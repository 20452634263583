import * as React from 'react';
import classNames from 'classnames';
import {FocusRing, FocusRingProps} from 'react-focus-rings';

import {BlockInteractionsContext} from './BlockInteractions';

import {KeyboardKeys} from '@developers/Constants';

// TODO remove 'strong' after message refactor,
export type ClickableTags = 'a' | 'div' | 'span' | 'strong' | 'li' | 'path';

// A subset of WAI-ARIA 1.1 roles that are considered "interactive"
type InteractiveARIARole =
  | 'button'
  | 'gridcell'
  | 'link'
  | 'listitem'
  | 'menuitem'
  | 'menuitemcheckbox'
  | 'menuitemradio'
  | 'option'
  | 'radio'
  | 'switch'
  | 'tab'
  | 'treeitem'
  | 'checkbox';

// Clickable's props are inherently non-exact because of the polymorphic tag:
// we already have to cast click/keyboard events to various other types to
// match the expectation of this shape. The proper solve here is to use a
// non-polymorphic API instead, like `asChild`.
interface ClickableSharedProps<T extends ClickableTags>
  extends Omit<React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>, 'ref'> {
  // These props are specific extensions or constraints we're adding to the
  // native html elements.
  tag: T;
  href?: HTMLAnchorElement['href'] | null;
  innerRef?:
    | ((a: HTMLElement | null) => void)
    | {
        current: HTMLElement | null;
      }
    | null;
  role?: InteractiveARIARole;
  tabIndex?: -1 | 0;
  'data-ref-id'?: string;
  focusProps?: FocusRingProps;
  ignoreKeyPress?: boolean;
  // These should all go away when Clickable becomes non-polymorphic
  target?: T extends 'a' ? string : never;
  d?: T extends 'path' ? string : never;
  fill?: T extends 'path' ? string : never;
}

interface ClickablePropsRequiredChildren<T extends ClickableTags> extends ClickableSharedProps<T> {
  children: React.ReactNode;
}

interface ClickablePropsRequiredAriaLabel<T extends ClickableTags> extends ClickableSharedProps<T> {
  'aria-label': string;
}

export type ClickableProps<T extends ClickableTags = 'div'> =
  | ClickablePropsRequiredChildren<T>
  | ClickablePropsRequiredAriaLabel<T>;

class Clickable<T extends ClickableTags> extends React.Component<ClickableProps<T>> {
  ref: HTMLElement | null | undefined;

  static contextType = BlockInteractionsContext;
  declare context: React.ContextType<typeof BlockInteractionsContext>;

  static defaultProps = {
    tag: 'div',
    role: 'button',
    tabIndex: 0,
  };

  handleKeyPress = (event: React.KeyboardEvent<HTMLElement>) => {
    const {onClick, href, onKeyPress, ignoreKeyPress} = this.props;

    // Don't trigger infinite click events if the user is holding down the key.
    if (event.repeat) return;

    // Spacebar and Enter key handling, to match a normal <button>
    // https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Techniques/Using_the_button_role
    if (
      !ignoreKeyPress &&
      onClick != null &&
      this.ref != null &&
      (event.charCode === KeyboardKeys.SPACE || event.charCode === KeyboardKeys.ENTER)
    ) {
      // We have some links with hrefs and an onClick for analytics code.
      // We shouldn't prevent the href behavior for those links but we do want to preventDefault otherwise
      // incase browsers do some weird scroll thing (on space)
      if (href == null) {
        event.preventDefault();
      }

      // this.ref.click is nullish for 'path' elements
      if (this.ref.click == null) {
        onClick(event as unknown as React.MouseEvent<HTMLDivElement, MouseEvent>);
      } else {
        this.ref.click();
      }
    }

    onKeyPress != null && onKeyPress(event as React.KeyboardEvent<HTMLDivElement>);
  };

  setRef = (node: HTMLElement | null) => {
    this.ref = node;
    const {innerRef} = this.props;
    if (innerRef != null) {
      if (typeof innerRef === 'function') innerRef(node);
      else if (innerRef.hasOwnProperty('current')) innerRef.current = node;
    }
  };

  renderNonInteractive() {
    const {
      tag: Tag,
      focusProps: _focusProps,
      innerRef: _innerRef,
      onClick: _onClick,
      role: _role,
      tabIndex: _tabIndex,
      ...props
    } = this.props;
    return React.createElement(Tag, {
      ref: this.setRef,
      ...props,
    });
  }

  renderInner() {
    const {
      tag: Tag,
      onClick,
      className,
      children,
      focusProps: _focusProps,
      innerRef: _innerRef,
      ...innerProps
    } = this.props;

    // Not a clickable component
    if (onClick == null) {
      return React.createElement(
        Tag,
        {
          ref: this.setRef,
          className: classNames(className),
          ...innerProps,
        },
        children
      );
    }

    return React.createElement(
      Tag,
      {
        onClick,
        ref: this.setRef,
        onKeyPress: this.handleKeyPress,
        className: classNames(className),
        ...innerProps,
      },
      children
    );
  }

  render() {
    const blockInteractions = this.context;
    if (blockInteractions) {
      return this.renderNonInteractive();
    }
    return (
      /* @ts-expect-error There's some incompatibility with onFocus being undefinable. */
      <FocusRing {...this.props.focusProps}>{this.renderInner()}</FocusRing>
    );
  }
}
export default Clickable;
