import * as React from 'react';

import AppLogo from '@developers/components/common/AppLogo';
import Sidebar from '@developers/components/common/Sidebar';
import SidebarSection from '@developers/components/common/SidebarSection';
import TrackedLink from '@developers/components/common/TrackedLink';
import useGuildAnalyticsGuilds from '@developers/hooks/useGuildAnalyticsGuilds';

import {Routes} from '@developers/Constants';
import {Messages} from '@developers/i18n';
import styles from './PrimaryNavigation.module.css';

interface AppNavigationItemProps {
  hasSubNavigation: boolean;
  to: string;
  children: React.ReactNode;
}

const AppNavigationItem = (props: AppNavigationItemProps) => {
  return (
    <TrackedLink
      to={props.to}
      className={styles.navItem}
      activeClassName={props.hasSubNavigation ? styles.activeNavItemSecondary : styles.activeNavItemPrimary}>
      {props.children}
    </TrackedLink>
  );
};

const PrimaryNavigation = ({children}: {children?: React.ReactNode}) => {
  const filteredGuilds = useGuildAnalyticsGuilds();
  const hasSubNavigation = children != null;

  return (
    <Sidebar contentClassName={styles.wrapper} innerWrapperClassName={styles.wrapperInner}>
      <SidebarSection>
        <AppLogo />
      </SidebarSection>
      <SidebarSection hasBorder={hasSubNavigation} isFlush={!hasSubNavigation} className={styles.contentPrimary}>
        <AppNavigationItem hasSubNavigation={hasSubNavigation} to={Routes.APPLICATIONS}>
          {Messages.AppNavigation.APPLICATIONS}
        </AppNavigationItem>
        <AppNavigationItem hasSubNavigation={hasSubNavigation} to={Routes.TEAMS}>
          {Messages.AppNavigation.TEAMS}
        </AppNavigationItem>
        {filteredGuilds.length === 0 ? null : (
          <AppNavigationItem hasSubNavigation={hasSubNavigation} to={Routes.GUILDS}>
            {Messages.AppNavigation.GUILDS}
          </AppNavigationItem>
        )}
        <AppNavigationItem hasSubNavigation={hasSubNavigation} to={Routes.EMBED_DEBUGGER}>
          {Messages.AppNavigation.EMBED_DEBUGGER}
        </AppNavigationItem>
        <AppNavigationItem hasSubNavigation={hasSubNavigation} to={Routes.DEVELOPERS_DOCS}>
          {Messages.AppNavigation.DOCUMENTATION}
        </AppNavigationItem>
      </SidebarSection>
      {children != null ? (
        <SidebarSection isFlush isSubNavigation>
          {children}
        </SidebarSection>
      ) : null}
    </Sidebar>
  );
};

export default PrimaryNavigation;
