import * as React from 'react';
import {useNavigate} from 'react-router-dom';
import {useStateFromStores} from '@discordapp/flux';

import UserStore from '@developers/stores/UserStore';
import TrackingAnalyticsUtils from '@developers/utils/TrackingAnalyticsUtils';
import SidebarCta, {
  SidebarCtaActionTypes,
  SidebarCtaActions,
  SidebarCtaAction,
  SidebarCtaContent,
  SidebarCtaHeading,
} from './SidebarCta';

import {AnalyticsEvents, Routes} from '@developers/Constants';
import {Messages} from '@developers/i18n';

const CTA_ANALYTICS_NAME = 'active_developer_program_cta';

export default function ActiveDeveloperProgramCta() {
  const navigate = useNavigate();
  const user = useStateFromStores([UserStore], () => UserStore.user);

  React.useEffect(() => {
    if (user == null) return;

    TrackingAnalyticsUtils.track(AnalyticsEvents.CTA_VIEWED, {
      user_id: user.id,
      cta_name: CTA_ANALYTICS_NAME,
    });
  }, [user]);

  const handleCtaClick = () => {
    if (user == null) return;

    TrackingAnalyticsUtils.track(AnalyticsEvents.CTA_CLICKED, {
      user_id: user.id,
      cta_name: CTA_ANALYTICS_NAME,
    });
    navigate(Routes.ACTIVE_DEVELOPER_PROGRAM);
  };

  return (
    <SidebarCta>
      <SidebarCtaHeading>{Messages.ActiveDeveloperProgramCta.HEADING}</SidebarCtaHeading>
      <SidebarCtaContent>{Messages.ActiveDeveloperProgramCta.BODY}</SidebarCtaContent>
      <SidebarCtaActions>
        <SidebarCtaAction onClick={handleCtaClick} type={SidebarCtaActionTypes.PRIMARY}>
          {Messages.ActiveDeveloperProgramCta.CTA}
        </SidebarCtaAction>
      </SidebarCtaActions>
    </SidebarCta>
  );
}
