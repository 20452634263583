interface PIIRestrictedObject {
  name: string;
  shape: Record<string, unknown>;
}

const PIIRestrictedObjects: PIIRestrictedObject[] = [
  {
    name: 'MessageRecord',
    shape: {
      id: undefined,
      channel_id: undefined,
      content: undefined,
      nonce: undefined,
    },
  },
];

export function checkLogForPII(message: string, ...args: any[]) {
  if (process.env.NODE_ENV === 'production') {
    return;
  }

  args.forEach((arg) => {
    if (typeof arg === 'object' && arg !== null) {
      PIIRestrictedObjects.forEach((PIIObject) => {
        const allKeysPresent = Object.keys(PIIObject.shape).every((key) => key in arg);
        if (allKeysPresent) {
          throw new Error(
            `Logger: Log blocked, argument contains likely PII object: '${PIIObject.name}'` +
              ` and message: '${message}, if you want to log locally anyways, use 'Logger.logDangerously' instead.'`
          );
        }
      });
    }
  });
}
