import * as React from 'react';
import {useLocation} from 'react-router-dom';
import {useStateFromStores} from '@discordapp/flux';
import Storage from '@discordapp/storage';

import UserStore from '@developers/stores/UserStore';
import Button from '@developers/uikit/Button';
import Checkbox from '@developers/uikit/Checkbox';
import Grid, {GridChild, GridSection} from '@developers/uikit/Grid';
import FormLabel from './common/FormLabel';
import Modal, {ModalCancel, ModalContent, ModalFooter, ModalHeader} from './common/Modal';

import {Messages} from '@developers/i18n';
import styles from './WinterSnowflakes.module.css';

const SNOWFLAKE_COUNT_KEY = 'winter_snowflake_count';
const SHOW_SNOWFLAKES_KEY = 'winter_snowflake_show';
const SHOW_USER_SNOWFLAKE_KEY = 'winter_snowflake_user_id_show';

const SNOWFLAKE_CHARACTER = '❅';

const DEFAULT_SNOWFLAKE_COUNT = 12;

function getShowSnowflakes(): boolean {
  const prefersReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches;

  return Storage.get(SHOW_SNOWFLAKES_KEY) ?? !prefersReducedMotion;
}

function getShowUserIdSnowflake(): boolean {
  return Storage.get(SHOW_USER_SNOWFLAKE_KEY) ?? true;
}

function getSnowflakeCount(): number {
  return Storage.get(SNOWFLAKE_COUNT_KEY, DEFAULT_SNOWFLAKE_COUNT) ?? DEFAULT_SNOWFLAKE_COUNT;
}

interface SnowflakeControlsProps {
  updateSettings: () => void;
}

function SnowflakeControls({updateSettings}: SnowflakeControlsProps) {
  const [showModal, setShowModal] = React.useState(false);
  const [settingShowSnowflakes, setSettingShowSnowflakes] = React.useState(getShowSnowflakes);
  const [settingShowUserIdSnowflake, setSettingShowUserIdSnowflake] = React.useState(getShowUserIdSnowflake);
  const [settingSnowflakeCount, setSettingSnowflakeCount] = React.useState(getSnowflakeCount);

  const onModalToggle = React.useCallback(() => {
    setShowModal((prevState) => !prevState);
  }, [setShowModal]);

  const setShowSnowflakes = React.useCallback(
    (value: boolean) => {
      Storage.set(SHOW_SNOWFLAKES_KEY, Boolean(value));
      setSettingShowSnowflakes(Boolean(value));
      updateSettings();
    },
    [setSettingShowSnowflakes, updateSettings]
  );

  const setShowUserIdSnowflake = React.useCallback(
    (value: boolean) => {
      Storage.set(SHOW_USER_SNOWFLAKE_KEY, Boolean(value));
      setSettingShowUserIdSnowflake(Boolean(value));
      updateSettings();
    },
    [setSettingShowUserIdSnowflake, updateSettings]
  );

  const setSnowflakeCount = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      Storage.set(SNOWFLAKE_COUNT_KEY, event.target.valueAsNumber);
      setSettingSnowflakeCount(event.target.valueAsNumber);
      updateSettings();
    },
    [setSettingSnowflakeCount, updateSettings]
  );

  return (
    <React.Fragment>
      <div className={styles.snowflakeControls}>
        <Button color={Button.Color.WHITE} onClick={onModalToggle}>
          {SNOWFLAKE_CHARACTER} {Messages.WinterSnowflakes.CONTROL_DECORATIONS}
        </Button>
      </div>
      {showModal && (
        <Modal onRequestClose={onModalToggle}>
          <ModalContent>
            <ModalHeader>{Messages.WinterSnowflakes.CONTROLS}</ModalHeader>
            <Grid>
              <GridSection>
                <GridChild columnSpread={12}>
                  <FormLabel>{Messages.WinterSnowflakes.SHOW}</FormLabel>
                  <Checkbox value={settingShowSnowflakes} onChange={setShowSnowflakes} type={Checkbox.Types.INVERTED} />
                </GridChild>
                <GridChild columnSpread={12}>
                  <FormLabel>{Messages.WinterSnowflakes.SHOW_USER_ID}</FormLabel>
                  <Checkbox
                    value={settingShowUserIdSnowflake}
                    onChange={setShowUserIdSnowflake}
                    type={Checkbox.Types.INVERTED}
                  />
                </GridChild>
                <GridChild columnSpread={12}>
                  <FormLabel>{Messages.WinterSnowflakes.COUNT}</FormLabel>
                  <input type="range" min="1" max="12" onChange={setSnowflakeCount} value={settingSnowflakeCount} />
                  <span> {settingSnowflakeCount}</span>
                  <br />
                  <span className={styles.snowflakeCountVisualization}>
                    {[...Array(settingSnowflakeCount)].map((_value, snowflakeIndex) => (
                      <span key={snowflakeIndex}>{SNOWFLAKE_CHARACTER}</span>
                    ))}
                  </span>
                </GridChild>
              </GridSection>
            </Grid>
          </ModalContent>
          <ModalFooter>
            <ModalCancel onClick={onModalToggle}>{Messages.Common.CLOSE}</ModalCancel>
          </ModalFooter>
        </Modal>
      )}
    </React.Fragment>
  );
}

function WinterSnowflakes() {
  // Ensure that our number of snowflakes stays consistent across renders!
  const [settingShowSnowflakes, setSettingShowSnowflakes] = React.useState(getShowSnowflakes);
  const [settingShowUserIdSnowflake, setSettingShowUserIdSnowflake] = React.useState(getShowUserIdSnowflake);
  const [settingSnowflakeCount, setSettingSnowflakeCount] = React.useState(getSnowflakeCount);

  const location = useLocation();

  const showUserIdSnowflake = React.useMemo(() => {
    // The user ID snowflake is more confusing to people coming from non-developer spaces
    const isServerInsights = location.pathname.includes('/servers');

    return !isServerInsights && settingShowUserIdSnowflake;
  }, [location, settingShowUserIdSnowflake]);

  const userId = useStateFromStores([UserStore], () => UserStore.user?.id, []);

  const updateSettings = React.useCallback(() => {
    setSettingShowSnowflakes(getShowSnowflakes);
    setSettingShowUserIdSnowflake(getShowUserIdSnowflake);
    setSettingSnowflakeCount(getSnowflakeCount);
  }, [setSettingShowSnowflakes, setSettingShowUserIdSnowflake, setSettingSnowflakeCount]);

  if (new Date().getMonth() !== 11 || !location.pathname.includes('/docs/')) {
    return null;
  }

  return (
    <React.Fragment>
      <SnowflakeControls updateSettings={updateSettings} />
      <div className={styles.snowflakes} aria-hidden="true">
        {[...Array(settingSnowflakeCount)].map((_value, snowflakeIndex) => {
          // If we don't do this and instead conditionally render the container,
          // then snowflake elements continue to show despite this being disabled!
          if (!settingShowSnowflakes || snowflakeIndex + 1 > settingSnowflakeCount) {
            return null;
          }

          const snowflake =
            showUserIdSnowflake && snowflakeIndex === 7 && userId != null ? userId : SNOWFLAKE_CHARACTER;

          return (
            <div className={styles.snowflake} key={`snowflake-number-${snowflakeIndex}`}>
              {snowflake}
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
}

export default WinterSnowflakes;
