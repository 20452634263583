import {CreateConfettiArgs, Environment, SpriteProp} from 'confetti-cannon';

export const COMMON_CONFETTI_COLORS = [
  '#FF73FA',
  '#FFC0FF',
  '#FFD836',
  '#FF9A15',
  '#A5F7DE',
  '#51BC9D',
  '#AEC7FF',
  '#3E70DD',
];

export const COMMON_CONFETTI_SPRITES: SpriteProp[] = [
  {src: require('@developers/images/confetti/coin.svg'), colorize: false},
];

export const COMMON_CONFETTI_MAX_SPRITE_SIZE = 28;

type CommonConfettiBaseConfig = Pick<CreateConfettiArgs, 'velocity' | 'rotation' | 'size'>;

export const COMMON_CONFETTI_BASE_CONFIG: CommonConfettiBaseConfig = {
  velocity: {
    type: 'static-random',
    minValue: {x: 30, y: -80},
    maxValue: {x: -30, y: -180},
  },
  rotation: {
    type: 'linear-random',
    minValue: 0,
    maxValue: 360,
    minAddValue: -25,
    maxAddValue: 25,
  },
  size: {
    type: 'static-random',
    minValue: 14,
    maxValue: COMMON_CONFETTI_MAX_SPRITE_SIZE,
  },
};

export const COMMON_CONFETTI_ENVIRONMENT = new Environment();
