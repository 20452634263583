import platform from 'platform';

// https://github.com/bestiejs/platform.js/tree/master/doc#platformproduct
const TabletProducts = new Set(['iPad', 'Kindle', 'Kindle Fire', 'Nook', 'PlayBook']);

// https://github.com/bestiejs/platform.js/tree/master/doc#platformosfamily
const MobileFamily = new Set(['Android', 'iOS', 'Windows Phone']);

// https://stackoverflow.com/questions/58019463/how-to-detect-device-name-in-safari-on-ios-13-while-it-doesnt-show-the-correct
const isTabletSafari =
  window?.navigator?.platform === 'MacIntel' &&
  // @ts-expect-error
  typeof window?.navigator?.standalone !== 'undefined' &&
  window?.navigator?.maxTouchPoints > 1;

const isTablet = TabletProducts.has(platform.product ?? '') || isTabletSafari;
const isMobile = !isTablet && MobileFamily.has(platform?.os?.family ?? '');
const isIOSWeb = platform?.os?.family === 'iOS';

export {isTablet, isMobile, isIOSWeb};
