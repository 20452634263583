// Makes sure that we have our environment variables.
// If we don't, and we're not in production mode,
// default to our dev environment values.

let envSet =
  window.GLOBAL_ENV.API_ENDPOINT != null ||
  window.GLOBAL_ENV.WEBAPP_ENDPOINT != null ||
  window.GLOBAL_ENV.CDN_HOST != null ||
  window.GLOBAL_ENV.STRIPE_KEY != null ||
  window.GLOBAL_ENV.ADYEN_KEY != null ||
  window.GLOBAL_ENV.MARKETING_ENDPOINT != null ||
  window.GLOBAL_ENV.RELEASE_CHANNEL != null ||
  window.GLOBAL_ENV.ALGOLIA_KEY != null;

if (process.env.NODE_ENV !== 'production' && !envSet) {
  global.window.GLOBAL_ENV.API_VERSION = Number(process.env.API_VERSION!);
  global.window.GLOBAL_ENV.API_ENDPOINT = process.env.API_ENDPOINT!;
  global.window.GLOBAL_ENV.WEBAPP_ENDPOINT = process.env.WEBAPP_ENDPOINT!;
  global.window.GLOBAL_ENV.CDN_HOST = process.env.CDN_HOST;
  global.window.GLOBAL_ENV.STRIPE_KEY = process.env.STRIPE_KEY!;
  global.window.GLOBAL_ENV.ADYEN_KEY = process.env.ADYEN_KEY!;
  global.window.GLOBAL_ENV.MARKETING_ENDPOINT = process.env.MARKETING_ENDPOINT!;
  global.window.GLOBAL_ENV.RELEASE_CHANNEL = process.env.RELEASE_CHANNEL!;
  global.window.GLOBAL_ENV.ALGOLIA_KEY = process.env.ALGOLIA_KEY!;
  envSet = true;
}

if (!envSet) {
  // eslint-disable-next-line no-alert
  window.alert(`Global environment variables not set!`);
  throw new Error('Global environment variables not set! Aborting.');
}
