import {_private as themeDefinitions} from '../../themes/generated/generated-definitions';

const {Themes} = themeDefinitions;
type Themes = typeof Themes;

export interface ShadowNativeStyles {
  shadowOffset: {width: number; height: number};
  shadowColor: string;
  shadowOpacity: number;
  shadowRadius: number;
  elevation: number;
  shadowColorAndroid: string;
}

type Shadows = Record<
  string,
  Record<Themes[keyof Themes], {boxShadow: string; filter: string; nativeStyles: ShadowNativeStyles}>
>;
const Shadows = {
  SHADOW_BORDER: {
    [Themes.DARK]: {
      boxShadow: '0 0 0 1px hsl(0 0% 100% / 0.08)',
      filter: 'drop-shadow(0 0 1px hsl(0 0% 100% / 0.08))',
      nativeStyles: {
        shadowOffset: {width: 0, height: 0},
        shadowColor: '#ffffff',
        shadowOpacity: 0.08,
        shadowRadius: 1,
        elevation: 0,
        shadowColorAndroid: '#ffffff',
      },
    },
    [Themes.LIGHT]: {
      boxShadow: '0 0 0 1px hsl(0 0% 0% / 0.08)',
      filter: 'drop-shadow(0 0 1px hsl(0 0% 0% / 0.08))',
      nativeStyles: {
        shadowOffset: {width: 0, height: 0},
        shadowColor: '#000000',
        shadowOpacity: 0.08,
        shadowRadius: 1,
        elevation: 0,
        shadowColorAndroid: '#000000',
      },
    },
    [Themes.MIDNIGHT]: {
      boxShadow: '0 0 0 1px hsl(0 0% 100% / 0.08)',
      filter: 'drop-shadow(0 0 1px hsl(0 0% 100% / 0.08))',
      nativeStyles: {
        shadowOffset: {width: 0, height: 0},
        shadowColor: '#ffffff',
        shadowOpacity: 0.08,
        shadowRadius: 1,
        elevation: 0,
        shadowColorAndroid: '#ffffff',
      },
    },
    [Themes.DARKER]: {
      boxShadow: '0 0 0 1px hsl(0 0% 100% / 0.08)',
      filter: 'drop-shadow(0 0 1px hsl(0 0% 100% / 0.08))',
      nativeStyles: {
        shadowOffset: {width: 0, height: 0},
        shadowColor: '#ffffff',
        shadowOpacity: 0.08,
        shadowRadius: 1,
        elevation: 0,
        shadowColorAndroid: '#ffffff',
      },
    },
  },
  SHADOW_BUTTON_OVERLAY: {
    [Themes.DARK]: {
      boxShadow: '0 12px 24px 0 hsl(0 0% 0% / 0.24)',
      filter: 'drop-shadow(0 12px 24px hsl(0 0% 0% / 0.24))',
      nativeStyles: {
        shadowOffset: {width: 0, height: 12},
        shadowColor: '#000000',
        shadowOpacity: 0.24,
        shadowRadius: 24,
        elevation: 20,
        shadowColorAndroid: '#000000',
      },
    },
    [Themes.LIGHT]: {
      boxShadow: '0 12px 24px 0 hsl(0 0% 0% / 0.24)',
      filter: 'drop-shadow(0 12px 24px hsl(0 0% 0% / 0.24))',
      nativeStyles: {
        shadowOffset: {width: 0, height: 12},
        shadowColor: '#000000',
        shadowOpacity: 0.24,
        shadowRadius: 24,
        elevation: 20,
        shadowColorAndroid: '#000000',
      },
    },
    [Themes.MIDNIGHT]: {
      boxShadow: '0 12px 24px 0 hsl(0 0% 0% / 0.24)',
      filter: 'drop-shadow(0 12px 24px hsl(0 0% 0% / 0.24))',
      nativeStyles: {
        shadowOffset: {width: 0, height: 12},
        shadowColor: '#000000',
        shadowOpacity: 0.24,
        shadowRadius: 24,
        elevation: 20,
        shadowColorAndroid: '#000000',
      },
    },
    [Themes.DARKER]: {
      boxShadow: '0 12px 24px 0 hsl(0 0% 0% / 0.24)',
      filter: 'drop-shadow(0 12px 24px hsl(0 0% 0% / 0.24))',
      nativeStyles: {
        shadowOffset: {width: 0, height: 12},
        shadowColor: '#000000',
        shadowOpacity: 0.24,
        shadowRadius: 24,
        elevation: 20,
        shadowColorAndroid: '#000000',
      },
    },
  },
  SHADOW_HIGH: {
    [Themes.DARK]: {
      boxShadow: '0 12px 24px 0 hsl(0 0% 0% / 0.24)',
      filter: 'drop-shadow(0 12px 24px hsl(0 0% 0% / 0.24))',
      nativeStyles: {
        shadowOffset: {width: 0, height: 12},
        shadowColor: '#000000',
        shadowOpacity: 0.24,
        shadowRadius: 24,
        elevation: 20,
        shadowColorAndroid: '#000000',
      },
    },
    [Themes.LIGHT]: {
      boxShadow: '0 12px 36px 0 hsl(0 0% 0% / 0.12)',
      filter: 'drop-shadow(0 12px 36px hsl(0 0% 0% / 0.12))',
      nativeStyles: {
        shadowOffset: {width: 0, height: 12},
        shadowColor: '#000000',
        shadowOpacity: 0.12,
        shadowRadius: 36,
        elevation: 20,
        shadowColorAndroid: '#a5abb3',
      },
    },
    [Themes.MIDNIGHT]: {
      boxShadow: '0 12px 24px 0 hsl(0 0% 0% / 0.24)',
      filter: 'drop-shadow(0 12px 24px hsl(0 0% 0% / 0.24))',
      nativeStyles: {
        shadowOffset: {width: 0, height: 12},
        shadowColor: '#000000',
        shadowOpacity: 0.24,
        shadowRadius: 24,
        elevation: 20,
        shadowColorAndroid: '#000000',
      },
    },
    [Themes.DARKER]: {
      boxShadow: '0 12px 24px 0 hsl(0 0% 0% / 0.24)',
      filter: 'drop-shadow(0 12px 24px hsl(0 0% 0% / 0.24))',
      nativeStyles: {
        shadowOffset: {width: 0, height: 12},
        shadowColor: '#000000',
        shadowOpacity: 0.24,
        shadowRadius: 24,
        elevation: 20,
        shadowColorAndroid: '#000000',
      },
    },
  },
  SHADOW_LEDGE: {
    [Themes.DARK]: {
      boxShadow: '0 2px 0 0 hsl(0 0% 0% / 0.05), 0 1.5px 0 0 hsl(0 0% 0% / 0.05), 0 1px 0 0 hsl(0 0% 0% / 0.16)',
      filter: 'drop-shadow(0 1.5px 0 hsl(0 0% 0% / 0.24))',
      nativeStyles: {
        shadowOffset: {width: 0, height: 1.5},
        shadowColor: '#000000',
        shadowOpacity: 0.24,
        shadowRadius: 0,
        elevation: 2,
        shadowColorAndroid: '#000000',
      },
    },
    [Themes.LIGHT]: {
      boxShadow: '0 2px 0 0 hsl(0 0% 0% / 0.025), 0 1.5px 0 0 hsl(0 0% 0% / 0.025), 0 1px 0 0 hsl(0 0% 0% / 0.08)',
      filter: 'drop-shadow(0 1.5px 0 hsl(0 0% 0% / 0.12))',
      nativeStyles: {
        shadowOffset: {width: 0, height: 1.5},
        shadowColor: '#000000',
        shadowOpacity: 0.12,
        shadowRadius: 0,
        elevation: 2,
        shadowColorAndroid: '#a5abb3',
      },
    },
    [Themes.MIDNIGHT]: {
      boxShadow: '0 2px 0 0 hsl(0 0% 0% / 0.05), 0 1.5px 0 0 hsl(0 0% 0% / 0.05), 0 1px 0 0 hsl(0 0% 0% / 0.16)',
      filter: 'drop-shadow(0 1.5px 0 hsl(0 0% 0% / 0.24))',
      nativeStyles: {
        shadowOffset: {width: 0, height: 1.5},
        shadowColor: '#000000',
        shadowOpacity: 0.24,
        shadowRadius: 0,
        elevation: 2,
        shadowColorAndroid: '#000000',
      },
    },
    [Themes.DARKER]: {
      boxShadow: '0 2px 0 0 hsl(0 0% 0% / 0.05), 0 1.5px 0 0 hsl(0 0% 0% / 0.05), 0 1px 0 0 hsl(0 0% 0% / 0.16)',
      filter: 'drop-shadow(0 1.5px 0 hsl(0 0% 0% / 0.24))',
      nativeStyles: {
        shadowOffset: {width: 0, height: 1.5},
        shadowColor: '#000000',
        shadowOpacity: 0.24,
        shadowRadius: 0,
        elevation: 2,
        shadowColorAndroid: '#000000',
      },
    },
  },
  SHADOW_LOW: {
    [Themes.DARK]: {
      boxShadow: '0 1px 4px 0 hsl(0 0% 0% / 0.14)',
      filter: 'drop-shadow(0 1px 4px hsl(0 0% 0% / 0.14))',
      nativeStyles: {
        shadowOffset: {width: 0, height: 1},
        shadowColor: '#000000',
        shadowOpacity: 0.14,
        shadowRadius: 4,
        elevation: 3,
        shadowColorAndroid: '#000000',
      },
    },
    [Themes.LIGHT]: {
      boxShadow: '0 1px 4px 0 hsl(0 0% 0% / 0.08)',
      filter: 'drop-shadow(0 1px 4px hsl(0 0% 0% / 0.08))',
      nativeStyles: {
        shadowOffset: {width: 0, height: 1},
        shadowColor: '#000000',
        shadowOpacity: 0.08,
        shadowRadius: 4,
        elevation: 3,
        shadowColorAndroid: '#a5abb3',
      },
    },
    [Themes.MIDNIGHT]: {
      boxShadow: '0 1px 4px 0 hsl(0 0% 0% / 0.14)',
      filter: 'drop-shadow(0 1px 4px hsl(0 0% 0% / 0.14))',
      nativeStyles: {
        shadowOffset: {width: 0, height: 1},
        shadowColor: '#000000',
        shadowOpacity: 0.14,
        shadowRadius: 4,
        elevation: 3,
        shadowColorAndroid: '#000000',
      },
    },
    [Themes.DARKER]: {
      boxShadow: '0 1px 4px 0 hsl(0 0% 0% / 0.14)',
      filter: 'drop-shadow(0 1px 4px hsl(0 0% 0% / 0.14))',
      nativeStyles: {
        shadowOffset: {width: 0, height: 1},
        shadowColor: '#000000',
        shadowOpacity: 0.14,
        shadowRadius: 4,
        elevation: 3,
        shadowColorAndroid: '#000000',
      },
    },
  },
  SHADOW_LOW_ACTIVE: {
    [Themes.DARK]: {
      boxShadow: '0 0 4px 0 hsl(0 0% 0% / 0.14)',
      filter: 'drop-shadow(0 0 4px hsl(0 0% 0% / 0.14))',
      nativeStyles: {
        shadowOffset: {width: 0, height: 0},
        shadowColor: '#000000',
        shadowOpacity: 0.14,
        shadowRadius: 4,
        elevation: 1,
        shadowColorAndroid: '#000000',
      },
    },
    [Themes.LIGHT]: {
      boxShadow: '0 0 4px 0 hsl(0 0% 0% / 0.08)',
      filter: 'drop-shadow(0 0 4px hsl(0 0% 0% / 0.08))',
      nativeStyles: {
        shadowOffset: {width: 0, height: 0},
        shadowColor: '#000000',
        shadowOpacity: 0.08,
        shadowRadius: 4,
        elevation: 1,
        shadowColorAndroid: '#a5abb3',
      },
    },
    [Themes.MIDNIGHT]: {
      boxShadow: '0 0 4px 0 hsl(0 0% 0% / 0.14)',
      filter: 'drop-shadow(0 0 4px hsl(0 0% 0% / 0.14))',
      nativeStyles: {
        shadowOffset: {width: 0, height: 0},
        shadowColor: '#000000',
        shadowOpacity: 0.14,
        shadowRadius: 4,
        elevation: 1,
        shadowColorAndroid: '#000000',
      },
    },
    [Themes.DARKER]: {
      boxShadow: '0 0 4px 0 hsl(0 0% 0% / 0.14)',
      filter: 'drop-shadow(0 0 4px hsl(0 0% 0% / 0.14))',
      nativeStyles: {
        shadowOffset: {width: 0, height: 0},
        shadowColor: '#000000',
        shadowOpacity: 0.14,
        shadowRadius: 4,
        elevation: 1,
        shadowColorAndroid: '#000000',
      },
    },
  },
  SHADOW_LOW_HOVER: {
    [Themes.DARK]: {
      boxShadow: '0 4px 10px 0 hsl(0 0% 0% / 0.14)',
      filter: 'drop-shadow(0 4px 10px hsl(0 0% 0% / 0.14))',
      nativeStyles: {
        shadowOffset: {width: 0, height: 4},
        shadowColor: '#000000',
        shadowOpacity: 0.14,
        shadowRadius: 10,
        elevation: 3,
        shadowColorAndroid: '#000000',
      },
    },
    [Themes.LIGHT]: {
      boxShadow: '0 4px 8px 0 hsl(0 0% 0% / 0.08)',
      filter: 'drop-shadow(0 4px 8px hsl(0 0% 0% / 0.08))',
      nativeStyles: {
        shadowOffset: {width: 0, height: 4},
        shadowColor: '#000000',
        shadowOpacity: 0.08,
        shadowRadius: 8,
        elevation: 3,
        shadowColorAndroid: '#a5abb3',
      },
    },
    [Themes.MIDNIGHT]: {
      boxShadow: '0 4px 10px 0 hsl(0 0% 0% / 0.14)',
      filter: 'drop-shadow(0 4px 10px hsl(0 0% 0% / 0.14))',
      nativeStyles: {
        shadowOffset: {width: 0, height: 4},
        shadowColor: '#000000',
        shadowOpacity: 0.14,
        shadowRadius: 10,
        elevation: 3,
        shadowColorAndroid: '#000000',
      },
    },
    [Themes.DARKER]: {
      boxShadow: '0 4px 10px 0 hsl(0 0% 0% / 0.14)',
      filter: 'drop-shadow(0 4px 10px hsl(0 0% 0% / 0.14))',
      nativeStyles: {
        shadowOffset: {width: 0, height: 4},
        shadowColor: '#000000',
        shadowOpacity: 0.14,
        shadowRadius: 10,
        elevation: 3,
        shadowColorAndroid: '#000000',
      },
    },
  },
  SHADOW_MEDIUM: {
    [Themes.DARK]: {
      boxShadow: '0 4px 8px 0 hsl(0 0% 0% / 0.16)',
      filter: 'drop-shadow(0 4px 8px hsl(0 0% 0% / 0.16))',
      nativeStyles: {
        shadowOffset: {width: 0, height: 4},
        shadowColor: '#000000',
        shadowOpacity: 0.16,
        shadowRadius: 8,
        elevation: 5,
        shadowColorAndroid: '#000000',
      },
    },
    [Themes.LIGHT]: {
      boxShadow: '0 4px 8px 0 hsl(0 0% 0% / 0.08)',
      filter: 'drop-shadow(0 4px 8px hsl(0 0% 0% / 0.08))',
      nativeStyles: {
        shadowOffset: {width: 0, height: 4},
        shadowColor: '#000000',
        shadowOpacity: 0.08,
        shadowRadius: 8,
        elevation: 5,
        shadowColorAndroid: '#a5abb3',
      },
    },
    [Themes.MIDNIGHT]: {
      boxShadow: '0 4px 8px 0 hsl(0 0% 0% / 0.16)',
      filter: 'drop-shadow(0 4px 8px hsl(0 0% 0% / 0.16))',
      nativeStyles: {
        shadowOffset: {width: 0, height: 4},
        shadowColor: '#000000',
        shadowOpacity: 0.16,
        shadowRadius: 8,
        elevation: 5,
        shadowColorAndroid: '#000000',
      },
    },
    [Themes.DARKER]: {
      boxShadow: '0 4px 8px 0 hsl(0 0% 0% / 0.16)',
      filter: 'drop-shadow(0 4px 8px hsl(0 0% 0% / 0.16))',
      nativeStyles: {
        shadowOffset: {width: 0, height: 4},
        shadowColor: '#000000',
        shadowOpacity: 0.16,
        shadowRadius: 8,
        elevation: 5,
        shadowColorAndroid: '#000000',
      },
    },
  },
  SHADOW_MOBILE_NAVIGATOR_X: {
    [Themes.DARK]: {
      boxShadow: '0 0 10px 0 hsl(0 0% 0% / 0.22)',
      filter: 'drop-shadow(0 0 10px hsl(0 0% 0% / 0.22))',
      nativeStyles: {
        shadowOffset: {width: 0, height: 0},
        shadowColor: '#000000',
        shadowOpacity: 0.22,
        shadowRadius: 10,
        elevation: 0,
        shadowColorAndroid: '#000000',
      },
    },
    [Themes.LIGHT]: {
      boxShadow: '0 0 9px 0 hsl(0 0% 0% / 0.13)',
      filter: 'drop-shadow(0 0 9px hsl(0 0% 0% / 0.13))',
      nativeStyles: {
        shadowOffset: {width: 0, height: 0},
        shadowColor: '#000000',
        shadowOpacity: 0.13,
        shadowRadius: 9,
        elevation: 0,
        shadowColorAndroid: '#000000',
      },
    },
    [Themes.MIDNIGHT]: {
      boxShadow: '0 0 10px 0 hsl(0 0% 0% / 0.22)',
      filter: 'drop-shadow(0 0 10px hsl(0 0% 0% / 0.22))',
      nativeStyles: {
        shadowOffset: {width: 0, height: 0},
        shadowColor: '#000000',
        shadowOpacity: 0.22,
        shadowRadius: 10,
        elevation: 0,
        shadowColorAndroid: '#000000',
      },
    },
    [Themes.DARKER]: {
      boxShadow: '0 0 10px 0 hsl(0 0% 0% / 0.22)',
      filter: 'drop-shadow(0 0 10px hsl(0 0% 0% / 0.22))',
      nativeStyles: {
        shadowOffset: {width: 0, height: 0},
        shadowColor: '#000000',
        shadowOpacity: 0.22,
        shadowRadius: 10,
        elevation: 0,
        shadowColorAndroid: '#000000',
      },
    },
  },
  SHADOW_TOP_HIGH: {
    [Themes.DARK]: {
      boxShadow: '0 -12px 32px 0 hsl(0 0% 0% / 0.24)',
      filter: 'drop-shadow(0 -12px 32px hsl(0 0% 0% / 0.24))',
      nativeStyles: {
        shadowOffset: {width: 0, height: -12},
        shadowColor: '#000000',
        shadowOpacity: 0.24,
        shadowRadius: 32,
        elevation: 18,
        shadowColorAndroid: '#000000',
      },
    },
    [Themes.LIGHT]: {
      boxShadow: '0 -12px 36px 0 hsl(0 0% 0% / 0.12)',
      filter: 'drop-shadow(0 -12px 36px hsl(0 0% 0% / 0.12))',
      nativeStyles: {
        shadowOffset: {width: 0, height: -12},
        shadowColor: '#000000',
        shadowOpacity: 0.12,
        shadowRadius: 36,
        elevation: 18,
        shadowColorAndroid: '#a5abb3',
      },
    },
    [Themes.MIDNIGHT]: {
      boxShadow: '0 -12px 32px 0 hsl(0 0% 0% / 0.24)',
      filter: 'drop-shadow(0 -12px 32px hsl(0 0% 0% / 0.24))',
      nativeStyles: {
        shadowOffset: {width: 0, height: -12},
        shadowColor: '#000000',
        shadowOpacity: 0.24,
        shadowRadius: 32,
        elevation: 18,
        shadowColorAndroid: '#000000',
      },
    },
    [Themes.DARKER]: {
      boxShadow: '0 -12px 32px 0 hsl(0 0% 0% / 0.24)',
      filter: 'drop-shadow(0 -12px 32px hsl(0 0% 0% / 0.24))',
      nativeStyles: {
        shadowOffset: {width: 0, height: -12},
        shadowColor: '#000000',
        shadowOpacity: 0.24,
        shadowRadius: 32,
        elevation: 18,
        shadowColorAndroid: '#000000',
      },
    },
  },
  SHADOW_TOP_LEDGE: {
    [Themes.DARK]: {
      boxShadow: '0 -2px 0 0 hsl(0 0% 0% / 0.05), 0 -1.5px 0 0 hsl(0 0% 0% / 0.05), 0 -1px 0 0 hsl(0 0% 0% / 0.16)',
      filter: 'drop-shadow(0 -1.5px 0 hsl(0 0% 0% / 0.24))',
      nativeStyles: {
        shadowOffset: {width: 0, height: -1.5},
        shadowColor: '#000000',
        shadowOpacity: 0.24,
        shadowRadius: 0,
        elevation: 1,
        shadowColorAndroid: '#000000',
      },
    },
    [Themes.LIGHT]: {
      boxShadow: '0 -2px 0 0 hsl(0 0% 0% / 0.025), 0 -1.5px 0 0 hsl(0 0% 0% / 0.025), 0 -1px 0 0 hsl(0 0% 0% / 0.08)',
      filter: 'drop-shadow(0 -1.5px 0 hsl(0 0% 0% / 0.12))',
      nativeStyles: {
        shadowOffset: {width: 0, height: -1.5},
        shadowColor: '#000000',
        shadowOpacity: 0.12,
        shadowRadius: 0,
        elevation: 1,
        shadowColorAndroid: '#a5abb3',
      },
    },
    [Themes.MIDNIGHT]: {
      boxShadow: '0 -2px 0 0 hsl(0 0% 0% / 0.05), 0 -1.5px 0 0 hsl(0 0% 0% / 0.05), 0 -1px 0 0 hsl(0 0% 0% / 0.16)',
      filter: 'drop-shadow(0 -1.5px 0 hsl(0 0% 0% / 0.24))',
      nativeStyles: {
        shadowOffset: {width: 0, height: -1.5},
        shadowColor: '#000000',
        shadowOpacity: 0.24,
        shadowRadius: 0,
        elevation: 1,
        shadowColorAndroid: '#000000',
      },
    },
    [Themes.DARKER]: {
      boxShadow: '0 -2px 0 0 hsl(0 0% 0% / 0.05), 0 -1.5px 0 0 hsl(0 0% 0% / 0.05), 0 -1px 0 0 hsl(0 0% 0% / 0.16)',
      filter: 'drop-shadow(0 -1.5px 0 hsl(0 0% 0% / 0.24))',
      nativeStyles: {
        shadowOffset: {width: 0, height: -1.5},
        shadowColor: '#000000',
        shadowOpacity: 0.24,
        shadowRadius: 0,
        elevation: 1,
        shadowColorAndroid: '#000000',
      },
    },
  },
  SHADOW_TOP_LOW: {
    [Themes.DARK]: {
      boxShadow: '0 -1px 4px 0 hsl(0 0% 0% / 0.14)',
      filter: 'drop-shadow(0 -1px 4px hsl(0 0% 0% / 0.14))',
      nativeStyles: {
        shadowOffset: {width: 0, height: -1},
        shadowColor: '#000000',
        shadowOpacity: 0.14,
        shadowRadius: 4,
        elevation: 3,
        shadowColorAndroid: '#000000',
      },
    },
    [Themes.LIGHT]: {
      boxShadow: '0 -1px 4px 0 hsl(0 0% 0% / 0.08)',
      filter: 'drop-shadow(0 -1px 4px hsl(0 0% 0% / 0.08))',
      nativeStyles: {
        shadowOffset: {width: 0, height: -1},
        shadowColor: '#000000',
        shadowOpacity: 0.08,
        shadowRadius: 4,
        elevation: 3,
        shadowColorAndroid: '#a5abb3',
      },
    },
    [Themes.MIDNIGHT]: {
      boxShadow: '0 -1px 4px 0 hsl(0 0% 0% / 0.14)',
      filter: 'drop-shadow(0 -1px 4px hsl(0 0% 0% / 0.14))',
      nativeStyles: {
        shadowOffset: {width: 0, height: -1},
        shadowColor: '#000000',
        shadowOpacity: 0.14,
        shadowRadius: 4,
        elevation: 3,
        shadowColorAndroid: '#000000',
      },
    },
    [Themes.DARKER]: {
      boxShadow: '0 -1px 4px 0 hsl(0 0% 0% / 0.14)',
      filter: 'drop-shadow(0 -1px 4px hsl(0 0% 0% / 0.14))',
      nativeStyles: {
        shadowOffset: {width: 0, height: -1},
        shadowColor: '#000000',
        shadowOpacity: 0.14,
        shadowRadius: 4,
        elevation: 3,
        shadowColorAndroid: '#000000',
      },
    },
  },
} satisfies Shadows;

export const _private = {Shadows};
