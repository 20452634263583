import * as React from 'react';
import classNames from 'classnames';
import {getClass} from '@discordapp/common/utils/StylesheetUtils';

import styles from './Spinner.module.css';

export const SpinnerTypes = {
  WANDERING_CUBES: 'wanderingCubes',
  CHASING_DOTS: 'chasingDots',
  PULSING_ELLIPSIS: 'pulsingEllipsis',
  SPINNING_CIRCLE: 'spinningCircle',
} as const;

interface Props {
  type?: 'wanderingCubes' | 'chasingDots' | 'pulsingEllipsis' | 'spinningCircle';
  className?: string;
  itemClassName?: string;
}

const Spinner = ({type = SpinnerTypes.WANDERING_CUBES, className, itemClassName, ...props}: Props) => {
  if (type === SpinnerTypes.SPINNING_CIRCLE) {
    return (
      <div className={classNames(styles.spinner, styles[type], className)} {...props}>
        <div className={styles.spinningCircleInner}>
          <svg className={styles.circular} viewBox="25 25 50 50">
            <circle className={classNames(styles.path, styles.path3)} cx="50" cy="50" r="20" />
            <circle className={classNames(styles.path, styles.path2)} cx="50" cy="50" r="20" />
            <circle className={styles.path} cx="50" cy="50" r="20" />
          </svg>
        </div>
      </div>
    );
  }

  const itemClass = classNames(getClass(styles, type, 'item'), itemClassName);
  return (
    <span className={classNames(styles.spinner, className)} {...props}>
      <span className={classNames(styles.inner, styles[type])}>
        <span className={itemClass} />
        <span className={itemClass} />
        {type === SpinnerTypes.PULSING_ELLIPSIS ? <span className={itemClass} /> : null}
      </span>
    </span>
  );
};

Spinner.Type = SpinnerTypes;

export default Spinner;
