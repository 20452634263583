import * as React from 'react';
import {Timeout} from '@discordapp/timers';

import TooltipActionCreators from '@developers/actions/TooltipActionCreators';
import usePrevious from '@developers/hooks/usePrevious';
import TooltipStore from '@developers/stores/TooltipStore';

const Positions = {
  TOP: 'top',
  LEFT: 'left',
  RIGHT: 'right',
  BOTTOM: 'bottom',
};

export const Colors = {
  BLACK: 'black',
  BRAND: 'brand',
  GREEN: 'green',
  YELLOW: 'yellow',
  RED: 'red',
  GREY: 'grey',
};

interface Props {
  children?: React.ReactElement;
  text: React.ReactNode;
  color?: (typeof Colors)[keyof typeof Colors];
  position?: (typeof Positions)[keyof typeof Positions];
  delay?: number;
  className?: string;
  onClick?: (e: React.MouseEvent) => void;
  isInteractive?: boolean;
}

export default function Tooltip({
  position = Positions.TOP,
  color = Colors.BLACK,
  delay = 0,
  isInteractive = false,
  text,
  children,
  className,
  ...restProps
}: Props) {
  const timer = React.useMemo(() => new Timeout(), []);
  const ref = React.useRef<HTMLDivElement>(null);
  const id = React.useId();
  const [isOpen, setIsOpen] = React.useState(false);

  const show = React.useCallback(() => {
    timer.stop();

    const domNode = ref.current;

    if (!(domNode instanceof Element)) {
      return;
    }

    const {top, left, right, bottom} = domNode.getBoundingClientRect();
    TooltipActionCreators.show(id, {
      position,
      text,
      color,
      targetWidth: right - left,
      targetHeight: bottom - top,
      x: left,
      y: top,
      id,
      isInteractive,
    });
  }, [color, id, isInteractive, position, text, timer]);

  const hide = React.useCallback(() => {
    timer.stop();
    if (isOpen) {
      TooltipActionCreators.hide(id);
    }
  }, [id, isOpen, timer]);

  React.useEffect(() => {
    const getStateFromStores = () => setIsOpen(TooltipStore.isOpen(id));
    TooltipStore.addChangeListener(getStateFromStores);
    return () => {
      TooltipStore.removeChangeListener(getStateFromStores);
      hide();
    };
  }, [hide, id]);

  const prevText = usePrevious(text);
  const prevPosition = usePrevious(position);

  React.useEffect(() => {
    if (isOpen) {
      if (text == null) {
        hide();
      } else if (prevPosition !== prevPosition || prevText !== text) {
        show();
      }
    }
  }, [hide, isOpen, prevPosition, prevText, show, text]);

  const getChildProps = () => {
    const child = React.Children.only(children);
    return child?.props;
  };

  const maybeShow = () => {
    if (delay > 0) {
      showDelayed();
    } else {
      show();
    }
  };

  const showDelayed = () => {
    timer.start(delay, show);
  };

  const handleMouseEnter = (e: React.MouseEvent) => {
    const {onMouseEnter} = getChildProps();
    maybeShow();
    onMouseEnter?.(e);
  };

  const handleMouseLeave = (e: React.MouseEvent) => {
    const {onMouseLeave} = getChildProps();
    hide();
    onMouseLeave?.(e);
  };

  const handleClick = (e: React.MouseEvent) => {
    const {onClick} = getChildProps();
    const {onClick: thisOnClick} = restProps;
    hide();
    onClick?.(e);
    thisOnClick?.(e);
  };

  const handleContextMenu = (e: React.MouseEvent) => {
    const {onContextMenu} = getChildProps();
    hide();
    onContextMenu?.(e);
  };

  const handleTouchStart = (e: React.TouchEvent) => {
    const {onTouchStart} = getChildProps();
    maybeShow();
    onTouchStart?.(e);
  };

  const handleTouchEnd = (e: React.TouchEvent) => {
    const {onTouchEnd} = getChildProps();
    hide();
    onTouchEnd?.(e);
  };

  if (children == null) {
    return null;
  }

  if (text == null) {
    return children;
  }

  return (
    // eslint-disable-next-line @discordapp/discord/use-a11y-component
    <div
      ref={ref}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
      onContextMenu={handleContextMenu}
      className={className}>
      {children}
    </div>
  );
}

Tooltip.Positions = Positions;
Tooltip.Colors = Colors;
