import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {useBlocker} from 'react-router-dom';
import Animated from '@discordapp/animated';

import usePrevious from '@developers/hooks/usePrevious';
import {ComponentDispatch} from '@developers/utils/ComponentDispatchUtils';
import UnsavedChangesNoticeBar from './UnsavedChangesNoticeBar';

import {ComponentActions} from '@developers/Constants';
import styles from './UnsavedChangesNotice.module.css';

interface Props {
  isSubmitDisabled?: boolean;
  onSave?: () => void;
  onReset: () => void;
  submitting: boolean;
  actionCopy?: React.ReactNode;
  isVisible: boolean;
  hasExtraPadding?: boolean;
}

const SPRING_SETTINGS = {
  friction: 7,
  tension: 60,
};

const PLACEHOLDER_MOUNT_POINT_ID = crypto.randomUUID();

export const UnsavedChangesNoticePlaceholderMountPoint = () => <div id={PLACEHOLDER_MOUNT_POINT_ID} />;

function NoticePlaceholder({displayPadding, hasExtraPadding}: {displayPadding: boolean; hasExtraPadding: boolean}) {
  const mountPoint = window.document.getElementById(PLACEHOLDER_MOUNT_POINT_ID);

  if (!hasExtraPadding || !displayPadding || mountPoint == null) {
    return null;
  }

  return ReactDOM.createPortal(<div className={styles.noticePlaceholder} />, mountPoint);
}

export default function UnsavedChangesNotice({
  actionCopy,
  hasExtraPadding = true,
  isSubmitDisabled,
  isVisible,
  onReset,
  onSave,
  submitting,
}: Props): JSX.Element {
  const translateY = React.useRef(new Animated.Value(0));
  const [showNotice, setShowNotice] = React.useState(false);
  const [displayPadding, setDisplayPadding] = React.useState(false);

  useBlocker(() => {
    if (isVisible) {
      ComponentDispatch.dispatch(ComponentActions.SHAKE_APP, {});
      ComponentDispatch.dispatch(ComponentActions.EMPHASIZE_NOTICE);
      return true;
    }
    return false;
  });

  const prevIsVisible = usePrevious(isVisible);

  const slideIn = React.useCallback((callback: () => unknown) => {
    Animated.spring(translateY.current, {
      toValue: 1,
      ...SPRING_SETTINGS,
    }).start(callback);
  }, []);

  const slideOut = React.useCallback((callback: () => unknown) => {
    Animated.stagger(250, [
      Animated.spring(translateY.current, {
        toValue: 1.3,
        ...SPRING_SETTINGS,
      }),
      Animated.spring(translateY.current, {
        toValue: 0,
        ...SPRING_SETTINGS,
      }),
    ]).start(callback);
  }, []);

  React.useEffect(() => {
    if (!prevIsVisible && isVisible) {
      setShowNotice((prevNotice) => !prevNotice);
      slideIn(() => {});
    }

    if (prevIsVisible && !isVisible) {
      slideOut(() => {
        setShowNotice((prevNotice) => !prevNotice);
      });
    }
  }, [isVisible, prevIsVisible, slideIn, slideOut]);

  React.useEffect(() => {
    if (isVisible && hasExtraPadding) {
      setDisplayPadding(true);
    } else {
      setDisplayPadding(false);
    }
  }, [hasExtraPadding, isVisible]);

  return (
    <React.Fragment>
      <Animated.div
        className={styles.noticeWrapper}
        style={{
          transform: [
            {
              translateY: translateY.current.interpolate({
                inputRange: [0, 1],
                outputRange: ['150%', '0%'],
              }),
            },
            {
              translateZ: 0,
            },
          ],
        }}>
        {showNotice ? (
          <UnsavedChangesNoticeBar
            actionCopy={actionCopy}
            isSubmitDisabled={isSubmitDisabled}
            onReset={onReset}
            onSave={onSave}
            submitting={submitting}
          />
        ) : null}
      </Animated.div>
      <NoticePlaceholder displayPadding={displayPadding} hasExtraPadding={hasExtraPadding} />
    </React.Fragment>
  );
}
