import {getTeamIcon} from './ApplicationImageUtils';

import type {SelectOptionWithAvatar} from '@developers/components/common/AvatarSelectOption';
import type {Team, TeamId} from '@developers/flow/Server';

interface TeamOptions {
  label: string;
  value: string;
  avatar: string | null | undefined;
}

export function getTeamSelectOptions(
  teams: Map<TeamId, Team>,
  additionalTeamOptions: Array<SelectOptionWithAvatar<string>> = []
): Array<SelectOptionWithAvatar<string>> {
  const teamOptions: TeamOptions[] = [];

  teams.forEach((team, teamId) => {
    teamOptions.push({label: team.name, value: teamId, avatar: getTeamIcon(teamId, team.icon)});
  });

  return additionalTeamOptions.concat(teamOptions);
}
