import DevtoolsExtension from '@discordapp/common/DevtoolsExtension';
import {extractId} from '@discordapp/fingerprint-utils';
import Flux, {ActionBase, Dispatcher, Store, ActionHandlers} from '@discordapp/flux';
import HTTPUtils from '@discordapp/http-utils';
import IdGenerator from '@discordapp/id-generator';

const IDLE_CALLBACK_TIMEOUT = 1500;
const MAX_EVENTS_IN_QUEUE = 10000;

let drainTimeout = IDLE_CALLBACK_TIMEOUT;

// Technically we shouldn't have an optional `deadline` parameter, but when we polyfill `requestIdleCallback` we need to
// be able to call it without having an IdleDeadline object.
const requestIdleCallback: (cb: (deadline?: IdleDeadline) => void, opts?: IdleRequestOptions) => number =
  window.requestIdleCallback ??
  // Lazy shim since we only care about deferring. Safari does not support `requestIdleCallback`.
  ((cb) => setImmediate(() => cb()));

export interface QueuedEvent<T> {
  type: string;
  fingerprint?: string;
  properties: T;
  resolve?: (value?: void | PromiseLike<void>) => void;
}

interface AnyQueuedEvent extends QueuedEvent<Record<string, any>> {}

interface AnalyticsActionHandlersType {
  handleConnectionOpen: (action: {analyticsToken?: string | null | undefined; user: {id: string}}) => boolean | void;
  handleConnectionClosed: () => boolean | void;
  handleFingerprint: () => boolean | void;
  handleTrack: (action: {
    event: string;
    properties?: Record<string, any>;
    flush?: boolean;
    fingerprint?: string;
    resolve?: (value?: void | PromiseLike<void>) => void;
  }) => boolean | void;
}

const uuidGenerator = new IdGenerator();

// `token` and `authedUserId` are both set and cleared at the same time.
// We can assume that if we have one, we also have the other in the app.
// This is not true in Marketing, where they arrive seperately
let token: string | undefined | null;

// `authedUserId` is saved here because `AuthenticationStore` clears it on `LOGOUT`,
// but in here we want to clear it when we clear `token` on `CONNECTION_CLOSE`.
let authedUserId: string | undefined | null;

// main connection methods to attach to a actionHandler
export const AnalyticsActionHandlers: AnalyticsActionHandlersType = {
  handleConnectionOpen: () => {},
  handleConnectionClosed: () => {},
  handleFingerprint: () => {},
  handleTrack: () => {},
};

let eventsQueue: AnyQueuedEvent[] = [];
let drainCallback: number | null | undefined;

const defaultGetSessionId = () => Promise.resolve({sessionId: undefined});

// factory to create an AnalyticsTrackingStore for a specific app
export const analyticsTrackingStoreMaker = <T extends Readonly<ActionBase>>({
  dispatcher,
  actionHandler,
  getFingerprint,
  getSessionId = defaultGetSessionId,
  TRACKING_URL,
  drainTimeoutOverride,
  waitFor,
}: {
  dispatcher: any extends T ? never : Dispatcher<T>;
  actionHandler: ActionHandlers<T>;
  getFingerprint: () => string | null | undefined;
  getSessionId?: () => Promise<{
    sessionId: string | undefined;
  }>;
  TRACKING_URL: string;
  drainTimeoutOverride?: number;
  waitFor?: Array<Store<T>>;
}) => {
  // allow acceleration of drain timeout, for website which needs instant analytics calls
  drainTimeout = drainTimeoutOverride != null ? drainTimeoutOverride : IDLE_CALLBACK_TIMEOUT;

  function getUserId(event: AnyQueuedEvent) {
    if (authedUserId != null) {
      return authedUserId;
    }

    // Fall back to current fingerprint if one wasn't recorded by the event.
    const fingerprint = event.fingerprint || getFingerprint();
    if (fingerprint != null) {
      return extractId(fingerprint);
    }

    return null;
  }

  function canDrain() {
    if (eventsQueue.length === 0) {
      return false;
    }

    // If we have an authed user id, but no token yet, we cannot drain, and are
    // waiting on the token to be passed in so we can drain the tracking queue.
    if (authedUserId != null) {
      return token != null;
    }

    return getFingerprint() != null;
  }

  function scheduleDrain() {
    if (drainCallback == null && canDrain()) {
      drainCallback = requestIdleCallback(drainEventsQueue, {timeout: drainTimeout});
    }
  }

  function drainEventsQueue() {
    drainCallback = null;
    if (!canDrain()) return;

    const eventsToQueue = eventsQueue.slice();
    eventsQueue = [];
    const result = submitEventsImmediately(eventsToQueue);
    // Only keep the events in the queue if the request failed.
    // Resolving an event's promise should not affect if the event should drain from the queue.
    result.then(
      () => {
        eventsToQueue.forEach((event) => {
          event.resolve?.();
        });
      },
      (e) => {
        eventsQueue.unshift(...eventsToQueue);

        const {message} = e.body || e;
        // eslint-disable-next-line no-console
        console.warn('[AnalyticsTrackingStore] Track:', message);
      }
    );
  }

  function submitEventsImmediately(eventsToSend: AnyQueuedEvent[]) {
    const sendTime = Date.now();
    const events = eventsToSend.map((event) => ({
      ...event,
      properties: {
        ...event.properties,
        client_send_timestamp: sendTime,
      },
    }));

    if (process.env.NODE_ENV === 'development') {
      /* eslint-disable no-console */
      console.groupCollapsed(`[AnalyticsTrackingStore] Draining ${events.length} event(s)`);

      if (token != null) {
        console.info('with token:', token);
      } else {
        console.info('with fingerprint:', getFingerprint());
      }

      for (const {type, properties} of events) {
        console.log(type, properties);
      }

      console.groupEnd();
      /* eslint-enable no-console */
    }

    return HTTPUtils.post({
      url: TRACKING_URL,
      body: {
        token,
        events,
      },
      retries: 3,
    });
  }

  AnalyticsActionHandlers.handleConnectionOpen = function ({analyticsToken, user}) {
    if (analyticsToken != null) {
      token = analyticsToken;
    }
    if (user.id != null) {
      authedUserId = user.id;
    }
    scheduleDrain();
    return false;
  };

  AnalyticsActionHandlers.handleConnectionClosed = function () {
    drainEventsQueue();
    token = null;
    authedUserId = null;
    return false;
  };

  AnalyticsActionHandlers.handleFingerprint = function () {
    drainEventsQueue();
    return false;
  };

  AnalyticsActionHandlers.handleTrack = function ({event: eventType, properties, flush, fingerprint, resolve}) {
    getSessionId().then(({sessionId}) => {
      const event: AnyQueuedEvent = {
        type: eventType,
        fingerprint,
        properties: {
          client_track_timestamp: Date.now(),
          client_heartbeat_session_id: sessionId,
          ...properties,
        },
        resolve,
      };

      // don't pass uuid if we don't actually have the userId
      const userId = getUserId(event);
      if (userId != null) {
        event.properties.client_uuid = uuidGenerator.generate(userId);
      }

      eventsQueue.push(event);

      if (process.env.NODE_ENV === 'development') {
        DevtoolsExtension.reportEvent({type: 'Analytics-Track', description: eventType, data: event});
      }

      if (eventsQueue.length > MAX_EVENTS_IN_QUEUE) {
        eventsQueue = eventsQueue.slice(-MAX_EVENTS_IN_QUEUE);
      }
      if (flush) {
        drainEventsQueue();
      } else {
        scheduleDrain();
      }
    });

    return false;
  };

  class AnalyticsTrackingStore extends Flux.Store<T> {
    static displayName = 'AnalyticsTrackingStore';
    initialize() {
      if (waitFor != null) {
        this.waitFor(...waitFor);
      }
    }
    submitEventsImmediately = submitEventsImmediately;
  }

  return new AnalyticsTrackingStore(dispatcher, actionHandler);
};
