import * as React from 'react';
import classNames from 'classnames';
import {getClass} from '@discordapp/common/utils/StylesheetUtils';
import {Text} from '@discordapp/design/components/Text/Text.web';

import styles from './Alert.module.css';

export const AlertTypes = {
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error',
  SUCCESS: 'success',
  BENIGN: 'benign',
} as const;

interface Props {
  children?: React.ReactNode;
  className?: string;
  textClassName?: string;
  heading?: string;
  type?: string;
}

export default function Alert({children, className, textClassName, heading, type = AlertTypes.WARNING}: Props) {
  function renderHeading() {
    if (heading == null) return null;
    return (
      <Text variant="text-lg/medium" className={styles.heading} color="always-white">
        {heading}
      </Text>
    );
  }
  const typeClass = getClass(styles, type);
  return (
    <div className={classNames(styles.alert, typeClass, className)}>
      {renderHeading()}
      <Text variant="text-md/normal" className={textClassName} color="always-white">
        {children}
      </Text>
    </div>
  );
}
