export interface RGBColor {
  r: number;
  g: number;
  b: number;
}

interface HSVColor {
  h: number;
  s: number;
  v: number;
}

interface EmojiColorMap {
  hex: string;
  hsv: HSVColor;
}

// Returns an object with properties r, g, and b reflecting the
export function hexToRgb(hex: string): RGBColor {
  if (hex[0] === '#') {
    hex = hex.slice(1);
  }
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return {r, g, b};
}

export function hexOpacityToRgba(hex: string, opacity: number): string {
  const {r, g, b} = hexToRgb(hex);
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

// From https://github.com/discord/discord/blob/main/discord_app/utils/ColorUtils.tsx#L198
function hex2rgb2hsv(hex: string): HSVColor | null {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  if (result == null) {
    return null;
  }

  var r = parseInt(result[1], 16);
  var g = parseInt(result[2], 16);
  var b = parseInt(result[3], 16);

  let rr;
  let gg;
  let bb;
  let h: number;
  let s;

  const rabs = r / 255;
  const gabs = g / 255;
  const babs = b / 255;

  const v = Math.max(rabs, gabs, babs);
  const diff = v - Math.min(rabs, gabs, babs);
  const diffc = (c: number) => (v - c) / 6 / diff + 1 / 2;
  const percentRoundFn = (num: number) => Math.round(num * 100) / 100;
  if (diff === 0) {
    h = s = 0;
  } else {
    s = diff / v;
    rr = diffc(rabs);
    gg = diffc(gabs);
    bb = diffc(babs);

    if (rabs === v) {
      h = bb - gg;
    } else if (gabs === v) {
      h = 1 / 3 + rr - bb;
    } else if (babs === v) {
      h = 2 / 3 + gg - rr;
    } else {
      h = 0;
    }
    if (h < 0) {
      h += 1;
    } else if (h > 1) {
      h -= 1;
    }
  }
  return {
    h: Math.round(h * 360),
    s: percentRoundFn(s * 100),
    v: percentRoundFn(v * 100),
  };
}

// From https://github.com/discord/discord/blob/main/discord_app/utils/ColorUtils.tsx#L458
function sortColors(colorA: EmojiColorMap, colorB: EmojiColorMap) {
  const hsvA = colorA.hsv;
  const hsvB = colorB.hsv;
  return hsvB.s + hsvB.v - (hsvA.s + hsvA.v);
}

// From https://github.com/discord/discord/blob/main/discord_app/utils/ColorUtils.tsx#L445
// A better name for this might be "findMostSaturatedColor"
export function findColorByHsv(colors: string[]): string {
  const hsvColors = colors
    .slice(0, 3)
    .map((color) => {
      return {
        hex: color,
        hsv: hex2rgb2hsv(color) ?? {h: 0, s: 0, v: 0},
      };
    })
    .sort(sortColors);
  return hsvColors[0].hex;
}
