let localStorage;

/**
 * If cookies are blocked in Firefox, this causes a fatal error
 * https://sentry.io/discord/discord-web/issues/537753341/ & 536013249
 */
try {
  localStorage = window.localStorage;
} catch (e) {}

/**
 * Some simple XSS security. Won't prevent access to tokens entirely,
 * but at least it makes it a lot harder.
 */
if (process.env.NODE_ENV === 'production' && !__UIKIT__) {
  // old Safari versions don't like this
  try {
    // TypeScript considers this read-only
    // @ts-expect-error
    delete window.localStorage;
  } catch (e) {}
}

/**
 * Determine if localStorage can be used.
 */
function localStorageTest(): boolean {
  const test = 'test';
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
}

/**
 * Store everything in local storage.
 */
class LocalStorage {
  /**
   * Get an item from the store.
   */
  get<T>(key: string, defaultValue?: T): T {
    let value = localStorage.getItem(key);
    if (value != null) {
      try {
        value = JSON.parse(value);
      } catch (e) {
        value = defaultValue;
      }
    } else {
      value = defaultValue;
    }
    return value;
  }

  /**
   * Set an item in the store.
   */
  set(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  /**
   * Remove an item from the store.
   */
  remove(key: string): void {
    localStorage.removeItem(key);
  }

  /**
   * Clear all items at the store.
   */
  clear(): void {
    localStorage.clear();
  }

  /**
   * Get all items in storage as a json string
   */
  stringify(): string {
    return JSON.stringify(localStorage || {});
  }

  asyncGetRaw(key: string, traceName?: string): Promise<string | null> {
    return Promise.resolve(localStorage.getItem(key));
  }

  setRaw(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  getAfterRefresh<T>(key: string): Promise<T | null | undefined> {
    return Promise.resolve(this.get<T>(key));
  }
}

/**
 * Mock store that puts everything in an object if localStorage is disabled.
 */
export class ObjectStorage {
  storage: {[key: string]: any};

  constructor() {
    this.storage = {};
  }

  /**
   * Get an item from the store.
   */
  get<T>(key: string, defaultValue?: T): T {
    if (!this.storage.hasOwnProperty(key)) {
      return defaultValue;
    }
    return this.storage[key];
  }

  /**
   * Set an item in the store.
   */
  set(key: string, value: any) {
    this.storage[key] = value;
  }

  /**
   * Remove an item from the store.
   */
  remove(key: string): void {
    delete this.storage[key];
  }

  /**
   * Clear all items at the store.
   */
  clear(): void {
    this.storage = {};
  }

  /**
   * Get all items in storage as a json string
   */
  stringify(): string {
    return JSON.stringify(this.storage);
  }

  asyncGetRaw(key: string, traceName?: string): Promise<string | null> {
    return Promise.resolve(localStorage.getItem(key));
  }

  setRaw(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  getAfterRefresh<T>(key: string): Promise<T | null | undefined> {
    return Promise.resolve(this.get<T>(key));
  }
}

export const impl = localStorageTest() ? new LocalStorage() : new ObjectStorage();
