import {ApplicationSubscriptionPriceTiers} from '@discordapp/common/shared-constants/ApplicationSubscriptionPriceTiers';
import Flux from '@discordapp/flux';

import Dispatcher from '@developers/Dispatcher';
import ActionTypes from '@developers/actions/ActionTypes';

import type {ActionFor, Action} from '@developers/flow/Action';
import type {ApplicationId, PriceTier, PriceTierValues, SKU} from '@developers/flow/Server';

let skusByAppId: Record<ApplicationId, SKU[]> = {};
let skus: Record<string, SKU> = {};
let priceTierValues: Record<PriceTier, PriceTierValues> = {};
let priceTiers: PriceTier[] = [];

function setKnownSkus(appSkus: SKU[]): void {
  for (const sku of appSkus) {
    updateKnownSku(sku);
  }
}

function setPriceTierValues(priceTier: PriceTier, incomingPriceTierValues: PriceTierValues): void {
  priceTierValues[priceTier] = incomingPriceTierValues;
}

function setPriceTiers(incomingPriceTiers: PriceTier[]): void {
  priceTiers = incomingPriceTiers.sort((a, b) => a - b);
}

function updateKnownSku(appSku: SKU): void {
  skus[appSku.id] = appSku;
}

function setSkusForApp(appId: ApplicationId, appSkus: SKU[]): void {
  skusByAppId[appId] = appSkus;
}

function updateSkusForApp(appId: ApplicationId, updatedSku: SKU): void {
  const nextAppSkus = [...skusByAppId[appId]];
  const indexToReplace = nextAppSkus.findIndex((sku) => sku.id === updatedSku.id);
  nextAppSkus[indexToReplace] = updatedSku;
  skusByAppId[appId] = nextAppSkus;
}

function addNewSku(sku: SKU): void {
  if (skusByAppId[sku.application_id] == null) {
    skusByAppId[sku.application_id] = [];
  }

  skusByAppId[sku.application_id].push(sku);
  skus[sku.id] = sku;
}

function handleUserLogout() {
  skusByAppId = {};
  skus = {};
  priceTierValues = {};
  priceTiers = [];
}

class SkuStore extends Flux.Store<Action> {
  static displayName = 'SkuStore';
  hasSkus(appId: ApplicationId): boolean {
    return skusByAppId[appId] != null && skusByAppId[appId].length > 0;
  }

  getPriceTiers(): PriceTier[] {
    return priceTiers;
  }

  getSubscriptionPriceTiers(): PriceTier[] {
    return priceTiers.filter(
      (tier) => tier >= ApplicationSubscriptionPriceTiers.MIN && tier <= ApplicationSubscriptionPriceTiers.MAX
    );
  }

  getPriceTierValues(): {
    [priceTier: PriceTier]: PriceTierValues;
  } {
    return priceTierValues;
  }

  getSku(skuId: string): SKU | null | undefined {
    return skus[skuId];
  }

  getSkus(appId: ApplicationId): SKU[] | null | undefined {
    return skusByAppId[appId];
  }
}

function handleSkuFetchSuccess(action: ActionFor<'SKU_FETCH_SUCCESS'>) {
  setKnownSkus(action.skus);
  setSkusForApp(action.appId, action.skus);
}

function handleSkuUpdateSuccess(action: ActionFor<'SKU_UPDATE_SUCCESS'>) {
  updateKnownSku(action.sku);
  updateSkusForApp(action.appId, action.sku);
}

function handlePriceTierValuesFetchSuccess(action: ActionFor<'PRICE_TIER_VALUES_FETCH_SUCCESS'>) {
  setPriceTierValues(action.priceTier, action.priceTierValues);
}

function handlePriceTiersFetchSuccess(action: ActionFor<'PRICE_TIERS_FETCH_SUCCESS'>) {
  setPriceTiers(action.priceTiers);
}

function handleSkuCreateSuccess(action: ActionFor<'SKU_CREATE_SUCCESS'>) {
  addNewSku(action.sku);
}

export default new SkuStore(Dispatcher, {
  [ActionTypes.SKU_FETCH_SUCCESS]: handleSkuFetchSuccess,
  [ActionTypes.SKU_UPDATE_SUCCESS]: handleSkuUpdateSuccess,
  [ActionTypes.PRICE_TIER_VALUES_FETCH_SUCCESS]: handlePriceTierValuesFetchSuccess,
  [ActionTypes.PRICE_TIERS_FETCH_SUCCESS]: handlePriceTiersFetchSuccess,
  [ActionTypes.SKU_CREATE_SUCCESS]: handleSkuCreateSuccess,
  [ActionTypes.USER_LOGOUT]: handleUserLogout,
});
