type Spacing = Record<string, number>;
export const Spacing = {
  PX_4: 4,
  PX_8: 8,
  PX_12: 12,
  PX_16: 16,
  PX_24: 24,
  PX_32: 32,
  PX_40: 40,
  PX_48: 48,
  PX_56: 56,
  PX_64: 64,
  PX_72: 72,
  PX_80: 80,
  PX_96: 96,
} satisfies Spacing;

export const _private = {Spacing};
