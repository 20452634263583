import * as React from 'react';
import classNames from 'classnames';

import Button from '@developers/uikit/Button';
import Flex from '@developers/uikit/Flex';
import {ComponentDispatch} from '@developers/utils/ComponentDispatchUtils';

import {ComponentActions} from '@developers/Constants';
import {Messages} from '@developers/i18n';
import styles from './UnsavedChangesNoticeBar.module.css';

interface Props {
  actionCopy?: React.ReactNode;
  noticeCopy?: React.ReactNode;
  onSave?: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
  onReset: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
  theme?: string;
  submitting: boolean;
  isSubmitDisabled?: boolean;
  message?: string;
}

export default function UnsavedChangesNotice({
  actionCopy = Messages.Actions.SAVE_CHANGES,
  isSubmitDisabled = false,
  noticeCopy = Messages.Common.SETTINGS_NOTICE_MESSAGE,
  submitting,
  onSave,
  onReset,
}: Props) {
  const [emphasized, setEmphasized] = React.useState(false);

  React.useEffect(() => {
    const emphasize = () => {
      setEmphasized(true);
      setTimeout(() => setEmphasized(false), 1000);
    };
    ComponentDispatch.subscribe(ComponentActions.EMPHASIZE_NOTICE, emphasize);
    return () => {
      ComponentDispatch.unsubscribe(ComponentActions.EMPHASIZE_NOTICE, emphasize);
    };
  }, []);

  const handleOnSave = (event: React.SyntheticEvent<HTMLButtonElement>): void => {
    onSave?.(event);
  };

  const handleOnReset = (event: React.SyntheticEvent<HTMLButtonElement>): void => {
    onReset(event);
  };

  return (
    <div className={classNames(styles.container, emphasized ? styles.emphasized : null)}>
      <Flex align={Flex.Align.CENTER} className={styles.contentWrap}>
        <Flex.Child>
          <div className={styles.message}>{noticeCopy}</div>
        </Flex.Child>
        <Flex className={styles.buttonWrap}>
          {onReset != null && (
            <Button
              className={styles.resetButton}
              height={Button.Height.SHORT}
              color={Button.Color.PRIMARY}
              look={Button.Look.LINK}
              onClick={handleOnReset}>
              <span>{Messages.Actions.RESET}</span>
            </Button>
          )}
          <Button
            disabled={isSubmitDisabled}
            className={styles.button}
            color={Button.Color.GREEN}
            submitting={submitting}
            type="submit"
            onClick={handleOnSave}>
            {actionCopy}
          </Button>
        </Flex>
      </Flex>
    </div>
  );
}
