import Dispatcher from '@developers/Dispatcher';
import ActionTypes from './ActionTypes';

export const openMobileMenu = (): void => {
  Dispatcher.dispatch({type: ActionTypes.MOBILE_MENU_OPEN});
};

export const closeMobileMenu = (): void => {
  Dispatcher.dispatch({type: ActionTypes.MOBILE_MENU_CLOSE});
};
