import {getDevicePixelRatio} from './PlatformUtils';

import {Endpoints} from '@developers/Constants';
import type {StoreAsset} from '@developers/flow/Server';

const MAX_IMAGE_SIZE = 1024;

export const Sizes = {
  SMALL: 32,
  MEDIUM_SMALL: 64,
  MEDIUM: 128,
  MEDIUM_LARGE: 256,
  LARGE: 512,
  X_LARGE: 1024,
};

export type SizeMultiple = (typeof Sizes)[keyof typeof Sizes];

const BASE_URL = `${location.protocol}${window.GLOBAL_ENV.API_ENDPOINT}`;

const getSizeString = (size?: number | null): string => (size != null ? `?size=${size}` : '');

export function getApplicationIcon(
  applicationId: string,
  iconId?: string | null,
  size?: SizeMultiple | null
): string | null | undefined {
  const sizeString = size != null ? getSizeString(calculateSize(size)) : '';
  if (iconId != null) {
    if (window.GLOBAL_ENV.CDN_HOST != null) {
      return `${location.protocol}//${window.GLOBAL_ENV.CDN_HOST}/app-icons/${applicationId}/${iconId}.png${sizeString}`;
    } else {
      return `${BASE_URL}/applications/${applicationId}/app-icons/${iconId}.png${sizeString}`;
    }
  }
  return null;
}

export function getAssetImage(
  applicationId: string,
  imageId?: string | null,
  size?: SizeMultiple | null
): string | null | undefined {
  const sizeString = size != null ? getSizeString(calculateSize(size)) : '';

  if (imageId != null) {
    if (window.GLOBAL_ENV.CDN_HOST != null) {
      return `${location.protocol}//${window.GLOBAL_ENV.CDN_HOST}/app-assets/${applicationId}/${imageId}.png${sizeString}`;
    } else {
      return `${BASE_URL}/applications/${applicationId}/app-assets/${imageId}.png${sizeString}`;
    }
  }
  return null;
}

export function getTeamIcon(teamId: string, iconId?: string | null): string | null | undefined {
  if (iconId == null) return null;
  if (window.GLOBAL_ENV.CDN_HOST != null) {
    return `${location.protocol}//${window.GLOBAL_ENV.CDN_HOST}/team-icons/${teamId}/${iconId}.png`;
  }
  return `${BASE_URL}/teams/${teamId}/team-icons/${iconId}.png`;
}

export function getStoreAssetImage(
  applicationId: string,
  asset: StoreAsset,
  size?: number | null,
  format?: string
): string {
  let url;
  const CDN_HOST = window.GLOBAL_ENV.CDN_HOST;
  if (format == null) {
    switch (asset.mime_type) {
      case 'video/quicktime':
        format = 'mp4'; // Undo this when lilliput reports video/mp4 for .mp4
        break;
      case 'video/mp4':
        format = 'mp4';
        break;
      case 'image/gif':
        format = 'gif';
        break;
      default:
        format = 'webp';
    }
  }
  if (CDN_HOST != null) {
    url = `${location.protocol}//${CDN_HOST}/app-assets/${applicationId}/store/${asset.id}.${format}`;
  } else {
    url = `${location.protocol}${window.GLOBAL_ENV.API_ENDPOINT}${Endpoints.STORE_ASSET_IMAGE(
      applicationId,
      asset.id,
      format
    )}`;
  }
  if (size != null) {
    url += getSizeString(calculateSize(size));
  }
  return url;
}

export function calculateSize(size: number) {
  const ratio = getDevicePixelRatio();
  size = ratio > 1 ? size * Math.ceil(ratio) : size;

  // Round size to nearest power of 2.
  size--;
  size |= size >> 1;
  size |= size >> 2;
  size |= size >> 4;
  size |= size >> 8;
  size |= size >> 16;
  size++;

  return Math.min(size, MAX_IMAGE_SIZE);
}
