import * as React from 'react';
import classNames from 'classnames';
import {FocusRing} from 'react-focus-rings';
import {AdvancedScrollerAuto} from '@discordapp/design/components/Scroller/web/AdvancedScroller';
import {useStateFromStores} from '@discordapp/flux';

import UserStore from '@developers/stores/UserStore';
import Avatar, {AvatarSizes} from '@developers/uikit/Avatar';
import ContextMenu from '@developers/uikit/ContextMenu';
import {getUserAvatarURL} from '@developers/utils/AvatarUtils';
import AppHeader, {UserMenu} from './AppHeader';
import {UnsavedChangesNoticePlaceholderMountPoint} from './UnsavedChangesNotice';

import type {AdvancedScrollerRef} from '@discordapp/design/components/Scroller/web/createAdvancedScroller';
import styles from './PageContent.module.css';

interface PageContentProps {
  children: React.ReactNode;
  className?: string;
  innerClassName?: string;
}

const PageContent = React.forwardRef<{scrollToTop: () => void}, PageContentProps>(
  ({children, className, innerClassName}: PageContentProps, ref) => {
    const user = useStateFromStores([UserStore], () => UserStore.user);
    const scrollerRef = React.useRef<AdvancedScrollerRef>(null);
    React.useImperativeHandle(ref, () => ({
      scrollToTop: (): void => {
        const {current} = scrollerRef;
        if (current != null) {
          current.scrollToTop();
        }
      },
    }));

    return (
      <div className={classNames(styles.contentWrapper, className)}>
        <AppHeader />
        <AdvancedScrollerAuto
          className={classNames('page-content-scrolling-container', styles.scroller)}
          ref={scrollerRef}>
          {user == null ? null : (
            <ContextMenu menuContent={<UserMenu />}>
              {(onTriggerClick) => {
                return (
                  <FocusRing offset={-1}>
                    <button className={styles.userMenuButton} type="button" onClick={onTriggerClick}>
                      <Avatar src={getUserAvatarURL(user)} className={styles.avatar} size={AvatarSizes.LARGE} />
                    </button>
                  </FocusRing>
                );
              }}
            </ContextMenu>
          )}
          <div className={classNames(styles.contentWrapperInner, innerClassName)}>{children}</div>
          <UnsavedChangesNoticePlaceholderMountPoint />
        </AdvancedScrollerAuto>
      </div>
    );
  }
);

export default PageContent;
