import {useState, useRef, useLayoutEffect} from 'react';
import shallowEqual, {areArraysShallowEqual} from '@discordapp/shallow-equal';

import BatchedStoreListener from './BatchedStoreListener';

import type Store from './Store';

function defaultAreStatesEqual<T>(state1: T, state2: T): boolean {
  return state1 === state2;
}

/**
 * Use this as a `areStatesEqual` function when states returned from `getStateFromStores()` will never be equal.
 * This disables the debug referential equality checker.
 *
 * NOTE: Use with caution. This is almost never the right solution.
 */
export function statesWillNeverBeEqual<T>(_state1: T, _state2: T): boolean {
  return false;
}

type CompareStatesFn<State> = (prevState: State, newState: State) => boolean;
type Deps = Readonly<any[]> | undefined;
interface HookRef<State, Deps> {
  stores: Array<Store<any>>;
  areStatesEqual: CompareStatesFn<State>;
  getStateFromStores: () => State;
  prevDeps: Deps;
  state: State;
}
export default function useStateFromStores<State>(
  stores: Array<Store<any>>,
  getStateFromStores: () => State,
  dependencies?: Deps,
  areStatesEqual: CompareStatesFn<State> = defaultAreStatesEqual
): State {
  const {current: hookRef} = useRef<HookRef<State, Deps>>({
    stores,
    areStatesEqual,
    getStateFromStores,
    prevDeps: undefined,
    // @ts-expect-error This will be filled in below!
    state: undefined,
  });

  if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
    if (!areArraysShallowEqual(hookRef.stores, stores)) {
      throw new Error('useStateFromStores does not support changing stores');
    }

    if (hookRef.areStatesEqual !== areStatesEqual) {
      throw new Error('useStateFromStores does not support changing areStatesEqual. Memoize if needed');
    }
  }

  let state = hookRef.state;
  if (dependencies == null || !areArraysShallowEqual(dependencies, hookRef.prevDeps)) {
    const newState =
      process.env.NODE_ENV === 'development'
        ? checkReferentialEquality(getStateFromStores, areStatesEqual, stores)
        : getStateFromStores();
    if (state == null || !areStatesEqual(state, newState)) {
      state = newState;
    }
  }

  useLayoutEffect(() => {
    hookRef.getStateFromStores = getStateFromStores;
    hookRef.prevDeps = dependencies;
    hookRef.state = state;
  });

  const [, forceUpdate] = useState<any>(null);
  useLayoutEffect(() => {
    const updateState = () => {
      const newState = hookRef.getStateFromStores();
      // Note: stateRef.current is non-nullable from the layout effect above
      if (!areStatesEqual(hookRef.state!, newState)) {
        hookRef.state = newState;
        forceUpdate({});
      }
    };
    // The state may have changed since we rendered because we're using useLayoutEffect.
    updateState();

    const listener = new BatchedStoreListener(stores, updateState);
    listener.attach('useStateFromStores');
    return () => listener.detach();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // This is tricky.  It _is_ possible for this to return null/undefined if the the getStateFromStores function
  // returns null or undefined.  The problem though is on the first run the ref will be initially undefined and
  // there's not a good way to pass in an initializer function to useRef, so TS can't infer that the type here is
  // always State :(
  return state!;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function useStateFromStoresObject<State extends {}>(
  stores: Array<Store<any>>,
  getStateFromStores: () => State,
  dependencies?: Deps
): State {
  return useStateFromStores<State>(stores, getStateFromStores, dependencies, shallowEqual);
}

export function useStateFromStoresArray<State extends any[] | readonly any[]>(
  stores: Array<Store<any>>,
  getStateFromStores: () => State,
  dependencies?: Deps
): State {
  return useStateFromStores<State>(stores, getStateFromStores, dependencies, areArraysShallowEqual);
}

function checkReferentialEquality<State>(
  getStateFromStores: () => State,
  areStatesEqual: CompareStatesFn<State>,
  stores: Array<Store<any>>
): State {
  if (areStatesEqual === statesWillNeverBeEqual) {
    return getStateFromStores();
  }

  const stateA = getStateFromStores();
  const stateB = getStateFromStores();

  if (!areStatesEqual(stateA, stateB)) {
    // eslint-disable-next-line no-console
    console.error(
      'Two subsequent calls to getStateFromStores within a useStateFromStores returns states that are not equal. ' +
        'Expand the stack trace of this error to find out more!',
      stores.map((store) => (store.constructor as any).displayName ?? store),
      stateA,
      stateB
    );
  }

  return stateA;
}
