/**
 * THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
 *
 * Constants in this file are generated from the Python discord_common.shared_constants module.
 * Run `clyde gen ts-constants` to regenerate.
 */
export enum TeamMemberRoles {
  ADMIN = 'admin',
  DEVELOPER = 'developer',
  READ_ONLY = 'read_only',
}

