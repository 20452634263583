import {makeLazy} from '@discordapp/code-splitting';

import PageLoadingIndicator from '@developers/components/common/PageLoadingIndicator';

import type * as React from 'react';

export const makeLazyWithLoadingIndicator = <ComponentProps extends object>({
  createPromise,
  webpackId,
  renderLoader = PageLoadingIndicator,
}: {
  createPromise: () => Promise<{
    default: React.ComponentType<ComponentProps>;
  }>;
  webpackId: string;
  renderLoader?: () => NonNullable<React.ReactNode> | null;
}) => {
  return makeLazy<ComponentProps>({createPromise, webpackId, renderLoader}) as () => JSX.Element;
};
