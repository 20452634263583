import HTTPUtils, {HTTPResponse} from '@discordapp/http-utils';
import stringifyErrors from '@discordapp/http-utils/stringifyErrors';

import Dispatcher from '@developers/Dispatcher';
import {getFileExtension} from '@developers/utils/AssetUtils';
import {APIErrorHandler} from '@developers/utils/ErrorUtils';
import ActionTypes from './ActionTypes';

import {Endpoints} from '@developers/Constants';
import type {
  ApplicationId,
  ApplicationEmbeddedActivityConfig,
  ApplicationProxyMapping,
  Application,
  AssetId,
  RequestAdditionalIntents,
  Asset,
  ApplicationDirectoryEntry,
} from '@developers/flow/Server';

export const createApplication = (formData: Record<string, unknown>): Promise<Application> => {
  return HTTPUtils.post({
    url: Endpoints.APPLICATIONS,
    body: formData,
    oldFormErrors: true,
  })
    .then((res: HTTPResponse) => {
      const application = res.body;
      Dispatcher.dispatch({type: ActionTypes.APPLICATION_CREATE_SUCCESS, application});
      return application;
    })
    .catch(APIErrorHandler);
};

export const createBot = async (appId: ApplicationId): Promise<Application> => {
  const createBotResponse = await HTTPUtils.post({
    url: Endpoints.APPLICATION_BOT(appId),
    oldFormErrors: true,
  }).catch(APIErrorHandler);

  const application = (await fetchApplication(appId).catch(APIErrorHandler)) as Application;

  if (application.bot != null && createBotResponse?.body.token != null) {
    application.bot.token = createBotResponse.body.token;
  }

  return application;
};

export const addUserToApplicationWhitelist = (
  appId: ApplicationId,
  userData: {
    username: string;
    discriminator: string;
  }
): Promise<void> => {
  return HTTPUtils.post({
    url: Endpoints.APPLICATION_WHITELIST(appId),
    body: userData,
    oldFormErrors: true,
  })
    .then((res: HTTPResponse) => {
      Dispatcher.dispatch({
        type: ActionTypes.APPLICATION_WHITELIST_ADD_USER_SUCCESS,
        appId,
        whitelistEntry: res.body,
      });
    })
    .catch(APIErrorHandler);
};

export const createAsset = (
  appId: ApplicationId,
  assetData: {
    name: string;
    image: string;
    type: string;
  }
): Promise<Asset | void> => {
  return HTTPUtils.post({
    url: Endpoints.APPLICATION_ASSETS(appId),
    body: assetData,
    oldFormErrors: true,
  })
    .then((res: HTTPResponse<Asset>) => {
      Dispatcher.dispatch({
        type: ActionTypes.APPLICATION_ASSETS_ADD_SUCCESS,
        appId,
        asset: res.body,
      });
    })
    .catch(APIErrorHandler);
};

export const deleteApplication = (appId: ApplicationId): Promise<void> => {
  return HTTPUtils.post({
    url: Endpoints.APPLICATION_DELETE(appId),
    oldFormErrors: true,
  })
    .then(() => {
      Dispatcher.dispatch({
        type: ActionTypes.APPLICATION_DELETE_SUCCESS,
        appId,
      });
    })
    .catch(APIErrorHandler);
};

export const deleteAsset = (appId: ApplicationId, assetId: AssetId): Promise<void> => {
  return HTTPUtils.delete({
    url: Endpoints.APPLICATION_ASSET(appId, assetId),
    oldFormErrors: true,
  })
    .then(() => {
      Dispatcher.dispatch({
        type: ActionTypes.APPLICATION_ASSETS_REMOVE_SUCCESS,
        appId,
        assetId,
      });
    })
    .catch(APIErrorHandler);
};

export const fetchApplication = (appId: ApplicationId): Promise<Application> => {
  return HTTPUtils.get({
    url: Endpoints.APPLICATION(appId),
    oldFormErrors: true,
  })
    .then((res: HTTPResponse) => {
      const application: Application = res.body;
      // HACK(shira): ApplicationStore currently merges API results into the existing store models as if they were
      // partials. This means any key that is conditionally serialized will not replace the store's existing value for
      // that key. So for now, lets explicitly set omitted keys to undefined to force the merge.
      application.install_params = application.install_params;
      application.custom_install_url = application.custom_install_url;
      Dispatcher.dispatch({type: ActionTypes.APPLICATION_FETCH_SUCCESS, application});

      return res.body;
    })
    .catch(APIErrorHandler);
};

export const fetchApplicationAssets = (appId: ApplicationId): Promise<void> => {
  return HTTPUtils.get({
    url: Endpoints.APPLICATION_ASSETS(appId),
    oldFormErrors: true,
  })
    .then((res: HTTPResponse) => {
      Dispatcher.dispatch({
        type: ActionTypes.APPLICATION_ASSETS_FETCH_SUCCESS,
        appId,
        assets: res.body,
      });
    })
    .catch(APIErrorHandler);
};

export const fetchApplicationDiscoverabilityState = (appId: ApplicationId): Promise<void> => {
  return HTTPUtils.get({
    url: Endpoints.APPLICATION_DISCOVERABILITY_STATE(appId),
    oldFormErrors: true,
  })
    .then((res: HTTPResponse) => {
      Dispatcher.dispatch({
        type: ActionTypes.APPLICATION_DISCOVERABILITY_STATE_FETCH_SUCCESS,
        appId,
        badCommands: res.body.bad_commands,
        discoverabilityState: res.body.discoverability_state,
        discoveryEligibilityFlags: res.body.discovery_eligibility_flags,
      });
    })
    .catch(APIErrorHandler);
};

export const fetchApplications = (options?: {withTeamApplications: boolean}): Promise<void> => {
  return HTTPUtils.get({
    url: Endpoints.APPLICATIONS,
    query: {
      with_team_applications: options != null && options.withTeamApplications,
    },
    oldFormErrors: true,
  })
    .then((res: HTTPResponse) => {
      Dispatcher.dispatch({type: ActionTypes.APPLICATIONS_FETCH_SUCCESS, applications: res.body});
    })
    .catch(APIErrorHandler);
};

export const fetchApplicationDirectoryEntry = (
  appId: ApplicationId,
  withLocalizations?: boolean
): Promise<HTTPResponse<ApplicationDirectoryEntry>> => {
  let url = Endpoints.APPLICATION_DIRECTORY_ENTRY(appId);

  if (withLocalizations) {
    url += '?with_localizations=true';
  }

  return HTTPUtils.get({
    url,
    oldFormErrors: true,
  }).catch(APIErrorHandler);
};

export const fetchApplicationWhitelist = (appId: ApplicationId): Promise<unknown> => {
  return HTTPUtils.get({
    url: Endpoints.APPLICATION_WHITELIST(appId),
    oldFormErrors: true,
  })
    .then((res: HTTPResponse) => {
      Dispatcher.dispatch({
        type: ActionTypes.APPLICATION_WHITELIST_FETCH_SUCCESS,
        appId,
        whitelist: res.body,
      });
    })
    .catch(APIErrorHandler);
};

export const generateSecret = (appId: ApplicationId): Promise<void> => {
  return HTTPUtils.post({
    url: Endpoints.APPLICATION_RESET(appId),
    oldFormErrors: true,
  })
    .then(({body}) => {
      Dispatcher.dispatch({
        type: ActionTypes.APPLICATION_RESET_SECRET_SUCCESS,
        appId,
        secret: body.secret,
      });
    })
    .catch(APIErrorHandler);
};

export const generateToken = (appId: ApplicationId): Promise<void> => {
  return HTTPUtils.post({
    url: Endpoints.APPLICATION_BOT_RESET(appId),
    oldFormErrors: true,
  })
    .then(({body}) => {
      Dispatcher.dispatch({
        type: ActionTypes.APPLICATION_RESET_BOT_TOKEN_SUCCESS,
        appId,
        token: body.token,
      });
    })
    .catch(APIErrorHandler);
};

export const removeUserFromApplicationWhitelist = (appId: ApplicationId, userId: string): Promise<void> => {
  return HTTPUtils.delete({
    url: Endpoints.APPLICATION_WHITELIST_USER(appId, userId),
    oldFormErrors: true,
  })
    .then(() => {
      Dispatcher.dispatch({
        type: ActionTypes.APPLICATION_WHITELIST_REMOVE_USER_SUCCESS,
        appId,
        userId,
      });
    })
    .catch(APIErrorHandler);
};

export const updateApplication = (appId: ApplicationId, formData: Record<string, unknown>): Promise<Application> => {
  return HTTPUtils.patch({
    url: Endpoints.APPLICATION(appId),
    body: formData,
    oldFormErrors: true,
  })
    .then(() => fetchApplication(appId))
    .catch(APIErrorHandler);
};

export const transferAppOwnership = (appId: ApplicationId, formData: Record<string, unknown>): Promise<void> => {
  return HTTPUtils.post({
    url: Endpoints.APPLICATION_OWNER_TRANSFER(appId),
    body: formData,
    oldFormErrors: true,
  })
    .then((res: HTTPResponse) => {
      Dispatcher.dispatch({type: ActionTypes.APPLICATION_OWNER_TRANSFER_SUCCESS, application: res.body});
    })
    .catch(APIErrorHandler);
};

export const updateBot = (
  appId: ApplicationId,
  formData: {
    username: string;
    avatar: string;
    banner: string;
  }
): Promise<Application | void> => {
  return HTTPUtils.patch({
    url: Endpoints.APPLICATION_BOT(appId),
    body: formData,
    oldFormErrors: true,
  })
    .then(() => fetchApplication(appId))
    .catch(APIErrorHandler);
};

export const fetchStoreAssets = (appId: ApplicationId): Promise<void> => {
  return HTTPUtils.get({
    url: Endpoints.STORE_ASSETS(appId),
    oldFormErrors: true,
  }).then((res: HTTPResponse) => {
    Dispatcher.dispatch({
      type: ActionTypes.STORE_ASSETS_FETCH_SUCCESS,
      appId,
      assets: res.body,
    });
  });
};

export const deleteStoreAsset = (appId: ApplicationId, assetId: string): Promise<void> => {
  return HTTPUtils.delete({
    url: Endpoints.DELETE_STORE_ASSET(appId, assetId),
    oldFormErrors: true,
  }).then(() => {
    Dispatcher.dispatch({
      type: ActionTypes.STORE_ASSET_REMOVE_SUCCESS,
      appId,
      assetId,
    });
  });
};

export const uploadStoreAsset = (
  appId: ApplicationId,
  asset: {
    filename: string;
    file: File;
  }
): Promise<void> => {
  return HTTPUtils.post({
    url: Endpoints.STORE_ASSETS(appId),
    attachments: [
      {
        name: 'assets',
        file: asset.file,
        filename: `${asset.filename}${getFileExtension(asset.file) ?? ''}`,
      },
    ],
    oldFormErrors: true,
  })
    .then((res: HTTPResponse) => {
      Dispatcher.dispatch({
        type: ActionTypes.STORE_ASSET_UPLOAD_SUCCESS,
        appId,
        asset: res.body,
      });
    })
    .catch((res: HTTPResponse) => {
      throw new Error(stringifyErrors(res.body));
    });
};

export const fetchManifestLabels = (appId: ApplicationId): Promise<void> => {
  return HTTPUtils.get({
    url: Endpoints.MANIFEST_LABELS(appId),
    oldFormErrors: true,
  }).then((res: HTTPResponse) => {
    Dispatcher.dispatch({
      type: ActionTypes.MANIFEST_LABELS_FETCH_SUCCESS,
      appId,
      manifestLabels: res.body,
    });
  });
};

export const activateApplicationLicense = (appId: ApplicationId, guildId: string): Promise<void> => {
  return HTTPUtils.post({
    url: Endpoints.APPLICATION_LICENSE_ACTIVATE(appId),
    body: {
      guild_id: guildId,
    },
    oldFormErrors: true,
  })
    .then((res: HTTPResponse) => {
      Dispatcher.dispatch({
        type: ActionTypes.APPLICATION_LICENSE_ACTIVATE_SUCCESS,
        application: res.body,
      });
    })
    .catch(APIErrorHandler);
};

export const submitStoreApplicationForApproval = (appId: ApplicationId): Promise<HTTPResponse> => {
  return HTTPUtils.post({
    url: Endpoints.APPLICATION_APPROVALS(appId),
    oldFormErrors: true,
  }).catch(APIErrorHandler);
};

export const submitApplicationAdditionalIntentsRequest = (
  appId: ApplicationId,
  body: RequestAdditionalIntents
): Promise<Application> => {
  return HTTPUtils.post({
    url: Endpoints.APPLICATION_REQUEST_ADDITIONAL_INTENTS(appId),
    body,
    oldFormErrors: true,
  })
    .then(() => fetchApplication(appId))
    .catch(APIErrorHandler);
};

export const checkApplicationVerificationEligibility = (appId: ApplicationId): Promise<HTTPResponse> => {
  return HTTPUtils.get({
    url: Endpoints.APPLICATION_VERIFICATION_REQUEST(appId),
  }).catch((response) => {
    throw new HTTPUtils.V8APIError(response);
  });
};

export const submitApplicationVerificationRequest = (appId: ApplicationId, body: unknown): Promise<HTTPResponse> => {
  return HTTPUtils.post({
    url: Endpoints.APPLICATION_VERIFICATION_REQUEST(appId),
    body,
    oldFormErrors: true,
  })
    .then((response) => {
      fetchApplication(appId);
      return response;
    })
    .catch(APIErrorHandler);
};

export const getApplicationProxyConfig = (appId: ApplicationId): Promise<void> => {
  return HTTPUtils.get({
    url: Endpoints.APPLICATION_PROXY_CONFIG(appId),
  })
    .then((response) => {
      Dispatcher.dispatch({
        type: ActionTypes.APPLICATION_PROXY_CONFIG_FETCH_SUCCESS,
        appId,
        config: response.body.url_map,
      });
    })
    .catch(APIErrorHandler);
};

export const updateApplicationProxyConfig = (appId: ApplicationId, updatedConfig: ApplicationProxyMapping[]) => {
  return HTTPUtils.post({
    url: Endpoints.APPLICATION_PROXY_CONFIG(appId),
    body: {
      /* eslint-disable-next-line */
      url_map: updatedConfig,
    },
  })
    .then((response) => {
      Dispatcher.dispatch({
        type: ActionTypes.APPLICATION_PROXY_CONFIG_UPDATE_SUCCESS,
        appId,
        config: response.body.url_map,
      });
    })
    .catch(APIErrorHandler);
};

export const getApplicationEmbeddedActivityConfig = (appId: ApplicationId): Promise<void> => {
  return HTTPUtils.get({
    url: Endpoints.APPLICATION_EMBEDDED_ACTIVITY_CONFIG(appId),
  })
    .then((response) => {
      Dispatcher.dispatch({
        type: ActionTypes.APPLICATION_EMBEDDED_ACTIVITY_CONFIG_FETCH_SUCCESS,
        appId,
        config: response.body,
      });
    })
    .catch(APIErrorHandler);
};

export const updateApplicationEmbeddedActivityConfig = (
  appId: ApplicationId,
  updatedConfig: Partial<ApplicationEmbeddedActivityConfig>
) => {
  return HTTPUtils.patch({
    url: Endpoints.APPLICATION_EMBEDDED_ACTIVITY_CONFIG(appId),
    body: updatedConfig,
  })
    .then((response) => {
      Dispatcher.dispatch({
        type: ActionTypes.APPLICATION_EMBEDDED_ACTIVITY_CONFIG_UPDATE_SUCCESS,
        appId,
        config: response.body,
      });
    })
    .catch(APIErrorHandler);
};

export const setApplicationEmbedded = (appId: ApplicationId, embedded: boolean) => {
  return HTTPUtils.post({
    url: Endpoints.APPLICATION_EMBEDDED_TOGGLE(appId),
    body: {
      embedded,
    },
  })
    .then(() => fetchApplication(appId))
    .catch(APIErrorHandler);
};

export const updateApplicationDirectoryEntry = (appId: ApplicationId, body: unknown): Promise<unknown> => {
  return HTTPUtils.patch({
    body,
    url: Endpoints.APPLICATION_DIRECTORY_ENTRY(appId),
  }).catch((r) => {
    throw new HTTPUtils.V8APIError(r);
  });
};

export const acknowledgeOnboarding = (applicationId: ApplicationId) => {
  Dispatcher.dispatch({
    type: ActionTypes.ACKNOWLEDGE_ONBOARDING,
    applicationId,
  });
};
