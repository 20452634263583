/**
 * Polyfill crypto.randomUUID in dev mode since it's
 * not available in insecure contexts (aka local ips).
 *
 * https://stackoverflow.com/a/2117523/2800218
 * LICENSE: https://creativecommons.org/licenses/by-sa/4.0/legalcode
 */
if (__DEV__) {
  if (!('randomUUID' in crypto)) {
    // @ts-expect-error
    crypto.randomUUID = function randomUUID() {
      return (
        // @ts-expect-error
        ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(
          /[018]/g,
          // @ts-expect-error
          (c) => (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
        )
      );
    };
  }
}
