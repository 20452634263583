import type {TrackFunctionOptions} from './AnalyticsUtils';
import type {Dispatcher} from '@discordapp/flux';

/**
 * Returns a track function
 *
 * `discord_common` is not aware of service-specific actions, so these need to be passed in to build a track function.
 */
export default (Dispatcher: Dispatcher<any>, TRACK: string) => {
  return function track(event: string, properties?: Record<string, any>, options?: TrackFunctionOptions) {
    // The promise's resolve is used to expose when events have successfully
    // been sent to the server. However, calling the resolve function should
    // only be called internally inside this package and for this specific use case.
    return new Promise<void>((resolve) => {
      Dispatcher.dispatch({
        type: TRACK,
        event,
        properties,
        flush: options?.flush ?? false,
        fingerprint: options?.fingerprint,
        resolve,
      });
    });
  };
};
