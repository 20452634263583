/*
  THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY
  If you need to add or edit existing variables, check out
  discord_common/js/packages/tokens/definitions, then run
  `clyde gen design tokens`
*/

const Colors = Object.freeze({
  APPLICATION_SUBSCRIPTION_END: 'hsl(197, calc(var(--saturation-factor, 1) * 98.8%), 32.4%)',
  APPLICATION_SUBSCRIPTION_START: 'hsl(235, calc(var(--saturation-factor, 1) * 68.5%), 52.7%)',
  BATTLENET: 'hsl(200, calc(var(--saturation-factor, 1) * 100%), 44.9%)',
  BG_GRADIENT_AURORA_1: 'hsl(220, calc(var(--saturation-factor, 1) * 86.5%), 17.5%)',
  BG_GRADIENT_AURORA_2: 'hsl(238, calc(var(--saturation-factor, 1) * 76.4%), 41.6%)',
  BG_GRADIENT_AURORA_3: 'hsl(184, calc(var(--saturation-factor, 1) * 78%), 33.9%)',
  BG_GRADIENT_AURORA_4: 'hsl(169, calc(var(--saturation-factor, 1) * 60.2%), 32.5%)',
  BG_GRADIENT_AURORA_5: 'hsl(230, calc(var(--saturation-factor, 1) * 92.5%), 26.3%)',
  BG_GRADIENT_CHROMA_GLOW_1: 'hsl(183, calc(var(--saturation-factor, 1) * 86.3%), 40.2%)',
  BG_GRADIENT_CHROMA_GLOW_2: 'hsl(258, calc(var(--saturation-factor, 1) * 89.8%), 46.3%)',
  BG_GRADIENT_CHROMA_GLOW_3: 'hsl(298, calc(var(--saturation-factor, 1) * 90.9%), 34.3%)',
  BG_GRADIENT_CHROMA_GLOW_4: 'hsl(265, calc(var(--saturation-factor, 1) * 100%), 66.3%)',
  BG_GRADIENT_CHROMA_GLOW_5: 'hsl(207, calc(var(--saturation-factor, 1) * 75.5%), 50.4%)',
  BG_GRADIENT_CITRUS_SHERBERT_1: 'hsl(40, calc(var(--saturation-factor, 1) * 88.7%), 58.2%)',
  BG_GRADIENT_CITRUS_SHERBERT_2: 'hsl(18, calc(var(--saturation-factor, 1) * 81.5%), 63.9%)',
  BG_GRADIENT_COTTON_CANDY_1: 'hsl(349, calc(var(--saturation-factor, 1) * 76.8%), 81.4%)',
  BG_GRADIENT_COTTON_CANDY_2: 'hsl(226, calc(var(--saturation-factor, 1) * 92.6%), 84.1%)',
  BG_GRADIENT_CRIMSON_MOON_1: 'hsl(0, calc(var(--saturation-factor, 1) * 88.6%), 31%)',
  BG_GRADIENT_CRIMSON_MOON_2: 'hsl(0, calc(var(--saturation-factor, 1) * 0%), 0%)',
  BG_GRADIENT_DESERT_KHAKI_1: 'hsl(29, calc(var(--saturation-factor, 1) * 32.4%), 86.1%)',
  BG_GRADIENT_DESERT_KHAKI_2: 'hsl(40, calc(var(--saturation-factor, 1) * 41.3%), 78.6%)',
  BG_GRADIENT_DESERT_KHAKI_3: 'hsl(50, calc(var(--saturation-factor, 1) * 49.6%), 75.9%)',
  BG_GRADIENT_DUSK_1: 'hsl(293, calc(var(--saturation-factor, 1) * 13.5%), 36.3%)',
  BG_GRADIENT_DUSK_2: 'hsl(223, calc(var(--saturation-factor, 1) * 41%), 69.4%)',
  BG_GRADIENT_EASTER_EGG_1: 'hsl(227, calc(var(--saturation-factor, 1) * 58.4%), 65.1%)',
  BG_GRADIENT_EASTER_EGG_2: 'hsl(227, calc(var(--saturation-factor, 1) * 31%), 44.3%)',
  BG_GRADIENT_FOREST_1: 'hsl(124, calc(var(--saturation-factor, 1) * 25.9%), 10.6%)',
  BG_GRADIENT_FOREST_2: 'hsl(143, calc(var(--saturation-factor, 1) * 26.2%), 23.9%)',
  BG_GRADIENT_FOREST_3: 'hsl(76, calc(var(--saturation-factor, 1) * 20.6%), 24.7%)',
  BG_GRADIENT_FOREST_4: 'hsl(117, calc(var(--saturation-factor, 1) * 17%), 41.6%)',
  BG_GRADIENT_FOREST_5: 'hsl(43, calc(var(--saturation-factor, 1) * 38.5%), 47.8%)',
  BG_GRADIENT_HANAMI_1: 'hsl(352, calc(var(--saturation-factor, 1) * 68.3%), 80.2%)',
  BG_GRADIENT_HANAMI_2: 'hsl(43, calc(var(--saturation-factor, 1) * 73.6%), 76.3%)',
  BG_GRADIENT_HANAMI_3: 'hsl(116, calc(var(--saturation-factor, 1) * 43.1%), 74.5%)',
  BG_GRADIENT_LOFI_VIBES_1: 'hsl(220, calc(var(--saturation-factor, 1) * 83.8%), 80.6%)',
  BG_GRADIENT_LOFI_VIBES_2: 'hsl(184, calc(var(--saturation-factor, 1) * 57.8%), 78.6%)',
  BG_GRADIENT_LOFI_VIBES_3: 'hsl(130, calc(var(--saturation-factor, 1) * 46.3%), 78.8%)',
  BG_GRADIENT_LOFI_VIBES_4: 'hsl(76, calc(var(--saturation-factor, 1) * 48.8%), 75.5%)',
  BG_GRADIENT_MARS_1: 'hsl(15, calc(var(--saturation-factor, 1) * 36.3%), 39.4%)',
  BG_GRADIENT_MARS_2: 'hsl(0, calc(var(--saturation-factor, 1) * 36.2%), 41.2%)',
  BG_GRADIENT_MIDNIGHT_BLURPLE_1: 'hsl(245, calc(var(--saturation-factor, 1) * 55.1%), 53.7%)',
  BG_GRADIENT_MIDNIGHT_BLURPLE_2: 'hsl(259, calc(var(--saturation-factor, 1) * 74.5%), 10.8%)',
  BG_GRADIENT_MINT_APPLE_1: 'hsl(166, calc(var(--saturation-factor, 1) * 39.7%), 52.5%)',
  BG_GRADIENT_MINT_APPLE_2: 'hsl(119, calc(var(--saturation-factor, 1) * 40.4%), 55.9%)',
  BG_GRADIENT_MINT_APPLE_3: 'hsl(87, calc(var(--saturation-factor, 1) * 48.3%), 59.8%)',
  BG_GRADIENT_NEON_NIGHTS_1: 'hsl(176, calc(var(--saturation-factor, 1) * 98.8%), 33.1%)',
  BG_GRADIENT_NEON_NIGHTS_2: 'hsl(259, calc(var(--saturation-factor, 1) * 39.5%), 55.3%)',
  BG_GRADIENT_NEON_NIGHTS_3: 'hsl(314, calc(var(--saturation-factor, 1) * 52.5%), 46.3%)',
  BG_GRADIENT_RETRO_RAINCLOUD_1: 'hsl(202, calc(var(--saturation-factor, 1) * 47%), 42.9%)',
  BG_GRADIENT_RETRO_RAINCLOUD_2: 'hsl(241, calc(var(--saturation-factor, 1) * 29.6%), 61%)',
  BG_GRADIENT_RETRO_STORM_1: 'hsl(202, calc(var(--saturation-factor, 1) * 47%), 42.9%)',
  BG_GRADIENT_RETRO_STORM_2: 'hsl(241, calc(var(--saturation-factor, 1) * 27.8%), 47.3%)',
  BG_GRADIENT_SEPIA_1: 'hsl(33, calc(var(--saturation-factor, 1) * 14.2%), 45.7%)',
  BG_GRADIENT_SEPIA_2: 'hsl(36, calc(var(--saturation-factor, 1) * 46.8%), 24.3%)',
  BG_GRADIENT_STRAWBERRY_LEMONADE_1: 'hsl(327, calc(var(--saturation-factor, 1) * 74.1%), 39.4%)',
  BG_GRADIENT_STRAWBERRY_LEMONADE_2: 'hsl(28, calc(var(--saturation-factor, 1) * 71.7%), 44.3%)',
  BG_GRADIENT_STRAWBERRY_LEMONADE_3: 'hsl(40, calc(var(--saturation-factor, 1) * 80.2%), 52.5%)',
  BG_GRADIENT_SUNRISE_1: 'hsl(327, calc(var(--saturation-factor, 1) * 42%), 43.9%)',
  BG_GRADIENT_SUNRISE_2: 'hsl(27, calc(var(--saturation-factor, 1) * 44.9%), 58%)',
  BG_GRADIENT_SUNRISE_3: 'hsl(50, calc(var(--saturation-factor, 1) * 46.3%), 44.5%)',
  BG_GRADIENT_SUNSET_1: 'hsl(259, calc(var(--saturation-factor, 1) * 55.6%), 35.3%)',
  BG_GRADIENT_SUNSET_2: 'hsl(22, calc(var(--saturation-factor, 1) * 66.7%), 57.6%)',
  BG_GRADIENT_UNDER_THE_SEA_1: 'hsl(115, calc(var(--saturation-factor, 1) * 10.5%), 42.9%)',
  BG_GRADIENT_UNDER_THE_SEA_2: 'hsl(159, calc(var(--saturation-factor, 1) * 20.4%), 43.3%)',
  BG_GRADIENT_UNDER_THE_SEA_3: 'hsl(175, calc(var(--saturation-factor, 1) * 10.9%), 46.7%)',
  BLACK_100: 'hsl(0, calc(var(--saturation-factor, 1) * 0%), 94.9%)',
  BLACK_130: 'hsl(0, calc(var(--saturation-factor, 1) * 0%), 91%)',
  BLACK_160: 'hsl(0, calc(var(--saturation-factor, 1) * 0%), 85.5%)',
  BLACK_200: 'hsl(0, calc(var(--saturation-factor, 1) * 0%), 80%)',
  BLACK_230: 'hsl(0, calc(var(--saturation-factor, 1) * 0%), 74.1%)',
  BLACK_260: 'hsl(0, calc(var(--saturation-factor, 1) * 0%), 67.5%)',
  BLACK_300: 'hsl(0, calc(var(--saturation-factor, 1) * 0%), 60%)',
  BLACK_330: 'hsl(0, calc(var(--saturation-factor, 1) * 0%), 47.8%)',
  BLACK_345: 'hsl(0, calc(var(--saturation-factor, 1) * 0%), 40%)',
  BLACK_360: 'hsl(0, calc(var(--saturation-factor, 1) * 0%), 36.1%)',
  BLACK_400: 'hsl(0, calc(var(--saturation-factor, 1) * 0%), 20%)',
  BLACK_430: 'hsl(0, calc(var(--saturation-factor, 1) * 0%), 14.5%)',
  BLACK_460: 'hsl(0, calc(var(--saturation-factor, 1) * 0%), 7.8%)',
  BLACK_500: 'hsl(0, calc(var(--saturation-factor, 1) * 0%), 0%)',
  BLACK: 'hsl(0, calc(var(--saturation-factor, 1) * 0%), 0%)',
  BLACK_530: 'hsl(0, calc(var(--saturation-factor, 1) * 0%), 0%)',
  BLACK_560: 'hsl(0, calc(var(--saturation-factor, 1) * 0%), 0%)',
  BLACK_600: 'hsl(0, calc(var(--saturation-factor, 1) * 0%), 0%)',
  BLACK_630: 'hsl(0, calc(var(--saturation-factor, 1) * 0%), 0%)',
  BLACK_660: 'hsl(0, calc(var(--saturation-factor, 1) * 0%), 0%)',
  BLACK_700: 'hsl(0, calc(var(--saturation-factor, 1) * 0%), 0%)',
  BLACK_730: 'hsl(0, calc(var(--saturation-factor, 1) * 0%), 0%)',
  BLACK_760: 'hsl(0, calc(var(--saturation-factor, 1) * 0%), 0%)',
  BLACK_800: 'hsl(0, calc(var(--saturation-factor, 1) * 0%), 0%)',
  BLACK_830: 'hsl(0, calc(var(--saturation-factor, 1) * 0%), 0%)',
  BLACK_860: 'hsl(0, calc(var(--saturation-factor, 1) * 0%), 0%)',
  BLACK_900: 'hsl(0, calc(var(--saturation-factor, 1) * 0%), 0%)',
  BLUE_100: 'hsl(210, calc(var(--saturation-factor, 1) * 80%), 98%)',
  BLUE_130: 'hsl(210, calc(var(--saturation-factor, 1) * 87.5%), 96.9%)',
  BLUE_160: 'hsl(209, calc(var(--saturation-factor, 1) * 87.1%), 93.9%)',
  BLUE_200: 'hsl(206, calc(var(--saturation-factor, 1) * 92.3%), 89.8%)',
  BLUE_230: 'hsl(205, calc(var(--saturation-factor, 1) * 92.5%), 84.3%)',
  BLUE_260: 'hsl(204, calc(var(--saturation-factor, 1) * 94.5%), 78.4%)',
  BLUE_300: 'hsl(203, calc(var(--saturation-factor, 1) * 97.4%), 69.6%)',
  BLUE_330: 'hsl(201, calc(var(--saturation-factor, 1) * 100%), 59%)',
  BLUE_345: 'hsl(200, calc(var(--saturation-factor, 1) * 100%), 49.4%)',
  BLUE_360: 'hsl(203, calc(var(--saturation-factor, 1) * 100%), 47.5%)',
  BLUE_400: 'hsl(207, calc(var(--saturation-factor, 1) * 100%), 46.1%)',
  BLUE_430: 'hsl(212, calc(var(--saturation-factor, 1) * 100%), 45.3%)',
  BLUE_460: 'hsl(214, calc(var(--saturation-factor, 1) * 100%), 41%)',
  BLUE_500: 'hsl(213, calc(var(--saturation-factor, 1) * 100%), 35.7%)',
  BLUE: 'hsl(213, calc(var(--saturation-factor, 1) * 100%), 35.7%)',
  BLUE_530: 'hsl(212, calc(var(--saturation-factor, 1) * 100%), 30.4%)',
  BLUE_560: 'hsl(212, calc(var(--saturation-factor, 1) * 100%), 26.7%)',
  BLUE_600: 'hsl(211, calc(var(--saturation-factor, 1) * 100%), 23.1%)',
  BLUE_630: 'hsl(211, calc(var(--saturation-factor, 1) * 100%), 20.8%)',
  BLUE_660: 'hsl(212, calc(var(--saturation-factor, 1) * 100%), 18.6%)',
  BLUE_700: 'hsl(212, calc(var(--saturation-factor, 1) * 100%), 16.7%)',
  BLUE_730: 'hsl(211, calc(var(--saturation-factor, 1) * 100%), 14.1%)',
  BLUE_760: 'hsl(211, calc(var(--saturation-factor, 1) * 100%), 12.4%)',
  BLUE_800: 'hsl(211, calc(var(--saturation-factor, 1) * 100%), 10.6%)',
  BLUE_830: 'hsl(213, calc(var(--saturation-factor, 1) * 100%), 9.4%)',
  BLUE_860: 'hsl(213, calc(var(--saturation-factor, 1) * 100%), 8.4%)',
  BLUE_900: 'hsl(213, calc(var(--saturation-factor, 1) * 100%), 7.1%)',
  BRAND_100: 'hsl(240, calc(var(--saturation-factor, 1) * 77.8%), 98.2%)',
  BRAND_130: 'hsl(236, calc(var(--saturation-factor, 1) * 87.5%), 96.9%)',
  BRAND_160: 'hsl(235, calc(var(--saturation-factor, 1) * 84.6%), 94.9%)',
  BRAND_200: 'hsl(236, calc(var(--saturation-factor, 1) * 83.3%), 92.9%)',
  BRAND_230: 'hsl(236, calc(var(--saturation-factor, 1) * 87%), 91%)',
  BRAND_260: 'hsl(235, calc(var(--saturation-factor, 1) * 86.2%), 88.6%)',
  BRAND_300: 'hsl(235, calc(var(--saturation-factor, 1) * 86.1%), 85.9%)',
  BRAND_330: 'hsl(235, calc(var(--saturation-factor, 1) * 85.1%), 81.6%)',
  BRAND_345: 'hsl(235, calc(var(--saturation-factor, 1) * 85.2%), 78.8%)',
  BRAND_360: 'hsl(235, calc(var(--saturation-factor, 1) * 86.1%), 77.5%)',
  BRAND_400: 'hsl(235, calc(var(--saturation-factor, 1) * 86.1%), 71.8%)',
  BRAND_430: 'hsl(235, calc(var(--saturation-factor, 1) * 85.7%), 69.8%)',
  BRAND_460: 'hsl(235, calc(var(--saturation-factor, 1) * 85.5%), 67.5%)',
  BRAND_500: 'hsl(235, calc(var(--saturation-factor, 1) * 85.6%), 64.7%)',
  BRAND: 'hsl(235, calc(var(--saturation-factor, 1) * 85.6%), 64.7%)',
  BRAND_530: 'hsl(235, calc(var(--saturation-factor, 1) * 66.7%), 58.8%)',
  BRAND_560: 'hsl(235, calc(var(--saturation-factor, 1) * 51.4%), 52.4%)',
  BRAND_600: 'hsl(235, calc(var(--saturation-factor, 1) * 46.7%), 44.1%)',
  BRAND_630: 'hsl(235, calc(var(--saturation-factor, 1) * 46.7%), 38.2%)',
  BRAND_660: 'hsl(235, calc(var(--saturation-factor, 1) * 47.1%), 33.3%)',
  BRAND_700: 'hsl(235, calc(var(--saturation-factor, 1) * 47%), 25.9%)',
  BRAND_730: 'hsl(235, calc(var(--saturation-factor, 1) * 46.8%), 24.3%)',
  BRAND_760: 'hsl(234, calc(var(--saturation-factor, 1) * 46.9%), 22.2%)',
  BRAND_800: 'hsl(235, calc(var(--saturation-factor, 1) * 47.5%), 19.4%)',
  BRAND_830: 'hsl(235, calc(var(--saturation-factor, 1) * 47.4%), 14.9%)',
  BRAND_860: 'hsl(235, calc(var(--saturation-factor, 1) * 46.9%), 9.6%)',
  BRAND_900: 'hsl(233, calc(var(--saturation-factor, 1) * 50%), 3.1%)',
  BUNGIE: 'hsl(197, calc(var(--saturation-factor, 1) * 100%), 44.5%)',
  CRUNCHYROLL: 'hsl(29, calc(var(--saturation-factor, 1) * 93%), 55.5%)',
  DISCORD_8TH_BDAY_BLUE: 'hsl(234, calc(var(--saturation-factor, 1) * 93.1%), 65.7%)',
  DISCORD_8TH_BDAY_DARK_BLUE: 'hsl(235, calc(var(--saturation-factor, 1) * 73.7%), 53.7%)',
  DISCORD_8TH_BDAY_GREEN: 'hsl(79, calc(var(--saturation-factor, 1) * 100%), 46.9%)',
  DISCORD_8TH_BDAY_LIGHT_BLUE: 'hsl(222, calc(var(--saturation-factor, 1) * 100%), 68.4%)',
  DISCORD_8TH_BDAY_PINK: 'hsl(316, calc(var(--saturation-factor, 1) * 100%), 66.1%)',
  DISCORD_8TH_BDAY_PURPLE: 'hsl(234, calc(var(--saturation-factor, 1) * 93.1%), 65.7%)',
  DISCORD_8TH_BDAY_YELLOW: 'hsl(60, calc(var(--saturation-factor, 1) * 100%), 50%)',
  EBAY: 'hsl(211, calc(var(--saturation-factor, 1) * 100%), 41.2%)',
  EPIC_GAMES: 'hsl(34, calc(var(--saturation-factor, 1) * 6.2%), 22.2%)',
  FACEBOOK: 'hsl(221, calc(var(--saturation-factor, 1) * 44.2%), 37.3%)',
  GITHUB: 'hsl(0, calc(var(--saturation-factor, 1) * 4.2%), 9.4%)',
  GOLD: 'hsl(46, calc(var(--saturation-factor, 1) * 100%), 54.9%)',
  GREEN_100: 'hsl(137, calc(var(--saturation-factor, 1) * 90%), 96.1%)',
  GREEN_130: 'hsl(137, calc(var(--saturation-factor, 1) * 94.1%), 93.3%)',
  GREEN_160: 'hsl(141, calc(var(--saturation-factor, 1) * 91.3%), 86.5%)',
  GREEN_200: 'hsl(143, calc(var(--saturation-factor, 1) * 93.5%), 75.9%)',
  GREEN_230: 'hsl(146, calc(var(--saturation-factor, 1) * 86.6%), 64.9%)',
  GREEN_260: 'hsl(146, calc(var(--saturation-factor, 1) * 75.1%), 59%)',
  GREEN_300: 'hsl(146, calc(var(--saturation-factor, 1) * 65.4%), 53.5%)',
  GREEN_330: 'hsl(146, calc(var(--saturation-factor, 1) * 63.1%), 47.8%)',
  GREEN_345: 'hsl(147, calc(var(--saturation-factor, 1) * 65.9%), 43.7%)',
  GREEN_360: 'hsl(145, calc(var(--saturation-factor, 1) * 65%), 39.2%)',
  GREEN_400: 'hsl(143, calc(var(--saturation-factor, 1) * 60.7%), 35.9%)',
  GREEN_430: 'hsl(142, calc(var(--saturation-factor, 1) * 56.1%), 32.2%)',
  GREEN_460: 'hsl(141, calc(var(--saturation-factor, 1) * 56.6%), 28%)',
  GREEN_500: 'hsl(141, calc(var(--saturation-factor, 1) * 58.4%), 24.5%)',
  GREEN: 'hsl(141, calc(var(--saturation-factor, 1) * 58.4%), 24.5%)',
  GREEN_530: 'hsl(140, calc(var(--saturation-factor, 1) * 60.7%), 21%)',
  GREEN_560: 'hsl(139, calc(var(--saturation-factor, 1) * 61.7%), 18.4%)',
  GREEN_600: 'hsl(139, calc(var(--saturation-factor, 1) * 65.4%), 15.9%)',
  GREEN_630: 'hsl(140, calc(var(--saturation-factor, 1) * 66.7%), 14.1%)',
  GREEN_660: 'hsl(139, calc(var(--saturation-factor, 1) * 68.8%), 12.5%)',
  GREEN_700: 'hsl(141, calc(var(--saturation-factor, 1) * 74.1%), 10.6%)',
  GREEN_730: 'hsl(138, calc(var(--saturation-factor, 1) * 78.3%), 9%)',
  GREEN_760: 'hsl(140, calc(var(--saturation-factor, 1) * 84.6%), 7.6%)',
  GREEN_800: 'hsl(139, calc(var(--saturation-factor, 1) * 82.4%), 6.7%)',
  GREEN_830: 'hsl(138, calc(var(--saturation-factor, 1) * 80%), 5.9%)',
  GREEN_860: 'hsl(133, calc(var(--saturation-factor, 1) * 70.4%), 5.3%)',
  GREEN_900: 'hsl(129, calc(var(--saturation-factor, 1) * 58.3%), 4.7%)',
  GUILD_BOOSTING_BLUE: 'hsl(221, calc(var(--saturation-factor, 1) * 70%), 55.5%)',
  GUILD_BOOSTING_BLUE_FOR_GRADIENTS: 'hsl(235, calc(var(--saturation-factor, 1) * 68.5%), 52.7%)',
  GUILD_BOOSTING_PINK: 'hsl(302, calc(var(--saturation-factor, 1) * 100%), 72.5%)',
  GUILD_BOOSTING_PURPLE: 'hsl(269, calc(var(--saturation-factor, 1) * 83.8%), 71%)',
  GUILD_BOOSTING_PURPLE_FOR_GRADIENTS: 'hsl(269, calc(var(--saturation-factor, 1) * 91.8%), 61.8%)',
  HYPESQUAD_HOUSE_1: 'hsl(253, calc(var(--saturation-factor, 1) * 77%), 72.7%)',
  HYPESQUAD_HOUSE_2: 'hsl(9, calc(var(--saturation-factor, 1) * 86.5%), 68%)',
  HYPESQUAD_HOUSE_3: 'hsl(169, calc(var(--saturation-factor, 1) * 69.1%), 56.9%)',
  INPUT_PLACEHOLDER_TEXT_DARK: 'hsl(216, calc(var(--saturation-factor, 1) * 2.1%), 53.9%)',
  LOL: 'hsl(190, calc(var(--saturation-factor, 1) * 89.7%), 7.6%)',
  ORANGE_100: 'hsl(18, calc(var(--saturation-factor, 1) * 100%), 98%)',
  ORANGE_130: 'hsl(23, calc(var(--saturation-factor, 1) * 100%), 96.5%)',
  ORANGE_160: 'hsl(21, calc(var(--saturation-factor, 1) * 93.9%), 93.5%)',
  ORANGE_200: 'hsl(21, calc(var(--saturation-factor, 1) * 92.3%), 89.8%)',
  ORANGE_230: 'hsl(24, calc(var(--saturation-factor, 1) * 92.5%), 84.3%)',
  ORANGE_260: 'hsl(23, calc(var(--saturation-factor, 1) * 94.5%), 78.6%)',
  ORANGE_300: 'hsl(25, calc(var(--saturation-factor, 1) * 94.6%), 71.2%)',
  ORANGE_330: 'hsl(27, calc(var(--saturation-factor, 1) * 94.7%), 62.7%)',
  ORANGE_345: 'hsl(28, calc(var(--saturation-factor, 1) * 87.7%), 55.5%)',
  ORANGE_360: 'hsl(26, calc(var(--saturation-factor, 1) * 71.3%), 50.8%)',
  ORANGE_400: 'hsl(25, calc(var(--saturation-factor, 1) * 67.5%), 45.9%)',
  ORANGE_430: 'hsl(25, calc(var(--saturation-factor, 1) * 69.5%), 39.8%)',
  ORANGE_460: 'hsl(24, calc(var(--saturation-factor, 1) * 72.2%), 35.3%)',
  ORANGE_500: 'hsl(22, calc(var(--saturation-factor, 1) * 76.1%), 31.2%)',
  ORANGE: 'hsl(22, calc(var(--saturation-factor, 1) * 76.1%), 31.2%)',
  ORANGE_530: 'hsl(22, calc(var(--saturation-factor, 1) * 77%), 27.3%)',
  ORANGE_560: 'hsl(21, calc(var(--saturation-factor, 1) * 77.2%), 24.1%)',
  ORANGE_600: 'hsl(23, calc(var(--saturation-factor, 1) * 79.2%), 20.8%)',
  ORANGE_630: 'hsl(22, calc(var(--saturation-factor, 1) * 79.2%), 18.8%)',
  ORANGE_660: 'hsl(22, calc(var(--saturation-factor, 1) * 78.8%), 16.7%)',
  ORANGE_700: 'hsl(22, calc(var(--saturation-factor, 1) * 76.3%), 14.9%)',
  ORANGE_730: 'hsl(22, calc(var(--saturation-factor, 1) * 78.8%), 12.9%)',
  ORANGE_760: 'hsl(21, calc(var(--saturation-factor, 1) * 78.9%), 11.2%)',
  ORANGE_800: 'hsl(21, calc(var(--saturation-factor, 1) * 80%), 9.8%)',
  ORANGE_830: 'hsl(20, calc(var(--saturation-factor, 1) * 76.7%), 8.4%)',
  ORANGE_860: 'hsl(20, calc(var(--saturation-factor, 1) * 73%), 7.3%)',
  ORANGE_900: 'hsl(26, calc(var(--saturation-factor, 1) * 72.4%), 5.7%)',
  PARTNER: 'hsl(215, calc(var(--saturation-factor, 1) * 82.8%), 59%)',
  PAYPAL: 'hsl(230, calc(var(--saturation-factor, 1) * 69.8%), 24.7%)',
  PLAYSTATION: 'hsl(220, calc(var(--saturation-factor, 1) * 95.7%), 27.1%)',
  PLUM_23_ALPHA: 'hsl(230, calc(var(--saturation-factor, 1) * 60.7%), 78%)',
  PLUM_0: 'hsl(240, calc(var(--saturation-factor, 1) * 9.1%), 97.8%)',
  PLUM_1: 'hsl(240, calc(var(--saturation-factor, 1) * 4.3%), 95.5%)',
  PLUM_10: 'hsl(229, calc(var(--saturation-factor, 1) * 6.8%), 53.7%)',
  PLUM_11: 'hsl(229, calc(var(--saturation-factor, 1) * 6.9%), 45.5%)',
  PLUM_12: 'hsl(228, calc(var(--saturation-factor, 1) * 7.6%), 38.6%)',
  PLUM_13: 'hsl(227, calc(var(--saturation-factor, 1) * 8.3%), 32.9%)',
  PLUM_14: 'hsl(230, calc(var(--saturation-factor, 1) * 8.5%), 27.8%)',
  PLUM_15: 'hsl(229, calc(var(--saturation-factor, 1) * 8.9%), 24.1%)',
  PLUM_16: 'hsl(234, calc(var(--saturation-factor, 1) * 9.3%), 21.2%)',
  PLUM_17: 'hsl(233, calc(var(--saturation-factor, 1) * 9.3%), 19%)',
  PLUM_18: 'hsl(233, calc(var(--saturation-factor, 1) * 10.6%), 16.7%)',
  PLUM_19: 'hsl(233, calc(var(--saturation-factor, 1) * 10.8%), 14.5%)',
  PLUM_2: 'hsl(220, calc(var(--saturation-factor, 1) * 8.6%), 93.1%)',
  PLUM_20: 'hsl(231, calc(var(--saturation-factor, 1) * 11.1%), 12.4%)',
  PLUM_21: 'hsl(231, calc(var(--saturation-factor, 1) * 12.7%), 10.8%)',
  PLUM_22: 'hsl(240, calc(var(--saturation-factor, 1) * 12%), 9.8%)',
  PLUM_23: 'hsl(240, calc(var(--saturation-factor, 1) * 11.6%), 8.4%)',
  PLUM_24: 'hsl(228, calc(var(--saturation-factor, 1) * 14.3%), 6.9%)',
  PLUM_25: 'hsl(240, calc(var(--saturation-factor, 1) * 14.3%), 5.5%)',
  PLUM_26: 'hsl(240, calc(var(--saturation-factor, 1) * 15.8%), 3.7%)',
  PLUM_3: 'hsl(225, calc(var(--saturation-factor, 1) * 8%), 90.2%)',
  PLUM_4: 'hsl(225, calc(var(--saturation-factor, 1) * 6.2%), 87.5%)',
  PLUM_5: 'hsl(220, calc(var(--saturation-factor, 1) * 7.3%), 83.9%)',
  PLUM_6: 'hsl(231, calc(var(--saturation-factor, 1) * 6.7%), 79.4%)',
  PLUM_7: 'hsl(227, calc(var(--saturation-factor, 1) * 6.8%), 73.9%)',
  PLUM_8: 'hsl(230, calc(var(--saturation-factor, 1) * 7.4%), 68.2%)',
  PLUM_9: 'hsl(231, calc(var(--saturation-factor, 1) * 7.1%), 61.2%)',
  PREMIUM_PERK_BLUE: 'hsl(222, calc(var(--saturation-factor, 1) * 100%), 75.1%)',
  PREMIUM_PERK_BLUE_ALT: 'hsl(223, calc(var(--saturation-factor, 1) * 100%), 80.6%)',
  PREMIUM_PERK_DARK_BLUE: 'hsl(220, calc(var(--saturation-factor, 1) * 67.4%), 55.5%)',
  PREMIUM_PERK_GOLD: 'hsl(38, calc(var(--saturation-factor, 1) * 95.7%), 54.1%)',
  PREMIUM_PERK_GREEN: 'hsl(164, calc(var(--saturation-factor, 1) * 55.1%), 69.4%)',
  PREMIUM_PERK_LIGHT_BLUE: 'hsl(221, calc(var(--saturation-factor, 1) * 100%), 84.1%)',
  PREMIUM_PERK_ORANGE: 'hsl(25, calc(var(--saturation-factor, 1) * 96.7%), 64.1%)',
  PREMIUM_PERK_PINK: 'hsl(305, calc(var(--saturation-factor, 1) * 100%), 75.1%)',
  PREMIUM_PERK_PURPLE: 'hsl(272, calc(var(--saturation-factor, 1) * 100%), 80.2%)',
  PREMIUM_PERK_YELLOW: 'hsl(47, calc(var(--saturation-factor, 1) * 98.9%), 63.9%)',
  PREMIUM_TIER_0_BLUE: 'hsl(202, calc(var(--saturation-factor, 1) * 100%), 38%)',
  PREMIUM_TIER_0_BLUE_FOR_GRADIENTS: 'hsl(202, calc(var(--saturation-factor, 1) * 100%), 38%)',
  PREMIUM_TIER_0_BLUE_FOR_GRADIENTS_2: 'hsl(209, calc(var(--saturation-factor, 1) * 80%), 45.1%)',
  PREMIUM_TIER_0_HEADER_GRADIENT_1: 'hsl(240, calc(var(--saturation-factor, 1) * 55.2%), 47.3%)',
  PREMIUM_TIER_0_HEADER_GRADIENT_2: 'hsl(224, calc(var(--saturation-factor, 1) * 77.9%), 59.2%)',
  PREMIUM_TIER_0_HEADER_GRADIENT_3: 'hsl(246, calc(var(--saturation-factor, 1) * 74.4%), 69.4%)',
  PREMIUM_TIER_0_HEADER_GRADIENT_4: 'hsl(295, calc(var(--saturation-factor, 1) * 79.9%), 72.7%)',
  PREMIUM_TIER_0_HEADER_GRADIENT_5: 'hsl(336, calc(var(--saturation-factor, 1) * 55.2%), 72%)',
  PREMIUM_TIER_0_PURPLE: 'hsl(235, calc(var(--saturation-factor, 1) * 85.6%), 64.7%)',
  PREMIUM_TIER_0_PURPLE_FOR_GRADIENTS: 'hsl(235, calc(var(--saturation-factor, 1) * 85.6%), 64.7%)',
  PREMIUM_TIER_1_BLUE: 'hsl(228, calc(var(--saturation-factor, 1) * 86.7%), 70.6%)',
  PREMIUM_TIER_1_BLUE_FOR_GRADIENTS: 'hsl(235, calc(var(--saturation-factor, 1) * 85.6%), 64.7%)',
  PREMIUM_TIER_1_DARK_BLUE_FOR_GRADIENTS: 'hsl(235, calc(var(--saturation-factor, 1) * 68.5%), 52.7%)',
  PREMIUM_TIER_1_PURPLE: 'hsl(244, calc(var(--saturation-factor, 1) * 100%), 84.1%)',
  PREMIUM_TIER_2_PINK: 'hsl(342, calc(var(--saturation-factor, 1) * 58%), 72.9%)',
  PREMIUM_TIER_2_PINK_FOR_GRADIENTS: 'hsl(325, calc(var(--saturation-factor, 1) * 31.7%), 51.8%)',
  PREMIUM_TIER_2_PINK_FOR_GRADIENTS_2: 'hsl(295, calc(var(--saturation-factor, 1) * 51.4%), 50%)',
  PREMIUM_TIER_2_PURPLE: 'hsl(270, calc(var(--saturation-factor, 1) * 86.7%), 70.6%)',
  PREMIUM_TIER_2_PURPLE_FOR_GRADIENTS: 'hsl(269, calc(var(--saturation-factor, 1) * 52.7%), 52.7%)',
  PREMIUM_TIER_2_PURPLE_FOR_GRADIENTS_2: 'hsl(296, calc(var(--saturation-factor, 1) * 50%), 51.4%)',
  PRIMARY_100: 'hsl(0, calc(var(--saturation-factor, 1) * 0%), 97.6%)',
  PRIMARY_130: 'hsl(220, calc(var(--saturation-factor, 1) * 13%), 95.5%)',
  PRIMARY_160: 'hsl(210, calc(var(--saturation-factor, 1) * 11.1%), 92.9%)',
  PRIMARY_200: 'hsl(216, calc(var(--saturation-factor, 1) * 9.8%), 90%)',
  PRIMARY_230: 'hsl(210, calc(var(--saturation-factor, 1) * 9.1%), 87.1%)',
  PRIMARY_260: 'hsl(214, calc(var(--saturation-factor, 1) * 8.4%), 83.7%)',
  PRIMARY_300: 'hsl(210, calc(var(--saturation-factor, 1) * 9.3%), 78.8%)',
  PRIMARY_330: 'hsl(215, calc(var(--saturation-factor, 1) * 8.8%), 73.3%)',
  PRIMARY_345: 'hsl(214, calc(var(--saturation-factor, 1) * 8.4%), 67.5%)',
  PRIMARY_360: 'hsl(214, calc(var(--saturation-factor, 1) * 8.1%), 61.2%)',
  PRIMARY_400: 'hsl(223, calc(var(--saturation-factor, 1) * 5.8%), 52.9%)',
  PRIMARY_430: 'hsl(229, calc(var(--saturation-factor, 1) * 4.8%), 44.9%)',
  PRIMARY_460: 'hsl(228, calc(var(--saturation-factor, 1) * 5.2%), 38%)',
  PRIMARY_500: 'hsl(228, calc(var(--saturation-factor, 1) * 6%), 32.5%)',
  PRIMARY: 'hsl(228, calc(var(--saturation-factor, 1) * 6%), 32.5%)',
  PRIMARY_530: 'hsl(227, calc(var(--saturation-factor, 1) * 6.5%), 27.3%)',
  PRIMARY_560: 'hsl(225, calc(var(--saturation-factor, 1) * 6.7%), 23.5%)',
  PRIMARY_600: 'hsl(223, calc(var(--saturation-factor, 1) * 6.7%), 20.6%)',
  PRIMARY_630: 'hsl(220, calc(var(--saturation-factor, 1) * 6.5%), 18%)',
  PRIMARY_645: 'hsl(220, calc(var(--saturation-factor, 1) * 7%), 16.9%)',
  PRIMARY_660: 'hsl(228, calc(var(--saturation-factor, 1) * 6.7%), 14.7%)',
  PRIMARY_700: 'hsl(225, calc(var(--saturation-factor, 1) * 6.3%), 12.5%)',
  PRIMARY_730: 'hsl(225, calc(var(--saturation-factor, 1) * 7.1%), 11%)',
  PRIMARY_760: 'hsl(220, calc(var(--saturation-factor, 1) * 6.4%), 9.2%)',
  PRIMARY_800: 'hsl(220, calc(var(--saturation-factor, 1) * 8.1%), 7.3%)',
  PRIMARY_830: 'hsl(240, calc(var(--saturation-factor, 1) * 4%), 4.9%)',
  PRIMARY_860: 'hsl(240, calc(var(--saturation-factor, 1) * 7.7%), 2.5%)',
  PRIMARY_900: 'hsl(0, calc(var(--saturation-factor, 1) * 0%), 0.8%)',
  RED_100: 'hsl(350, calc(var(--saturation-factor, 1) * 75%), 98.4%)',
  RED_130: 'hsl(0, calc(var(--saturation-factor, 1) * 83.3%), 97.6%)',
  RED_160: 'hsl(0, calc(var(--saturation-factor, 1) * 90.9%), 95.7%)',
  RED_200: 'hsl(358, calc(var(--saturation-factor, 1) * 88.9%), 92.9%)',
  RED_230: 'hsl(359, calc(var(--saturation-factor, 1) * 85.2%), 89.4%)',
  RED_260: 'hsl(359, calc(var(--saturation-factor, 1) * 89%), 85.7%)',
  RED_300: 'hsl(359, calc(var(--saturation-factor, 1) * 91.4%), 81.8%)',
  RED_330: 'hsl(359, calc(var(--saturation-factor, 1) * 91.3%), 77.5%)',
  RED_345: 'hsl(358, calc(var(--saturation-factor, 1) * 92.9%), 72.4%)',
  RED_360: 'hsl(358, calc(var(--saturation-factor, 1) * 91.8%), 66.5%)',
  RED_400: 'hsl(359, calc(var(--saturation-factor, 1) * 87.3%), 59.8%)',
  RED_430: 'hsl(358, calc(var(--saturation-factor, 1) * 68.8%), 53.5%)',
  RED_460: 'hsl(359, calc(var(--saturation-factor, 1) * 59.1%), 46.1%)',
  RED_500: 'hsl(360, calc(var(--saturation-factor, 1) * 60.2%), 39.4%)',
  RED: 'hsl(360, calc(var(--saturation-factor, 1) * 60.2%), 39.4%)',
  RED_530: 'hsl(359, calc(var(--saturation-factor, 1) * 63.4%), 34.3%)',
  RED_560: 'hsl(359, calc(var(--saturation-factor, 1) * 63.9%), 30.4%)',
  RED_600: 'hsl(359, calc(var(--saturation-factor, 1) * 64.7%), 26.7%)',
  RED_630: 'hsl(359, calc(var(--saturation-factor, 1) * 65.6%), 23.9%)',
  RED_660: 'hsl(359, calc(var(--saturation-factor, 1) * 65.1%), 21.4%)',
  RED_700: 'hsl(358, calc(var(--saturation-factor, 1) * 66.7%), 18.8%)',
  RED_730: 'hsl(359, calc(var(--saturation-factor, 1) * 66.7%), 16.5%)',
  RED_760: 'hsl(359, calc(var(--saturation-factor, 1) * 70.3%), 14.5%)',
  RED_800: 'hsl(359, calc(var(--saturation-factor, 1) * 68.8%), 12.5%)',
  RED_830: 'hsl(358, calc(var(--saturation-factor, 1) * 67.3%), 10.8%)',
  RED_860: 'hsl(0, calc(var(--saturation-factor, 1) * 66.7%), 9.4%)',
  RED_900: 'hsl(0, calc(var(--saturation-factor, 1) * 70%), 7.8%)',
  REDDIT: 'hsl(16, calc(var(--saturation-factor, 1) * 100%), 50%)',
  RIOT_GAMES: 'hsl(349, calc(var(--saturation-factor, 1) * 100%), 45.9%)',
  ROLE_BLUE: 'hsl(204, calc(var(--saturation-factor, 1) * 64.4%), 35.3%)',
  ROLE_BROWN: 'hsl(24, calc(var(--saturation-factor, 1) * 100%), 32.9%)',
  ROLE_BURGUNDY: 'hsl(334, calc(var(--saturation-factor, 1) * 79.3%), 37.8%)',
  ROLE_DARK_BLUE: 'hsl(199, calc(var(--saturation-factor, 1) * 18.4%), 40.4%)',
  ROLE_DARK_GREY: 'hsl(200, calc(var(--saturation-factor, 1) * 18.3%), 46.1%)',
  ROLE_DARK_PURPLE: 'hsl(282, calc(var(--saturation-factor, 1) * 43.7%), 37.6%)',
  ROLE_DARK_TEAL: 'hsl(168, calc(var(--saturation-factor, 1) * 76.6%), 28.4%)',
  ROLE_DEFAULT: 'hsl(204, calc(var(--saturation-factor, 1) * 15.9%), 65.5%)',
  ROLE_GREEN: 'hsl(145, calc(var(--saturation-factor, 1) * 63.5%), 33.3%)',
  ROLE_GREY: 'hsl(202, calc(var(--saturation-factor, 1) * 4%), 60.8%)',
  ROLE_LIGHT_BLUE: 'hsl(204, calc(var(--saturation-factor, 1) * 15.9%), 65.5%)',
  ROLE_LIGHT_GREEN: 'hsl(145, calc(var(--saturation-factor, 1) * 63.2%), 49%)',
  ROLE_LIGHT_GREY: 'hsl(184, calc(var(--saturation-factor, 1) * 8.7%), 61.8%)',
  ROLE_MAGENTA: 'hsl(340, calc(var(--saturation-factor, 1) * 82.2%), 51.6%)',
  ROLE_ORANGE: 'hsl(28, calc(var(--saturation-factor, 1) * 79.7%), 51.8%)',
  ROLE_PURPLE: 'hsl(283, calc(var(--saturation-factor, 1) * 38.9%), 53.1%)',
  ROLE_SALMON: 'hsl(6, calc(var(--saturation-factor, 1) * 78.1%), 57.1%)',
  ROLE_SKY_BLUE: 'hsl(204, calc(var(--saturation-factor, 1) * 69.9%), 53.1%)',
  ROLE_TAN: 'hsl(37, calc(var(--saturation-factor, 1) * 86.5%), 40.8%)',
  ROLE_TEAL: 'hsl(168, calc(var(--saturation-factor, 1) * 75.7%), 42%)',
  ROLE_TERRACOTTA: 'hsl(6, calc(var(--saturation-factor, 1) * 63.6%), 36.7%)',
  ROLE_YELLOW: 'hsl(48, calc(var(--saturation-factor, 1) * 89%), 50.2%)',
  SAMSUNG: 'hsl(231, calc(var(--saturation-factor, 1) * 77.8%), 35.3%)',
  SKYPE: 'hsl(196, calc(var(--saturation-factor, 1) * 100%), 42.2%)',
  SPOTIFY: 'hsl(141, calc(var(--saturation-factor, 1) * 72.9%), 42%)',
  STEAM: 'hsl(215, calc(var(--saturation-factor, 1) * 35.1%), 14.5%)',
  TEAL_100: 'hsl(193, calc(var(--saturation-factor, 1) * 69.2%), 97.5%)',
  TEAL_130: 'hsl(192, calc(var(--saturation-factor, 1) * 83.3%), 95.3%)',
  TEAL_160: 'hsl(190, calc(var(--saturation-factor, 1) * 83.3%), 90.6%)',
  TEAL_200: 'hsl(188, calc(var(--saturation-factor, 1) * 85.7%), 83.5%)',
  TEAL_230: 'hsl(188, calc(var(--saturation-factor, 1) * 88.3%), 73.1%)',
  TEAL_260: 'hsl(188, calc(var(--saturation-factor, 1) * 81.9%), 65.3%)',
  TEAL_300: 'hsl(189, calc(var(--saturation-factor, 1) * 72.8%), 58.2%)',
  TEAL_330: 'hsl(189, calc(var(--saturation-factor, 1) * 65.6%), 52.2%)',
  TEAL_345: 'hsl(190, calc(var(--saturation-factor, 1) * 62.8%), 48.4%)',
  TEAL_360: 'hsl(190, calc(var(--saturation-factor, 1) * 64%), 43.5%)',
  TEAL_400: 'hsl(190, calc(var(--saturation-factor, 1) * 63.5%), 38.6%)',
  TEAL_430: 'hsl(190, calc(var(--saturation-factor, 1) * 63%), 33.9%)',
  TEAL_460: 'hsl(191, calc(var(--saturation-factor, 1) * 64.2%), 29.6%)',
  TEAL_500: 'hsl(190, calc(var(--saturation-factor, 1) * 65.2%), 25.9%)',
  TEAL: 'hsl(190, calc(var(--saturation-factor, 1) * 65.2%), 25.9%)',
  TEAL_530: 'hsl(190, calc(var(--saturation-factor, 1) * 66.7%), 22.4%)',
  TEAL_560: 'hsl(190, calc(var(--saturation-factor, 1) * 69.7%), 19.4%)',
  TEAL_600: 'hsl(189, calc(var(--saturation-factor, 1) * 71.8%), 16.7%)',
  TEAL_630: 'hsl(190, calc(var(--saturation-factor, 1) * 73.7%), 14.9%)',
  TEAL_660: 'hsl(188, calc(var(--saturation-factor, 1) * 75.8%), 12.9%)',
  TEAL_700: 'hsl(189, calc(var(--saturation-factor, 1) * 79.3%), 11.4%)',
  TEAL_730: 'hsl(189, calc(var(--saturation-factor, 1) * 80%), 9.8%)',
  TEAL_760: 'hsl(189, calc(var(--saturation-factor, 1) * 81.4%), 8.4%)',
  TEAL_800: 'hsl(188, calc(var(--saturation-factor, 1) * 83.3%), 7.1%)',
  TEAL_830: 'hsl(189, calc(var(--saturation-factor, 1) * 87.1%), 6.1%)',
  TEAL_860: 'hsl(188, calc(var(--saturation-factor, 1) * 85.2%), 5.3%)',
  TEAL_900: 'hsl(189, calc(var(--saturation-factor, 1) * 90.9%), 4.3%)',
  TRANSPARENT: 'transparent',
  TWITCH: 'hsl(262, calc(var(--saturation-factor, 1) * 46.8%), 39.8%)',
  TWITTER: 'hsl(203, calc(var(--saturation-factor, 1) * 89.1%), 53.1%)',
  WHITE_100: 'hsl(0, calc(var(--saturation-factor, 1) * 0%), 100%)',
  WHITE_130: 'hsl(0, calc(var(--saturation-factor, 1) * 0%), 100%)',
  WHITE_160: 'hsl(0, calc(var(--saturation-factor, 1) * 0%), 100%)',
  WHITE_200: 'hsl(0, calc(var(--saturation-factor, 1) * 0%), 100%)',
  WHITE_230: 'hsl(0, calc(var(--saturation-factor, 1) * 0%), 100%)',
  WHITE_260: 'hsl(0, calc(var(--saturation-factor, 1) * 0%), 100%)',
  WHITE_300: 'hsl(0, calc(var(--saturation-factor, 1) * 0%), 100%)',
  WHITE_330: 'hsl(0, calc(var(--saturation-factor, 1) * 0%), 100%)',
  WHITE_345: 'hsl(0, calc(var(--saturation-factor, 1) * 0%), 100%)',
  WHITE_360: 'hsl(0, calc(var(--saturation-factor, 1) * 0%), 100%)',
  WHITE_400: 'hsl(0, calc(var(--saturation-factor, 1) * 0%), 100%)',
  WHITE_430: 'hsl(0, calc(var(--saturation-factor, 1) * 0%), 100%)',
  WHITE_460: 'hsl(0, calc(var(--saturation-factor, 1) * 0%), 100%)',
  WHITE_500: 'hsl(0, calc(var(--saturation-factor, 1) * 0%), 100%)',
  WHITE: 'hsl(0, calc(var(--saturation-factor, 1) * 0%), 100%)',
  WHITE_530: 'hsl(0, calc(var(--saturation-factor, 1) * 0%), 91%)',
  WHITE_560: 'hsl(0, calc(var(--saturation-factor, 1) * 0%), 81.2%)',
  WHITE_600: 'hsl(0, calc(var(--saturation-factor, 1) * 0%), 67.8%)',
  WHITE_630: 'hsl(0, calc(var(--saturation-factor, 1) * 0%), 58.8%)',
  WHITE_660: 'hsl(0, calc(var(--saturation-factor, 1) * 0%), 51.4%)',
  WHITE_700: 'hsl(0, calc(var(--saturation-factor, 1) * 0%), 40%)',
  WHITE_730: 'hsl(0, calc(var(--saturation-factor, 1) * 0%), 37.3%)',
  WHITE_760: 'hsl(0, calc(var(--saturation-factor, 1) * 0%), 34.5%)',
  WHITE_800: 'hsl(0, calc(var(--saturation-factor, 1) * 0%), 30.2%)',
  WHITE_830: 'hsl(0, calc(var(--saturation-factor, 1) * 0%), 23.1%)',
  WHITE_860: 'hsl(0, calc(var(--saturation-factor, 1) * 0%), 14.9%)',
  WHITE_900: 'hsl(0, calc(var(--saturation-factor, 1) * 0%), 5.1%)',
  XBOX: 'hsl(120, calc(var(--saturation-factor, 1) * 77.1%), 27.5%)',
  YELLOW_100: 'hsl(34, calc(var(--saturation-factor, 1) * 100%), 96.9%)',
  YELLOW_130: 'hsl(31, calc(var(--saturation-factor, 1) * 100%), 95.5%)',
  YELLOW_160: 'hsl(33, calc(var(--saturation-factor, 1) * 100%), 91.4%)',
  YELLOW_200: 'hsl(35, calc(var(--saturation-factor, 1) * 97.3%), 85.5%)',
  YELLOW_230: 'hsl(37, calc(var(--saturation-factor, 1) * 96.6%), 77.1%)',
  YELLOW_260: 'hsl(41, calc(var(--saturation-factor, 1) * 96.8%), 62.9%)',
  YELLOW_300: 'hsl(40, calc(var(--saturation-factor, 1) * 86.4%), 56.9%)',
  YELLOW_330: 'hsl(40, calc(var(--saturation-factor, 1) * 75.3%), 52.4%)',
  YELLOW_345: 'hsl(40, calc(var(--saturation-factor, 1) * 71%), 48.6%)',
  YELLOW_360: 'hsl(39, calc(var(--saturation-factor, 1) * 74.4%), 42.9%)',
  YELLOW_400: 'hsl(38, calc(var(--saturation-factor, 1) * 78.6%), 38.4%)',
  YELLOW_430: 'hsl(37, calc(var(--saturation-factor, 1) * 84.4%), 32.7%)',
  YELLOW_460: 'hsl(36, calc(var(--saturation-factor, 1) * 87.8%), 28.8%)',
  YELLOW_500: 'hsl(36, calc(var(--saturation-factor, 1) * 93.8%), 25.1%)',
  YELLOW: 'hsl(36, calc(var(--saturation-factor, 1) * 93.8%), 25.1%)',
  YELLOW_530: 'hsl(35, calc(var(--saturation-factor, 1) * 92.9%), 22.2%)',
  YELLOW_560: 'hsl(34, calc(var(--saturation-factor, 1) * 94%), 19.6%)',
  YELLOW_600: 'hsl(35, calc(var(--saturation-factor, 1) * 93.1%), 17.1%)',
  YELLOW_630: 'hsl(35, calc(var(--saturation-factor, 1) * 92.4%), 15.5%)',
  YELLOW_660: 'hsl(35, calc(var(--saturation-factor, 1) * 91.4%), 13.7%)',
  YELLOW_700: 'hsl(34, calc(var(--saturation-factor, 1) * 90.3%), 12.2%)',
  YELLOW_730: 'hsl(33, calc(var(--saturation-factor, 1) * 92.7%), 10.8%)',
  YELLOW_760: 'hsl(33, calc(var(--saturation-factor, 1) * 91.7%), 9.4%)',
  YELLOW_800: 'hsl(32, calc(var(--saturation-factor, 1) * 90.2%), 8%)',
  YELLOW_830: 'hsl(32, calc(var(--saturation-factor, 1) * 88.9%), 7.1%)',
  YELLOW_860: 'hsl(32, calc(var(--saturation-factor, 1) * 86.7%), 5.9%)',
  YELLOW_900: 'hsl(36, calc(var(--saturation-factor, 1) * 83.3%), 4.7%)',
  YOUTUBE: 'hsl(0, calc(var(--saturation-factor, 1) * 72.8%), 46.1%)',
});

export default Colors as Record<keyof typeof Colors, string>;
