import * as React from 'react';
import classNames from 'classnames';

import Button from '@developers/uikit/Button';
import Clickable from '@developers/uikit/Clickable';

import {Messages} from '@developers/i18n';
import styles from './SidebarCta.module.css';

interface SidebarCtaActionsProps {
  children: React.ReactNode;
}

export const SidebarCtaActions = (props: SidebarCtaActionsProps) => {
  return <div className={styles.actions}>{props.children}</div>;
};

export enum SidebarCtaActionTypes {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  TERTIARY = 'TERTIARY',
}

type SidebarCtaActionProps = {
  children: React.ReactNode;
  className?: string | null;
  type?: SidebarCtaActionTypes;
} & Omit<React.ComponentProps<typeof Button>, 'height' | 'look'>;

export const SidebarCtaAction = (props: SidebarCtaActionProps) => {
  const {children, className, type = SidebarCtaActionTypes.SECONDARY, ...buttonProps} = props;
  const isPrimary = type === SidebarCtaActionTypes.PRIMARY;
  const isSecondary = type === SidebarCtaActionTypes.SECONDARY;
  const isTertiary = type === SidebarCtaActionTypes.TERTIARY;

  return (
    <Button
      className={classNames(className, styles.action, {
        [styles.actionPrimary]: isPrimary,
        [styles.actionSecondary]: isSecondary,
        [styles.actionTertiary]: isTertiary,
      })}
      color={isPrimary ? Button.Color.WHITE : Button.Color.TRANSPARENT}
      height={Button.Height.SHORT}
      {...buttonProps}>
      {children}
    </Button>
  );
};

interface SidebarCtaContentProps {
  children: React.ReactNode;
}

export const SidebarCtaContent = (props: SidebarCtaContentProps) => {
  return <div className={styles.content}>{props.children}</div>;
};

interface SidebarCtaHeadingProps {
  children: React.ReactNode;
}

export const SidebarCtaHeading = (props: SidebarCtaHeadingProps) => {
  return <header className={styles.heading}>{props.children}</header>;
};

interface SidebarCtaProps {
  children: React.ReactNode;
}

const SidebarCta = (props: SidebarCtaProps) => {
  return <div className={styles.wrapper}>{props.children}</div>;
};

interface SidebarCtaCloseButtonProps {
  onClick: () => void;
}

export function SidebarCtaCloseButton({onClick}: SidebarCtaCloseButtonProps) {
  return <Clickable className={styles.closeButton} onClick={onClick} aria-label={Messages.DISMISS} />;
}

export default SidebarCta;
