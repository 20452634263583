import * as React from 'react';
import {useNavigate} from 'react-router-dom';

import Modal, {ModalHeader, ModalContent, ModalFooter, ModalCancel} from '@developers/components/common/Modal';
import Button from '@developers/uikit/Button';

import {Routes} from '@developers/Constants';
import {Messages} from '@developers/i18n';
import styles from './StoreSignupModal.module.css';

interface StoreSignupModalProps {
  onClose: () => void;
}

const ConfirmBody = () => (
  <div className={styles.confirmBody}>
    <ModalHeader className={styles.header}>{Messages.StoreSignupModal.SIGNUP_HEADER}</ModalHeader>
    <div className={styles.confirmArt} />
    <p>{Messages.StoreSignupModal.SIGNUP_BODY_1}</p>
    <p>{Messages.StoreSignupModal.SIGNUP_BODY_2}</p>
  </div>
);

export default function StoreSignupModal(props: StoreSignupModalProps) {
  const navigate = useNavigate();
  const handleCloseClick = (): void => {
    props.onClose();
  };

  const handleConfirmClick = (): void => {
    navigate(Routes.TEAMS);
  };

  return (
    <Modal onRequestClose={handleCloseClick}>
      <ModalContent>
        <ConfirmBody />
      </ModalContent>
      <ModalFooter>
        <ModalCancel onClick={handleCloseClick}>{Messages.Actions.CANCEL}</ModalCancel>
        <Button onClick={handleConfirmClick} height={Button.Height.SHORT}>
          {Messages.StoreSignupModal.BUTTON_CONFIRM}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
