import Flux from '@discordapp/flux';

import Dispatcher from '@developers/Dispatcher';
import ActionTypes from '@developers/actions/ActionTypes';

import type {Action} from '@developers/flow/Action';

let isMenuOpen: boolean = false;

function handleMenuClose(): void {
  isMenuOpen = false;
}

function handleMenuOpen(): void {
  isMenuOpen = true;
}

class UserStore extends Flux.Store<Action> {
  static displayName = 'UserStore';
  get isMenuOpen(): boolean {
    return isMenuOpen;
  }
}

export default new UserStore(Dispatcher, {
  [ActionTypes.MOBILE_MENU_CLOSE]: handleMenuClose,
  [ActionTypes.MOBILE_MENU_OPEN]: handleMenuOpen,
});
