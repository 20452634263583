const MAX_ACTIONS = 10;

let lastFewActions: string[] = [];
export function add(action: string) {
  lastFewActions.push(action);
  while (lastFewActions.length > MAX_ACTIONS) {
    lastFewActions.shift();
  }
}

export function clear() {
  const actions = lastFewActions;
  lastFewActions = [];
  return actions;
}

export function serialize() {
  return lastFewActions.join(' -> ');
}
