interface StorageType {
  get<T>(key: string, defaultValue?: T): T | undefined | null;
  set(key: string, value: any): void;
  remove(key: string): void;
  clear(): void;
  stringify(): string;

  // mobile only
  refresh(exclude?: string[], secureKeys?: Set<string>): Promise<[Record<string, string>, Record<string, string>]>;
  parse(data: [Record<string, string>, Record<string, string>]): Promise<void>;
  getAfterRefresh<T>(key: string): Promise<T | null | undefined>;
  asyncGet(key: string, callback: (value?: any) => void, traceName?: string): void;
  setRaw(key: string, value: string): void;
  asyncGetRaw(key: string, traceName?: string): Promise<string | null>;
}

let Storage: StorageType;
if (__MOBILE__) {
  Storage = require('./native/Storage').impl;
} else {
  Storage = require('./web/Storage').impl;
}

export default Storage;
