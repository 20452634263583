import * as React from 'react';
import classNames from 'classnames';
import * as ReactDOM from 'react-dom';
import {Text} from '@discordapp/design/components/Text/Text.web';

import Alert, {AlertTypes} from '@developers/uikit/Alert';
import Button from '@developers/uikit/Button';
import Flex from '@developers/uikit/Flex';

import {KeyboardKeys} from '@developers/Constants';
import styles from './Modal.module.css';

interface Props {
  onRequestClose: () => void;
  children: React.ReactNode;
  className?: string;
}

export const ModalAlertTypes = AlertTypes;

export const ModalHeader = ({children, className}: {children: React.ReactNode; className?: string}) => (
  <Text variant="text-md/semibold" color="always-white" className={classNames(styles.modalHeader, className)}>
    {children}
  </Text>
);

export const ModalCancel = ({
  children,
  onClick,
  disabled = false,
}: {
  children: React.ReactNode;
  onClick: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
}) => (
  <Button disabled={disabled} color={Button.Color.TRANSPARENT} onClick={onClick}>
    {children}
  </Button>
);

export const ModalConfirm = ({
  children,
  onClick,
  submitting,
  disabled,
  type,
  color = Button.Color.BRAND,
}: {
  children: React.ReactNode;
  onClick?: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
  submitting?: boolean;
  disabled?: boolean;
  type?: string;
  color?: (typeof Button.Color)[keyof typeof Button.Color];
}) => (
  <Button
    color={color}
    disabled={disabled}
    type={type}
    submitting={submitting}
    className={styles.confirm}
    onClick={onClick}>
    {children}
  </Button>
);

export const ModalContent = ({children, className}: {children: React.ReactNode; className?: string}) => (
  <div className={classNames(styles.modalInner, className)}>{children}</div>
);

export const ModalFooter = ({children}: {children: React.ReactNode}) => (
  <footer className={styles.modalFooter}>{children}</footer>
);

export const ModalAlert = ({
  children,
  type = AlertTypes.ERROR,
  className,
  innerClassName,
}: {
  children: React.ReactNode;
  type?: (typeof AlertTypes)[keyof typeof AlertTypes];
  className?: string;
  innerClassName?: string;
}) => (
  <Alert type={type} className={classNames(styles.modalWarningPanel, className)} textClassName={innerClassName}>
    {children}
  </Alert>
);

export const ModalDivider = ({className}: {className?: string}) => (
  <div className={classNames(styles.divider, className)} />
);

export default function Modal({children, className, onRequestClose}: Props) {
  const [el, setEl] = React.useState<HTMLDivElement | null>(null);
  React.useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent): void => {
      if (event.which === KeyboardKeys.ESCAPE) {
        onRequestClose();
      }
    };
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [onRequestClose]);

  const handleRequestClose = (event: React.MouseEvent<HTMLDivElement>): void => {
    if (event.target === event.currentTarget) {
      onRequestClose();
    }
  };

  /**
   * Big Hack Alert: This is all to show a CSS dom removal animation
   *
   * Since React controls modifying DOM elements we don't have anyway way
   * to perform DOM-based clean up such as animations BEFORE the DOM node is deleted.
   *
   * View Transition API almost works here but it's not possible to wrap all React
   * updates in a `document.startViewTransition()`. This also means we need to force React
   * to flush on our time with `ReactDOM.flushSync()` since `startViewTransition` is a "stack marker" function —
   * it takes a screenshot and animates before the callback then onces after the sync callback.
   * https://developer.mozilla.org/en-US/docs/Web/API/View_Transitions_API
   *
   * CSS @exit-style is actually what we want but it's still a proposal:
   * https://github.com/w3c/csswg-drafts/issues/9500
   *
   * So instead, we let React remove the DOM node then clone it and reinsert in the same place —
   * transferring node ownership from React to this function.
   * The node then has the exit animation class applied, and is removed once animation is done.
   */
  const hasSetObserver = React.useRef(false);
  if (hasSetObserver.current === false && el != null && 'MutationObserver' in window) {
    const observer = new MutationObserver((mutations) => {
      if (!document.body.contains(el)) {
        observer.disconnect();
        const [mutation] = mutations;
        const node = mutation.removedNodes[0] as HTMLElement;
        // Cleanup
        node.onanimationend = () => {
          node.remove();
        };

        // Insert
        mutation.target.appendChild(node);
        node.classList.add(styles.modalClosed);
      }
    });
    observer.observe(document.body, {childList: true});
    hasSetObserver.current = true;
  }

  /* The click handler is acceptable; they do not need to be accessible. */
  /* eslint-disable @discordapp/discord/use-a11y-component */
  return ReactDOM.createPortal(
    <Flex
      justify={Flex.Justify.CENTER}
      align={Flex.Align.CENTER}
      className={styles.modal}
      onClick={handleRequestClose}
      innerRef={(ref) => setEl(ref)}>
      <div className={classNames(styles.modalContent, className)}>{children}</div>
    </Flex>,
    window.document.body
  );
  /* eslint-enable @discordapp/discord/use-a11y-component */
}
