import * as React from 'react';
import classNames from 'classnames';

import styles from './PageSection.module.css';

enum BottomMarginSizes {
  NONE = 'NONE',
  MEDIUM = 'MEDIUM',
  SMALL = 'SMALL',
}

enum BottomPaddingSizes {
  NONE = 'NONE',
  MEDIUM = 'MEDIUM',
  SMALL = 'SMALL',
}

const BottomMarginStyles = Object.freeze({
  [BottomMarginSizes.NONE]: null,
  [BottomMarginSizes.MEDIUM]: styles.marginBottomMedium,
  [BottomMarginSizes.SMALL]: styles.marginBottomSmall,
} as const);

const BottomPaddingStyles = Object.freeze({
  [BottomPaddingSizes.NONE]: null,
  [BottomPaddingSizes.MEDIUM]: styles.paddingBottomMedium,
  [BottomPaddingSizes.SMALL]: styles.paddingBottomSmall,
} as const);

const PageSection = ({
  bottomMargin = BottomMarginSizes.MEDIUM,
  bottomPadding = BottomPaddingSizes.NONE,
  children,
  hasBorder = false,
  isFlush = false,
  className,
}: {
  bottomMargin?: BottomMarginSizes;
  bottomPadding?: BottomPaddingSizes;
  children: React.ReactNode;
  hasBorder?: boolean;
  isFlush?: boolean;
  className?: string;
}) => (
  <div
    className={classNames(
      styles.section,
      className,
      BottomMarginStyles[bottomMargin],
      BottomPaddingStyles[bottomPadding],
      {
        [styles.hasBorder]: hasBorder,
        [styles.flush]: isFlush,
      }
    )}>
    {children}
  </div>
);

PageSection.BottomMarginSizes = BottomMarginSizes;
PageSection.BottomPaddingSizes = BottomPaddingSizes;

export default PageSection;
