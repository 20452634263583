import * as React from 'react';
import {Confetti, ConfettiCanvas, SpriteCanvas, SpriteCanvasHandle, ConfettiCanvasHandle} from 'confetti-cannon';

import {CanvasClickListener, ConfettiCannonContextProvider} from './CommonConfettiContext';

import {
  COMMON_CONFETTI_ENVIRONMENT,
  COMMON_CONFETTI_COLORS,
  COMMON_CONFETTI_SPRITES,
  COMMON_CONFETTI_MAX_SPRITE_SIZE,
  COMMON_CONFETTI_BASE_CONFIG,
} from './ConfettiConstants';
import styles from './CommonConfettiCannonProvider.module.css';

interface CommonConfettiCannonContextProviderProps {
  children: React.ReactNode;
}

export function CommonConfettiCannonProvider({children}: CommonConfettiCannonContextProviderProps) {
  const [spriteCanvas, setSpriteCanvas] = React.useState<SpriteCanvasHandle | null>(null);
  const [confettiCanvas, setConfettiCanvas] = React.useState<ConfettiCanvasHandle | null>(null);
  const clickListeners = React.useRef<Set<CanvasClickListener>>(new Set());
  const [hasClickListeners, setHasClickListeners] = React.useState(false);

  const removeCanvasClickListener = React.useCallback((listener: CanvasClickListener) => {
    clickListeners.current.delete(listener);
    setHasClickListeners(clickListeners.current.size > 0);
  }, []);

  const addCanvasClickListener = React.useCallback(
    (listener: CanvasClickListener) => {
      clickListeners.current.add(listener);
      setHasClickListeners(true);
      return () => removeCanvasClickListener(listener);
    },
    [removeCanvasClickListener]
  );

  const handleClick = React.useCallback((e: MouseEvent, confetti: Confetti | null) => {
    for (const listener of clickListeners.current) {
      listener(e, confetti);
    }
  }, []);

  return (
    <>
      <ConfettiCannonContextProvider
        confettiCanvas={confettiCanvas}
        spriteCanvas={spriteCanvas}
        baseConfig={COMMON_CONFETTI_BASE_CONFIG}
        addClickListener={addCanvasClickListener}
        removeClickListener={removeCanvasClickListener}>
        {children}
      </ConfettiCannonContextProvider>
      <ConfettiCanvas
        ref={setConfettiCanvas}
        className={styles.canvas}
        environment={COMMON_CONFETTI_ENVIRONMENT}
        onClick={hasClickListeners ? handleClick : undefined}
      />
      <SpriteCanvas
        ref={setSpriteCanvas}
        colors={COMMON_CONFETTI_COLORS}
        sprites={COMMON_CONFETTI_SPRITES}
        spriteWidth={COMMON_CONFETTI_MAX_SPRITE_SIZE}
        spriteHeight={COMMON_CONFETTI_MAX_SPRITE_SIZE}
      />
    </>
  );
}
