// global.performance doesn't actually exist, webpack just creates
// it: https://webpack.js.org/api/module-variables/#global-nodejs.
// The problem is that this implies the type of `NodeJS.Global`, rather than
// `window.performance`, which is what it actually is in the browser.
const performance = (global as any).performance ?? {};

export function mark(label: string) {
  if (process.env.NODE_ENV === 'production' || performance.mark == null) {
    return;
  }
  performance.mark(label);
}

export function measure(label: string, mark: string) {
  if (process.env.NODE_ENV === 'production' || performance.measure == null) {
    return;
  }
  performance.measure(label, mark);
  clearMarks(mark);
  clearMeasures(label);
}

export function clearMarks(label?: string) {
  if (process.env.NODE_ENV === 'production' || performance.clearMarks == null) {
    return;
  }
  performance.clearMarks(label);
}

export function clearMeasures(label?: string) {
  if (process.env.NODE_ENV === 'production' || performance.clearMeasures == null) {
    return;
  }
  performance.clearMeasures(label);
}
