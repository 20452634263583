/**
 * THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
 *
 * Constants in this file are generated from the Python discord_common.shared_constants module.
 * Run `clyde gen ts-constants` to regenerate.
 */
export enum ApplicationSubscriptionPriceTiers {
  MIN = 299,
  MAX = 19999,
}

