import * as React from 'react';
import classNames from 'classnames';
import map from 'lodash/map';
import {findDOMNode} from 'react-dom';
import {useStateFromStores} from '@discordapp/flux';

import TooltipActionCreators from '@developers/actions/TooltipActionCreators';
import TooltipStore from '@developers/stores/TooltipStore';
import {Colors} from './Tooltip';

import type {TooltipProps} from '@developers/flow/Client';
import styles from './Tooltips.module.css';

interface State {
  offsetX: number | null | undefined;
  offsetY: number | null | undefined;
}

class Tooltip extends React.PureComponent<TooltipProps, State> {
  state: State = {
    offsetX: null,
    offsetY: null,
  };

  componentDidMount() {
    this.updateOffsets();
  }

  handleMouseEnter = () => {
    if (this.props.isInteractive) {
      TooltipActionCreators.show(this.props.id, this.props);
    }
  };

  handleMouseLeave = () => {
    if (this.props.isInteractive) {
      TooltipActionCreators.hide(this.props.id);
    }
  };

  componentDidUpdate({text, position, x, y, targetWidth, targetHeight}: TooltipProps) {
    if (
      this.props.text !== text ||
      this.props.position !== position ||
      this.props.x !== x ||
      this.props.y !== y ||
      this.props.targetWidth !== targetWidth ||
      this.props.targetHeight !== targetHeight
    ) {
      this.updateOffsets();
    }
  }

  updateOffsets = () => {
    const {targetHeight, targetWidth, position} = this.props;
    const domNode = findDOMNode(this);
    if (domNode == null || !(domNode instanceof HTMLElement)) {
      return;
    }
    const newState: Partial<State> = {
      offsetY: -(domNode.offsetHeight / 2),
    };
    switch (position) {
      case 'left':
        newState.offsetX = -domNode.offsetWidth;
        newState.offsetY = (newState.offsetY ?? 0) + targetHeight / 2;
        break;
      case 'right':
        newState.offsetX = targetWidth;
        newState.offsetY = (newState.offsetY ?? 0) + targetHeight / 2;
        break;
      case 'bottom':
        newState.offsetX = (targetWidth - domNode.offsetWidth) / 2;
        newState.offsetY = targetHeight;
        break;
      case 'top':
      default:
        newState.offsetX = (targetWidth - domNode.offsetWidth) / 2;
        newState.offsetY = -domNode.offsetHeight;
    }
    this.setState(newState as State);
  };

  render() {
    const {offsetX, offsetY} = this.state;
    const {x, y, position, text, isInteractive, color} = this.props;

    // @ts-expect-error react-18-upgrade
    const tooltipText = typeof text === 'function' ? text() : text;

    if (!tooltipText) {
      return null;
    }

    const style: React.CSSProperties = {
      left: offsetX == null ? undefined : x + offsetX,
      top: offsetY == null ? undefined : y + offsetY,
    };

    return (
      <div
        onMouseLeave={this.handleMouseLeave}
        onMouseEnter={this.handleMouseEnter}
        className={classNames(
          styles.tooltip,
          styles[position],
          {[styles.tooltipInteractive]: isInteractive},
          {[styles.grey]: color === Colors.GREY}
        )}
        style={style}>
        {tooltipText}
      </div>
    );
  }
}

export default function Tooltips() {
  const tooltips = useStateFromStores([TooltipStore], () => TooltipStore.getTooltips());
  return (
    <div className={styles.tooltips}>
      {map(tooltips, (props, i) => (
        <Tooltip key={i} {...props} />
      ))}
    </div>
  );
}
