import Flux from '@discordapp/flux';

import Dispatcher from '@developers/Dispatcher';
import ActionTypes from '@developers/actions/ActionTypes';

import type {Action, ActionFor} from '@developers/flow/Action';
import type {Channel} from '@developers/flow/Server';

const DEFAULT_CHANNELS_BY_CHANNEL_ID = Object.freeze({});

let channelsByGuild: Map<string, Channel[]> = new Map();
let channelsByChannelIdByGuildId: Map<string, Record<Channel['id'], Channel>> = new Map();
let isFetchingGuildChannels: boolean = false;

const handleGuildChannelsFetchStart = () => {
  isFetchingGuildChannels = true;
};

const handleGuildChannelsFetch = (payload: ActionFor<'GUILD_CHANNELS_FETCH_SUCCESS'>) => {
  channelsByGuild.set(payload.guildId, payload.channels);
  const channelsByChannelId = payload.channels.reduce((acc: Record<Channel['id'], Channel>, channel) => {
    acc[channel.id] = channel;
    return acc;
  }, {});
  channelsByChannelIdByGuildId.set(payload.guildId, channelsByChannelId);
  isFetchingGuildChannels = false;
};

function handleUserLogout() {
  channelsByGuild = new Map();
  channelsByChannelIdByGuildId = new Map();
  isFetchingGuildChannels = false;
}

class GuildStore extends Flux.Store<Action> {
  static displayName = 'GuildStore';
  getChannelsForGuild(guildId: string): Channel[] | void {
    return channelsByGuild.get(guildId);
  }

  getChannelsMapForGuild(guildId: string): Record<Channel['id'], Channel> {
    return channelsByChannelIdByGuildId.get(guildId) ?? DEFAULT_CHANNELS_BY_CHANNEL_ID;
  }

  getIsFetchingGuildChannels(): boolean {
    return isFetchingGuildChannels;
  }
}

export default new GuildStore(Dispatcher, {
  [ActionTypes.GUILD_CHANNELS_FETCH_START]: handleGuildChannelsFetchStart,
  [ActionTypes.GUILD_CHANNELS_FETCH_SUCCESS]: handleGuildChannelsFetch,
  [ActionTypes.USER_LOGOUT]: handleUserLogout,
});
