import {ObjectStorage} from './Storage';

let sessionStorage;
/**
 * Depending on security settings in the browser, this can throw an error
 * https://sentry.io/discord/discord-web/issues/670861961/
 */
try {
  sessionStorage = window.sessionStorage;
} catch (e) {}

/**
 * Some simple XSS security. Won't prevent access to tokens entirely,
 * but at least it makes it a lot harder.
 */
// old Safari versions don't like this
try {
  delete window.sessionStorage;
} catch (e) {}

/**
 * Determine if sessionStorage can be used.
 */
export function sessionStorageTest(): boolean {
  const test = 'test';
  try {
    sessionStorage.setItem(test, test);
    sessionStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
}

/**
 * Store everything in session storage.
 */
class SessionStorage {
  /**
   * Get an item from the store.
   */
  get<T>(key: string, defaultValue?: T): T | undefined {
    let value = sessionStorage.getItem(key);
    if (value != null) {
      try {
        value = JSON.parse(value);
      } catch (e) {
        value = defaultValue;
      }
    } else {
      value = defaultValue;
    }
    return value;
  }

  /**
   * Set an item in the store.
   */
  set(key: string, value: any) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  /**
   * Remove an item from the store.
   */
  remove(key: string): void {
    sessionStorage.removeItem(key);
  }

  /**
   * Clear all items at the store.
   */
  clear(): void {
    sessionStorage.clear();
  }
}

export const impl = sessionStorageTest() ? new SessionStorage() : new ObjectStorage();
