import TokenManager from '@discordapp/common/lib/TokenManager';
import Flux from '@discordapp/flux';
import Storage from '@discordapp/storage';

import Dispatcher from '@developers/Dispatcher';
import ActionTypes from '@developers/actions/ActionTypes';

import type {ActionFor, Action} from '@developers/flow/Action';

let fingerprint: string | null | undefined = Storage.get('fingerprint');

function handleFingerprintSet({fingerprint: _fingerprint}: ActionFor<'FINGERPRINT_SET'>) {
  if (_fingerprint != null) {
    fingerprint = _fingerprint;
    Storage.set('fingerprint', fingerprint);
  }
}

function handleToken(action: ActionFor<'UPDATE_TOKEN'>) {
  TokenManager.setToken(action.token);
}

function handleUserLogout() {
  fingerprint = null;
}

class APIStore extends Flux.Store<Action> {
  static displayName = 'APIStore';
  get fingerprint(): string | null | undefined {
    return fingerprint;
  }

  get token(): string | null | undefined {
    return TokenManager.getToken();
  }
}

export default new APIStore(Dispatcher, {
  [ActionTypes.FINGERPRINT_SET]: handleFingerprintSet,
  [ActionTypes.UPDATE_TOKEN]: handleToken,
  [ActionTypes.USER_LOGOUT]: handleUserLogout,
});
