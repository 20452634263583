/**
 * THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
 * @generated
 *
 * Run `./dev-cli api-docs` to regenerate.
 */

export default [
  {
    key: 'CHANGE_LOG',
    name: 'Change Log',
    file: 'Change_Log',
    filename: 'Change_Log.md',
    category: null,
    categoryName: '',
    subPath: 'change-log',
  },
  {
    key: 'INTRO',
    name: 'Intro',
    file: 'Intro',
    filename: 'Intro.mdx',
    category: null,
    categoryName: '',
    subPath: 'intro',
  },
  {
    key: 'REFERENCE',
    name: 'Reference',
    file: 'Reference',
    filename: 'Reference.md',
    category: null,
    categoryName: '',
    subPath: 'reference',
  },
  {
    key: 'BEST_PRACTICES_CRAFTING_YOUR_APP_DIRECTORY_PRODUCT_PAGE',
    name: 'Crafting your App Directory Product Page',
    file: 'Crafting_your_App_Directory_Product_Page',
    filename: 'Crafting_your_App_Directory_Product_Page.md',
    category: 'best_practices',
    categoryName: 'best practices',
    subPath: 'best-practices/crafting-your-app-directory-product-page',
  },
  {
    key: 'DA_GETTING_STARTED',
    name: 'Getting Started',
    file: 'Getting_Started',
    filename: 'Getting_Started.mdx',
    category: 'da',
    categoryName: 'da',
    subPath: 'da/getting-started',
  },
  {
    key: 'DE_GETTING_STARTED',
    name: 'Getting Started',
    file: 'Getting_Started',
    filename: 'Getting_Started.mdx',
    category: 'de',
    categoryName: 'de',
    subPath: 'de/getting-started',
  },
  {
    key: 'FR_GETTING_STARTED',
    name: 'Getting Started',
    file: 'Getting_Started',
    filename: 'Getting_Started.mdx',
    category: 'fr',
    categoryName: 'fr',
    subPath: 'fr/getting-started',
  },
  {
    key: 'GAME_SDK_ACHIEVEMENTS',
    name: 'Achievements',
    file: 'Achievements',
    filename: 'Achievements.md',
    category: 'game_sdk',
    categoryName: 'game sdk',
    subPath: 'game-sdk/achievements',
  },
  {
    key: 'GAME_SDK_ACTIVITIES',
    name: 'Activities',
    file: 'Activities',
    filename: 'Activities.md',
    category: 'game_sdk',
    categoryName: 'game sdk',
    subPath: 'game-sdk/activities',
  },
  {
    key: 'GAME_SDK_APPLICATIONS',
    name: 'Applications',
    file: 'Applications',
    filename: 'Applications.md',
    category: 'game_sdk',
    categoryName: 'game sdk',
    subPath: 'game-sdk/applications',
  },
  {
    key: 'GAME_SDK_DISCORD',
    name: 'Discord',
    file: 'Discord',
    filename: 'Discord.md',
    category: 'game_sdk',
    categoryName: 'game sdk',
    subPath: 'game-sdk/discord',
  },
  {
    key: 'GAME_SDK_DISCORD_VOICE',
    name: 'Discord Voice',
    file: 'Discord_Voice',
    filename: 'Discord_Voice.md',
    category: 'game_sdk',
    categoryName: 'game sdk',
    subPath: 'game-sdk/discord-voice',
  },
  {
    key: 'GAME_SDK_IMAGES',
    name: 'Images',
    file: 'Images',
    filename: 'Images.md',
    category: 'game_sdk',
    categoryName: 'game sdk',
    subPath: 'game-sdk/images',
  },
  {
    key: 'GAME_SDK_LOBBIES',
    name: 'Lobbies',
    file: 'Lobbies',
    filename: 'Lobbies.md',
    category: 'game_sdk',
    categoryName: 'game sdk',
    subPath: 'game-sdk/lobbies',
  },
  {
    key: 'GAME_SDK_NETWORKING',
    name: 'Networking',
    file: 'Networking',
    filename: 'Networking.md',
    category: 'game_sdk',
    categoryName: 'game sdk',
    subPath: 'game-sdk/networking',
  },
  {
    key: 'GAME_SDK_OVERLAY',
    name: 'Overlay',
    file: 'Overlay',
    filename: 'Overlay.md',
    category: 'game_sdk',
    categoryName: 'game sdk',
    subPath: 'game-sdk/overlay',
  },
  {
    key: 'GAME_SDK_RELATIONSHIPS',
    name: 'Relationships',
    file: 'Relationships',
    filename: 'Relationships.md',
    category: 'game_sdk',
    categoryName: 'game sdk',
    subPath: 'game-sdk/relationships',
  },
  {
    key: 'GAME_SDK_SDK_STARTER_GUIDE',
    name: 'SDK Starter Guide',
    file: 'SDK_Starter_Guide',
    filename: 'SDK_Starter_Guide.md',
    category: 'game_sdk',
    categoryName: 'game sdk',
    subPath: 'game-sdk/sdk-starter-guide',
  },
  {
    key: 'GAME_SDK_STORAGE',
    name: 'Storage',
    file: 'Storage',
    filename: 'Storage.md',
    category: 'game_sdk',
    categoryName: 'game sdk',
    subPath: 'game-sdk/storage',
  },
  {
    key: 'GAME_SDK_STORE',
    name: 'Store',
    file: 'Store',
    filename: 'Store.md',
    category: 'game_sdk',
    categoryName: 'game sdk',
    subPath: 'game-sdk/store',
  },
  {
    key: 'GAME_SDK_USERS',
    name: 'Users',
    file: 'Users',
    filename: 'Users.md',
    category: 'game_sdk',
    categoryName: 'game sdk',
    subPath: 'game-sdk/users',
  },
  {
    key: 'INTERACTIONS_APPLICATION_COMMANDS',
    name: 'Application Commands',
    file: 'Application_Commands',
    filename: 'Application_Commands.md',
    category: 'interactions',
    categoryName: 'interactions',
    subPath: 'interactions/application-commands',
  },
  {
    key: 'INTERACTIONS_MESSAGE_COMPONENTS',
    name: 'Message Components',
    file: 'Message_Components',
    filename: 'Message_Components.md',
    category: 'interactions',
    categoryName: 'interactions',
    subPath: 'interactions/message-components',
  },
  {
    key: 'INTERACTIONS_OVERVIEW',
    name: 'Overview',
    file: 'Overview',
    filename: 'Overview.mdx',
    category: 'interactions',
    categoryName: 'interactions',
    subPath: 'interactions/overview',
  },
  {
    key: 'INTERACTIONS_RECEIVING_AND_RESPONDING',
    name: 'Receiving and Responding',
    file: 'Receiving_and_Responding',
    filename: 'Receiving_and_Responding.md',
    category: 'interactions',
    categoryName: 'interactions',
    subPath: 'interactions/receiving-and-responding',
  },
  {
    key: 'JA_GETTING_STARTED',
    name: 'Getting Started',
    file: 'Getting_Started',
    filename: 'Getting_Started.mdx',
    category: 'ja',
    categoryName: 'ja',
    subPath: 'ja/getting-started',
  },
  {
    key: 'MONETIZATION_APP_SUBSCRIPTIONS',
    name: 'App Subscriptions',
    file: 'App_Subscriptions',
    filename: 'App_Subscriptions.md',
    category: 'monetization',
    categoryName: 'monetization',
    subPath: 'monetization/app-subscriptions',
  },
  {
    key: 'MONETIZATION_ENTITLEMENTS',
    name: 'Entitlements',
    file: 'Entitlements',
    filename: 'Entitlements.md',
    category: 'monetization',
    categoryName: 'monetization',
    subPath: 'monetization/entitlements',
  },
  {
    key: 'MONETIZATION_OVERVIEW',
    name: 'Overview',
    file: 'Overview',
    filename: 'Overview.md',
    category: 'monetization',
    categoryName: 'monetization',
    subPath: 'monetization/overview',
  },
  {
    key: 'MONETIZATION_SKUS',
    name: 'SKUs',
    file: 'SKUs',
    filename: 'SKUs.md',
    category: 'monetization',
    categoryName: 'monetization',
    subPath: 'monetization/skus',
  },
  {
    key: 'NL_GETTING_STARTED',
    name: 'Getting Started',
    file: 'Getting_Started',
    filename: 'Getting_Started.mdx',
    category: 'nl',
    categoryName: 'nl',
    subPath: 'nl/getting-started',
  },
  {
    key: 'PL_GETTING_STARTED',
    name: 'Getting Started',
    file: 'Getting_Started',
    filename: 'Getting_Started.mdx',
    category: 'pl',
    categoryName: 'pl',
    subPath: 'pl/getting-started',
  },
  {
    key: 'POLICIES_AND_AGREEMENTS_DEVELOPER_POLICY',
    name: 'Developer Policy',
    file: 'Developer_Policy',
    filename: 'Developer_Policy.md',
    category: 'policies_and_agreements',
    categoryName: 'policies and agreements',
    subPath: 'policies-and-agreements/developer-policy',
  },
  {
    key: 'POLICIES_AND_AGREEMENTS_DEVELOPER_TERMS_OF_SERVICE',
    name: 'Developer Terms of Service',
    file: 'Developer_Terms_of_Service',
    filename: 'Developer_Terms_of_Service.md',
    category: 'policies_and_agreements',
    categoryName: 'policies and agreements',
    subPath: 'policies-and-agreements/developer-terms-of-service',
  },
  {
    key: 'POLICIES_AND_AGREEMENTS_PREMIUM_APPS_POLICY',
    name: 'Premium Apps Policy',
    file: 'Premium_Apps_Policy',
    filename: 'Premium_Apps_Policy.md',
    category: 'policies_and_agreements',
    categoryName: 'policies and agreements',
    subPath: 'policies-and-agreements/premium-apps-policy',
  },
  {
    key: 'POLICIES_AND_AGREEMENTS_STORE_DISTRIBUTION_AGREEMENT',
    name: 'Store Distribution Agreement',
    file: 'Store_Distribution_Agreement',
    filename: 'Store_Distribution_Agreement.md',
    category: 'policies_and_agreements',
    categoryName: 'policies and agreements',
    subPath: 'policies-and-agreements/store-distribution-agreement',
  },
  {
    key: 'PT-BR_GETTING_STARTED',
    name: 'Getting Started',
    file: 'Getting_Started',
    filename: 'Getting_Started.mdx',
    category: 'pt-br',
    categoryName: 'pt-br',
    subPath: 'pt-br/getting-started',
  },
  {
    key: 'QUICK_START_GETTING_STARTED',
    name: 'Getting Started',
    file: 'Getting_Started',
    filename: 'Getting_Started.mdx',
    category: 'quick_start',
    categoryName: 'quick start',
    subPath: 'quick-start/getting-started',
  },
  {
    key: 'QUICK_START_OVERVIEW_OF_APPS',
    name: 'Overview of Apps',
    file: 'Overview_of_Apps',
    filename: 'Overview_of_Apps.mdx',
    category: 'quick_start',
    categoryName: 'quick start',
    subPath: 'quick-start/overview-of-apps',
  },
  {
    key: 'RESOURCES_APPLICATION',
    name: 'Application',
    file: 'Application',
    filename: 'Application.md',
    category: 'resources',
    categoryName: 'resources',
    subPath: 'resources/application',
  },
  {
    key: 'RESOURCES_APPLICATION_ROLE_CONNECTION_METADATA',
    name: 'Application Role Connection Metadata',
    file: 'Application_Role_Connection_Metadata',
    filename: 'Application_Role_Connection_Metadata.md',
    category: 'resources',
    categoryName: 'resources',
    subPath: 'resources/application-role-connection-metadata',
  },
  {
    key: 'RESOURCES_AUDIT_LOG',
    name: 'Audit Log',
    file: 'Audit_Log',
    filename: 'Audit_Log.md',
    category: 'resources',
    categoryName: 'resources',
    subPath: 'resources/audit-log',
  },
  {
    key: 'RESOURCES_AUTO_MODERATION',
    name: 'Auto Moderation',
    file: 'Auto_Moderation',
    filename: 'Auto_Moderation.md',
    category: 'resources',
    categoryName: 'resources',
    subPath: 'resources/auto-moderation',
  },
  {
    key: 'RESOURCES_CHANNEL',
    name: 'Channel',
    file: 'Channel',
    filename: 'Channel.md',
    category: 'resources',
    categoryName: 'resources',
    subPath: 'resources/channel',
  },
  {
    key: 'RESOURCES_EMOJI',
    name: 'Emoji',
    file: 'Emoji',
    filename: 'Emoji.md',
    category: 'resources',
    categoryName: 'resources',
    subPath: 'resources/emoji',
  },
  {
    key: 'RESOURCES_GUILD',
    name: 'Guild',
    file: 'Guild',
    filename: 'Guild.md',
    category: 'resources',
    categoryName: 'resources',
    subPath: 'resources/guild',
  },
  {
    key: 'RESOURCES_GUILD_SCHEDULED_EVENT',
    name: 'Guild Scheduled Event',
    file: 'Guild_Scheduled_Event',
    filename: 'Guild_Scheduled_Event.md',
    category: 'resources',
    categoryName: 'resources',
    subPath: 'resources/guild-scheduled-event',
  },
  {
    key: 'RESOURCES_GUILD_TEMPLATE',
    name: 'Guild Template',
    file: 'Guild_Template',
    filename: 'Guild_Template.md',
    category: 'resources',
    categoryName: 'resources',
    subPath: 'resources/guild-template',
  },
  {
    key: 'RESOURCES_INVITE',
    name: 'Invite',
    file: 'Invite',
    filename: 'Invite.md',
    category: 'resources',
    categoryName: 'resources',
    subPath: 'resources/invite',
  },
  {
    key: 'RESOURCES_STAGE_INSTANCE',
    name: 'Stage Instance',
    file: 'Stage_Instance',
    filename: 'Stage_Instance.md',
    category: 'resources',
    categoryName: 'resources',
    subPath: 'resources/stage-instance',
  },
  {
    key: 'RESOURCES_STICKER',
    name: 'Sticker',
    file: 'Sticker',
    filename: 'Sticker.md',
    category: 'resources',
    categoryName: 'resources',
    subPath: 'resources/sticker',
  },
  {
    key: 'RESOURCES_USER',
    name: 'User',
    file: 'User',
    filename: 'User.md',
    category: 'resources',
    categoryName: 'resources',
    subPath: 'resources/user',
  },
  {
    key: 'RESOURCES_VOICE',
    name: 'Voice',
    file: 'Voice',
    filename: 'Voice.md',
    category: 'resources',
    categoryName: 'resources',
    subPath: 'resources/voice',
  },
  {
    key: 'RESOURCES_WEBHOOK',
    name: 'Webhook',
    file: 'Webhook',
    filename: 'Webhook.md',
    category: 'resources',
    categoryName: 'resources',
    subPath: 'resources/webhook',
  },
  {
    key: 'RICH_PRESENCE_BEST_PRACTICES',
    name: 'Best Practices',
    file: 'Best_Practices',
    filename: 'Best_Practices.md',
    category: 'rich_presence',
    categoryName: 'rich presence',
    subPath: 'rich-presence/best-practices',
  },
  {
    key: 'RICH_PRESENCE_FAQ',
    name: 'FAQ',
    file: 'FAQ',
    filename: 'FAQ.md',
    category: 'rich_presence',
    categoryName: 'rich presence',
    subPath: 'rich-presence/faq',
  },
  {
    key: 'RICH_PRESENCE_HOW_TO',
    name: 'How To',
    file: 'How_To',
    filename: 'How_To.md',
    category: 'rich_presence',
    categoryName: 'rich presence',
    subPath: 'rich-presence/how-to',
  },
  {
    key: 'RICH_PRESENCE_LAUNCH_CHECKLIST',
    name: 'Launch Checklist',
    file: 'Launch_Checklist',
    filename: 'Launch_Checklist.md',
    category: 'rich_presence',
    categoryName: 'rich presence',
    subPath: 'rich-presence/launch-checklist',
  },
  {
    key: 'TOPICS_CERTIFIED_DEVICES',
    name: 'Certified Devices',
    file: 'Certified_Devices',
    filename: 'Certified_Devices.md',
    category: 'topics',
    categoryName: 'topics',
    subPath: 'topics/certified-devices',
  },
  {
    key: 'TOPICS_COMMUNITY_RESOURCES',
    name: 'Community Resources',
    file: 'Community_Resources',
    filename: 'Community_Resources.md',
    category: 'topics',
    categoryName: 'topics',
    subPath: 'topics/community-resources',
  },
  {
    key: 'TOPICS_GATEWAY',
    name: 'Gateway',
    file: 'Gateway',
    filename: 'Gateway.md',
    category: 'topics',
    categoryName: 'topics',
    subPath: 'topics/gateway',
  },
  {
    key: 'TOPICS_GATEWAY_EVENTS',
    name: 'Gateway Events',
    file: 'Gateway_Events',
    filename: 'Gateway_Events.md',
    category: 'topics',
    categoryName: 'topics',
    subPath: 'topics/gateway-events',
  },
  {
    key: 'TOPICS_OAUTH2',
    name: 'OAuth2',
    file: 'OAuth2',
    filename: 'OAuth2.md',
    category: 'topics',
    categoryName: 'topics',
    subPath: 'topics/oauth2',
  },
  {
    key: 'TOPICS_OPCODES_AND_STATUS_CODES',
    name: 'Opcodes and Status Codes',
    file: 'Opcodes_and_Status_Codes',
    filename: 'Opcodes_and_Status_Codes.md',
    category: 'topics',
    categoryName: 'topics',
    subPath: 'topics/opcodes-and-status-codes',
  },
  {
    key: 'TOPICS_PERMISSIONS',
    name: 'Permissions',
    file: 'Permissions',
    filename: 'Permissions.md',
    category: 'topics',
    categoryName: 'topics',
    subPath: 'topics/permissions',
  },
  {
    key: 'TOPICS_RPC',
    name: 'RPC',
    file: 'RPC',
    filename: 'RPC.md',
    category: 'topics',
    categoryName: 'topics',
    subPath: 'topics/rpc',
  },
  {
    key: 'TOPICS_RATE_LIMITS',
    name: 'Rate Limits',
    file: 'Rate_Limits',
    filename: 'Rate_Limits.md',
    category: 'topics',
    categoryName: 'topics',
    subPath: 'topics/rate-limits',
  },
  {
    key: 'TOPICS_TEAMS',
    name: 'Teams',
    file: 'Teams',
    filename: 'Teams.md',
    category: 'topics',
    categoryName: 'topics',
    subPath: 'topics/teams',
  },
  {
    key: 'TOPICS_THREADS',
    name: 'Threads',
    file: 'Threads',
    filename: 'Threads.md',
    category: 'topics',
    categoryName: 'topics',
    subPath: 'topics/threads',
  },
  {
    key: 'TOPICS_VOICE_CONNECTIONS',
    name: 'Voice Connections',
    file: 'Voice_Connections',
    filename: 'Voice_Connections.md',
    category: 'topics',
    categoryName: 'topics',
    subPath: 'topics/voice-connections',
  },
  {
    key: 'TR_GETTING_STARTED',
    name: 'Getting Started',
    file: 'Getting_Started',
    filename: 'Getting_Started.mdx',
    category: 'tr',
    categoryName: 'tr',
    subPath: 'tr/getting-started',
  },
  {
    key: 'TUTORIALS_CONFIGURING_APP_METADATA_FOR_LINKED_ROLES',
    name: 'Configuring App Metadata for Linked Roles',
    file: 'Configuring_App_Metadata_for_Linked_Roles',
    filename: 'Configuring_App_Metadata_for_Linked_Roles.md',
    category: 'tutorials',
    categoryName: 'tutorials',
    subPath: 'tutorials/configuring-app-metadata-for-linked-roles',
  },
  {
    key: 'TUTORIALS_DEVELOPING_A_USER_INSTALLABLE_APP',
    name: 'Developing a User Installable App',
    file: 'Developing_a_User_Installable_App',
    filename: 'Developing_a_User_Installable_App.mdx',
    category: 'tutorials',
    categoryName: 'tutorials',
    subPath: 'tutorials/developing-a-user-installable-app',
  },
  {
    key: 'TUTORIALS_HOSTING_ON_CLOUDFLARE_WORKERS',
    name: 'Hosting on Cloudflare Workers',
    file: 'Hosting_on_Cloudflare_Workers',
    filename: 'Hosting_on_Cloudflare_Workers.md',
    category: 'tutorials',
    categoryName: 'tutorials',
    subPath: 'tutorials/hosting-on-cloudflare-workers',
  },
  {
    key: 'TUTORIALS_UPGRADING_TO_APPLICATION_COMMANDS',
    name: 'Upgrading to Application Commands',
    file: 'Upgrading_to_Application_Commands',
    filename: 'Upgrading_to_Application_Commands.md',
    category: 'tutorials',
    categoryName: 'tutorials',
    subPath: 'tutorials/upgrading-to-application-commands',
  },
] as const;
