import murmurhash from 'murmurhash';

import UserStore from '@developers/stores/UserStore';

function isInRange(value: string | number, min?: string | null, max?: string | null): boolean {
  const valueNumber = BigInt(value);
  const minNumber = min != null ? BigInt(min) : null;
  const maxNumber = max != null ? BigInt(max) : null;

  if (minNumber != null && valueNumber < minNumber) {
    return false;
  }
  if (maxNumber != null && valueNumber > maxNumber) {
    return false;
  }
  return true;
}

function getRangeData(filterData: Array<[number, string]>) {
  let min: string | undefined;
  let max: string | undefined;
  for (const [hashedKey, value] of filterData) {
    switch (hashedKey) {
      case murmurhash.v3('min_id'):
        min = value;
        break;
      case murmurhash.v3('max_id'):
        max = value;
        break;
    }
  }
  return {min, max};
}

// filterData is a list of 2 item lists
// [[murmurhash_key, val], [murmurhash_key, val]]
// E.g. for a guild id range filter it looks like
// [[mmh3(min_id), 0], [mmh3(max_id), 1]]
export const GUILD_FILTERS = {
  [murmurhash.v3('guild_ids')]: (filterData: Array<[number, string[]]>) => {
    let guildIds: string[] = [];
    for (const [hashedKey, value] of filterData) {
      switch (hashedKey) {
        case murmurhash.v3('guild_ids'):
          guildIds = value;
          break;
      }
    }
    return (guildId: string) => guildIds.includes(guildId);
  },
  [murmurhash.v3('guild_id_range')]: (filterData: Array<[number, string]>) => {
    const {min, max} = getRangeData(filterData);
    return (guildId: string) => isInRange(guildId, min, max);
  },
  [murmurhash.v3('guild_member_count_range')]: (filterData: Array<[number, string]>) => {
    const {min, max} = getRangeData(filterData);
    return (guildId: string) => {
      const guild = UserStore.getGuild(guildId);
      const guildMemberCount = guild?.approximate_member_count;
      if (guildMemberCount == null) {
        return false;
      }
      return isInRange(guildMemberCount, min, max);
    };
  },
  [murmurhash.v3('guild_has_feature')]: (filterData: Array<[number, string[]]>) => {
    const [[, features]] = filterData;
    return (guildId: string) => {
      const guild = UserStore.getGuild(guildId);
      if (guild == null) return false;
      return features.some((feature: string) => guild.features.includes(feature));
    };
  },
  // guild_hub_types is not implemented since it's not returned from API and also not used in developers now
};
