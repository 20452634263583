import {upperCaseFirstChar} from './StringUtils';

export function getClass(Styles: Record<string, any>, name: string, ...args: Array<string | undefined | null>): string {
  const modes = args.reduce((acc, mode) => acc + upperCaseFirstChar(mode), '');
  const className = `${name}${modes}`;
  const hashedClassName = Styles[className];

  if (hashedClassName == null) {
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.warn(`Class doesn't exist:`, name, className);
    }
    return '';
  }

  return hashedClassName;
}
