import * as React from 'react';
import classNames from 'classnames';
import {FocusRingScope} from 'react-focus-rings';


import {usePaddingFixes, getScrollbarSpecs, getMergedOrientationStyles} from './scroller-utilities';

import type {ScrollerComponentBaseProps, ScrollerOrientationTypes} from './scroller-utilities';

// Scroller is a component that does nothing but allow tall content to
// overflow and scroll.  No need for ref handling, or scrolling state.  It's
// about as bare bones as you need to be, with all the padding fixes included.
// Most of the time you probably want to use this

export interface ScrollerProps extends ScrollerComponentBaseProps {
  orientation?: ScrollerOrientationTypes;
  fade?: boolean;
  children: React.ReactNode;
}

export function createScroller(scrollbarClassName: string, fadeClassName: string) {
  const specs = getScrollbarSpecs(scrollbarClassName);
  return React.forwardRef(function Scroller(
    {
      children,
      className,
      dir = 'ltr',
      orientation = 'vertical',
      paddingFix = true,
      fade = false,
      onScroll,
      style,
      ...props
    }: ScrollerProps,
    ref: React.Ref<HTMLDivElement>
  ) {
    if (process.env.NODE_ENV === 'development' && onScroll != null) {
      throw new Error(
        'Scroller: You should not attach an onScroll handler to the basic Scroller. Use AdvancedScroller instead'
      );
    }
    const scrollerRef: React.MutableRefObject<HTMLDivElement | null> = React.useRef<HTMLDivElement>(null);
    const paddingNode = usePaddingFixes({paddingFix, orientation, dir, className, scrollerRef, specs});
    return (
      <div
        ref={(node: HTMLDivElement | null) => {
          if (typeof ref === 'function') {
            ref(node);
          } else if (ref != null) {
            (ref as React.MutableRefObject<HTMLDivElement | null>).current = node;
          }
          scrollerRef.current = node;
        }}
        className={classNames(className, {[scrollbarClassName]: true, [fadeClassName]: fade})}
        style={getMergedOrientationStyles(style, orientation)}
        dir={dir}
        {...props}>
        <FocusRingScope containerRef={scrollerRef}>
          {children}
          {paddingNode}
        </FocusRingScope>
      </div>
    );
  });
}
