import * as React from 'react';
import classNames from 'classnames';
import {Text} from '@discordapp/design/components/Text/Text.web';

import Flex from '@developers/uikit/Flex';

import styles from './EmptyState.module.css';

interface Props {
  children?: React.ReactNode;
  imageClassName?: string;
  imageUri?: string;
  imageWidth?: number;
  primaryCopy?: React.ReactNode;
  secondaryCopy?: React.ReactNode;
}

const EmptyState = ({children, imageClassName, imageUri, imageWidth = 290, primaryCopy, secondaryCopy}: Props) => {
  const renderChildNodes = (): React.ReactNode => {
    if (children != null) {
      return (
        <Flex.Child grow={0} shrink={0}>
          {children}
        </Flex.Child>
      );
    }
    return null;
  };

  const renderCopyNodes = (): React.ReactNode => {
    const copyNodes = [];

    if (primaryCopy != null) {
      copyNodes.push(
        <Text variant="text-md/medium" color="always-white" key="primary-copy">
          {primaryCopy}
        </Text>
      );
    }

    if (secondaryCopy != null) {
      copyNodes.push(
        <Text variant="text-sm/normal" className={styles.copySecondary} color="text-secondary" key="secondary-copy">
          {secondaryCopy}
        </Text>
      );
    }

    return copyNodes.length === 0 ? null : <div className={styles.copy}>{copyNodes}</div>;
  };

  const renderImageNode = (): React.ReactNode => {
    if (imageUri == null) return null;

    return (
      <Flex.Child grow={0} shrink={1}>
        <img
          alt=""
          className={classNames(styles.image, imageClassName)}
          src={imageUri}
          style={{maxWidth: imageWidth}}
        />
        {renderCopyNodes()}
      </Flex.Child>
    );
  };

  return (
    <Flex align={Flex.Align.CENTER} justify={Flex.Justify.CENTER} className={styles.wrapper}>
      {renderImageNode()}
      {renderChildNodes()}
    </Flex>
  );
};

export default EmptyState;
