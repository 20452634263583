import * as React from 'react';
import {Text} from '@discordapp/design/components/Text/Text.web';

import Avatar from '@developers/uikit/Avatar';
import Flex from '@developers/uikit/Flex';

import {Images} from '@developers/Constants';
import type {SelectOption} from '@developers/flow/Client';
import styles from './AvatarSelectOption.module.css';

export type SelectOptionWithAvatar<T> = {
  avatar: string | null | undefined;
} & SelectOption<T>;

const AvatarSizes = {
  SMALL: Avatar.Sizes.SMALL,
  LARGE: Avatar.Sizes.LARGE,
};

interface Props<T> {
  option: SelectOptionWithAvatar<T>;
  isValue?: boolean;
  avatarSize?: (typeof AvatarSizes)[keyof typeof AvatarSizes];
}

export default function AvatarSelectOption<T>({avatarSize = AvatarSizes.SMALL, option, isValue}: Props<T>) {
  return (
    <Flex
      className={styles.optionWrap}
      grow={1}
      align={Flex.Align.CENTER}
      direction={Flex.Direction.HORIZONTAL}
      wrap={Flex.Wrap.NO_WRAP}>
      <Avatar
        className={styles.icon}
        src={option.avatar == null ? Images.ASSET_DEFAULT_AVATAR_1 : option.avatar}
        size={avatarSize}
      />
      <Text variant="text-md/medium" className={styles.label}>
        {option.label}
      </Text>
      {isValue ? <div className={styles.selectArrowPlaceholder} /> : null}
    </Flex>
  );
}

AvatarSelectOption.AvatarSizes = AvatarSizes;
