import * as React from 'react';
import {
  ConfettiCannon,
  useConfettiCannon,
  CreateConfettiArgs,
  CreateConfettiRequestedOptions,
  Confetti,
  ConfettiCanvasHandle,
  SpriteCanvasHandle,
} from 'confetti-cannon';

const DEFAULT_NUM_TO_FIRE = 20;

export type CanvasClickListener = (e: MouseEvent, confetti: Confetti | null) => void;

type CreateConfettiArgsBase = Partial<CreateConfettiArgs> & Pick<CreateConfettiArgs, 'size'>;
type CreateConfettiArgsMinusBase = Omit<CreateConfettiArgs, 'size'>;
type CreateConfettiAtArgs = Omit<CreateConfettiArgsMinusBase, 'position'>;

interface ConfettiCannonContextType {
  confettiCanvas: ConfettiCanvasHandle | null;
  cannon: ConfettiCannon | null;
  createConfetti: (
    createConfettiArgs: CreateConfettiArgsMinusBase,
    requestedOptions?: CreateConfettiRequestedOptions
  ) => Confetti | undefined;
  createConfettiAt: (
    x: number,
    y: number,
    createConfettiArgs?: CreateConfettiAtArgs,
    requestedOptions?: CreateConfettiRequestedOptions
  ) => Confetti | undefined;
  createMultipleConfetti: (
    createConfettiArgs: CreateConfettiArgsMinusBase,
    numberToFire?: number,
    requestedOptions?: CreateConfettiRequestedOptions
  ) => Confetti[];
  createMultipleConfettiAt: (
    x: number,
    y: number,
    createConfettiArgs?: CreateConfettiAtArgs,
    numberToFire?: number,
    requestedOptions?: CreateConfettiRequestedOptions
  ) => Confetti[];
  addClickListener: (listener: CanvasClickListener) => void;
  removeClickListener: (listener: CanvasClickListener) => void;
}

const DEFAULT_CONTEXT_VALUE: ConfettiCannonContextType = {
  confettiCanvas: null,
  cannon: null,
  createConfetti: () => undefined,
  createConfettiAt: () => undefined,
  createMultipleConfetti: () => [],
  createMultipleConfettiAt: () => [],
  addClickListener: () => () => null,
  removeClickListener: () => null,
};

export const ConfettiCannonContext = React.createContext<ConfettiCannonContextType>(DEFAULT_CONTEXT_VALUE);

interface ConfettiCannonContextProviderProps {
  children: React.ReactNode;
  confettiCanvas: ConfettiCanvasHandle | null;
  spriteCanvas: SpriteCanvasHandle | null;
  baseConfig: CreateConfettiArgsBase;
  addClickListener: (listener: CanvasClickListener) => () => void;
  removeClickListener: (listener: CanvasClickListener) => void;
}

export function ConfettiCannonContextProvider({
  children,
  confettiCanvas,
  spriteCanvas,
  baseConfig,
  addClickListener,
  removeClickListener,
}: ConfettiCannonContextProviderProps) {
  const cannon = useConfettiCannon(confettiCanvas, spriteCanvas);
  const reducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches;

  const value: ConfettiCannonContextType = React.useMemo(() => {
    if (reducedMotion) {
      return DEFAULT_CONTEXT_VALUE;
    }

    return {
      confettiCanvas: confettiCanvas,
      cannon,
      createConfetti: (createConfettiArgs, requestedOptions?) =>
        cannon.createConfetti({...baseConfig, ...createConfettiArgs}, requestedOptions),
      createConfettiAt: (x, y, createConfettiArgs, requestedOptions) =>
        cannon.createConfetti(
          {...baseConfig, position: {type: 'static', value: {x, y}}, ...createConfettiArgs},
          requestedOptions
        ),
      createMultipleConfetti: (createConfettiArgs, numToFire = DEFAULT_NUM_TO_FIRE, requestedOptions) =>
        cannon.createMultipleConfetti({...baseConfig, ...createConfettiArgs}, numToFire, requestedOptions),
      createMultipleConfettiAt: (x, y, createConfettiArgs, numToFire = DEFAULT_NUM_TO_FIRE, requestedOptions) =>
        cannon.createMultipleConfetti(
          {...baseConfig, position: {type: 'static', value: {x, y}}, ...createConfettiArgs},
          numToFire,
          requestedOptions
        ),
      addClickListener,
      removeClickListener,
    };
  }, [addClickListener, baseConfig, cannon, confettiCanvas, reducedMotion, removeClickListener]);

  return <ConfettiCannonContext.Provider value={value}>{children}</ConfettiCannonContext.Provider>;
}
