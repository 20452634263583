import * as React from 'react';
import classNames from 'classnames';
import {Text, TextColors} from '@discordapp/design/components/Text/Text.web';

import styles from './FormLabel.module.css';

interface Props {
  children: React.ReactNode;
  color?: TextColors;
  isFlush?: boolean;
  isRequired?: boolean;
  className?: string;
}

const FormLabel = ({children, color = 'text-normal', isFlush = false, isRequired = false, className}: Props) => (
  <Text
    variant="text-xs/semibold"
    className={classNames(styles.label, {[styles.flush]: isFlush}, className)}
    color={color}
    tag="label">
    {children}
    {isRequired && <span className={styles.requiredIndicator}> *</span>}
  </Text>
);

export default FormLabel;
