import * as React from 'react';

import TrackedLink from './TrackedLink';

import {Routes} from '@developers/Constants';
import {Messages} from '@developers/i18n';
import styles from './AppLogo.module.css';

const AppLogo = () => {
  return (
    <TrackedLink className={styles.wrapper} to={Routes.APPLICATIONS} from="nav">
      <img alt="Discord Logo" src={require('@developers/images/common/discord-logo.svg')} className={styles.logomark} />
      <span className={styles.wordmark}>{Messages.Common.DEVELOPER_PORTAL}</span>
    </TrackedLink>
  );
};

export default AppLogo;
