declare type TimeoutID = ReturnType<Window['setTimeout']>;

export default class Backoff {
  min: number;
  max: number;
  jitter: boolean;

  _current: number;
  _timeoutId?: TimeoutID | null;
  _callback?: ((value: unknown) => void) | null;
  _fails: number = 0;

  /**
   * Create a backoff instance can automatically backoff retries.
   */
  constructor(min: number = 500, max: number | null = null, jitter: boolean = true) {
    if (min <= 0) {
      throw Error(`Backoff min value must be greater than zero or backoff will never back-off.`);
    }

    this.min = min;
    this.max = max != null ? max : min * 10;
    this.jitter = jitter;

    this._current = min;
  }

  /**
   * Return the number of failures.
   */
  get fails(): number {
    return this._fails;
  }

  /**
   * Current backoff value in milliseconds.
   */
  get current(): number {
    return this._current;
  }

  /**
   * A callback is going to fire.
   */
  get pending(): boolean {
    return this._timeoutId != null;
  }

  /**
   * Clear any pending callbacks and reset the backoff.
   */
  succeed() {
    this.cancel();
    this._fails = 0;
    this._current = this.min;
  }

  /**
   * Incremet the backoff and schedule a callback if provided.
   */
  fail(callback?: (value?: unknown) => void): number {
    this._fails += 1;
    let delay = this._current * 2;
    if (this.jitter) {
      delay *= Math.random();
    }
    this._current = Math.min(this._current + delay, this.max);
    if (callback != null) {
      if (this._timeoutId != null) {
        if (this._callback !== callback) {
          throw new Error('callback already pending');
        } else {
          // If the callback is the same, just cancel it and re-schedule.
          this.cancel();
        }
      }
      this._callback = callback;
      // eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error
      // @ts-ignore Web has different type than node.
      this._timeoutId = setTimeout(() => {
        try {
          if (callback != null) {
            callback();
          }
        } finally {
          this.cancel();
        }
      }, this._current);
    }
    return this._current;
  }

  /**
   *  Clear any pending callbacks.
   */
  cancel() {
    this._callback = null;

    if (this._timeoutId != null) {
      clearTimeout(this._timeoutId);
      this._timeoutId = null;
    }
  }
}
