import * as React from 'react';
import Helmet from 'react-helmet';
import {useStateFromStores, useStateFromStoresArray} from '@discordapp/flux';

import * as ApplicationActions from '@developers/actions/ApplicationActions';
import * as TeamActions from '@developers/actions/TeamActions';
import {CardGridContainer} from '@developers/components/common/CardGrid';
import EmptyState from '@developers/components/common/EmptyState';
import PageContent from '@developers/components/common/PageContent';
import PageHeading from '@developers/components/common/PageHeading';
import PageLoadingIndicator from '@developers/components/common/PageLoadingIndicator';
import PageSection from '@developers/components/common/PageSection';
import PageSubheading from '@developers/components/common/PageSubheading';
import PrimaryNavigation from '@developers/components/navigation/PrimaryNavigation';
import ApplicationStore from '@developers/stores/ApplicationStore';
import TeamStore from '@developers/stores/TeamStore';
import UserStore from '@developers/stores/UserStore';
import Alert, {AlertTypes} from '@developers/uikit/Alert';
import Button from '@developers/uikit/Button';
import {APIError, getMessageFromAPIError} from '@developers/utils/ErrorUtils';
import {getTitle} from '@developers/utils/MetaUtils';
import ApplicationGrid from './ApplicationGrid';
import CreateApplicationModal from './CreateApplicationModal';
import StoreSignupModal from './StoreSignupModal';

import {Images, APIErrorCodes} from '@developers/Constants';
import {Messages} from '@developers/i18n';
import type {ApplicationWithDetails} from '@developers/flow/Client';

const getApplicationsByOwnerId = (applications: ApplicationWithDetails[]): Record<string, ApplicationWithDetails[]> => {
  return applications.reduce<Record<string, ApplicationWithDetails[]>>((acc, application: ApplicationWithDetails) => {
    if (acc[application.owner.id] == null) {
      acc[application.owner.id] = [];
    }
    acc[application.owner.id].push(application);
    return acc;
  }, {});
};

const Applications = () => {
  const applications = useStateFromStoresArray([ApplicationStore], () => ApplicationStore.applications);
  const user = useStateFromStores([UserStore], () => UserStore.user);
  const teams = useStateFromStores([TeamStore], () => TeamStore.teamsMap);

  const [errorMessage, setErrorMessage] = React.useState<React.ReactNode>(null);
  const [isCreateModalOpen, setIsCreateModalOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [shouldRenderStoreModal, setShouldRenderStoreModal] = React.useState(
    new URL(location.href).searchParams.get('store_signup') === 'true'
  );

  React.useEffect(() => {
    Promise.all([
      TeamActions.fetchTeams().catch((error: APIError) => {
        // Swallow the unverified email error here.
        if (error.code !== APIErrorCodes.UNVERIFIED_EMAIL) {
          throw error;
        }
      }),
      ApplicationActions.fetchApplications({withTeamApplications: true}),
    ])
      .then(() => {
        setIsLoading(false);
        setErrorMessage(null);
      })
      .catch((error: APIError) => {
        setIsLoading(false);
        setErrorMessage(getMessageFromAPIError(error, Messages.Errors.ERROR_LOADING_APPLICATIONS.format()));
      });

    const params = new URLSearchParams(window.location.search);
    if (params.get('new_application') === 'true') {
      setIsCreateModalOpen(true);
    }
  }, []);

  const handleCreateApplicationClick = () => {
    setIsCreateModalOpen(true);
  };

  const handleCreateApplicationModalRequestClose = () => {
    setIsCreateModalOpen(false);
  };

  const handleDismissStoreSignalModal = () => {
    setShouldRenderStoreModal(false);
  };

  const renderApplicationGrids = (): React.ReactNode => {
    if (isLoading) return <PageLoadingIndicator />;
    if (user == null) return null;
    const applicationsByOwnerId = getApplicationsByOwnerId(applications);
    const ownerIds = Object.keys(applicationsByOwnerId);

    const applicationGrids = [
      <ApplicationGrid
        key={user.id}
        applications={applicationsByOwnerId[user.id]}
        heading={Messages.MyApps.MY_APPLICATIONS_LABEL}
        isFlush={ownerIds.length === 0}
      />,
    ];

    if (applicationsByOwnerId[user.id] == null || applicationsByOwnerId[user.id].length === 0) {
      const hasTeamApps = applications.length > 0;

      applicationGrids.push(
        <EmptyState
          key="Empty state"
          imageUri={Images.EMPTY_GENERIC}
          primaryCopy={hasTeamApps ? Messages.MyApps.NO_PERSONAL_APPS_PRIMARY : Messages.MyApps.NO_APPS_PRIMARY}
          secondaryCopy={hasTeamApps ? Messages.MyApps.NO_PERSONAL_APPS_SECONDARY : Messages.MyApps.NO_APPS_SECONDARY}
        />
      );
    }

    return applicationGrids.concat(
      ownerIds
        .sort()
        .reduce((acc: Array<React.ReactElement<typeof ApplicationGrid>>, ownerId: string, index: number) => {
          if (ownerId !== user.id) {
            const team = teams.get(ownerId);
            if (team != null) {
              acc.push(
                <ApplicationGrid
                  key={ownerId}
                  applications={applicationsByOwnerId[ownerId]}
                  heading={team.name}
                  isFlush={index === ownerIds.length - 1}
                />
              );
            }
          }

          return acc;
        }, [])
    );
  };

  const renderError = (): React.ReactNode => {
    if (errorMessage == null || user == null) return null;
    return <Alert type={AlertTypes.ERROR}>{errorMessage}</Alert>;
  };

  const renderStoreSignupModal = (): React.ReactNode => {
    if (!shouldRenderStoreModal || user == null) return null;
    return <StoreSignupModal onClose={handleDismissStoreSignalModal} />;
  };

  return (
    <React.Fragment>
      <Helmet title={getTitle(Messages.MyApps.PAGE_TITLE)} />
      <PrimaryNavigation />
      <PageContent>
        {renderStoreSignupModal()}
        <PageSection>
          <PageHeading
            action={
              <Button onClick={handleCreateApplicationClick} height={Button.Height.MEDIUM}>
                {Messages.MyApps.NEW_APPLICATION}
              </Button>
            }>
            {Messages.MyApps.APPLICATIONS}
          </PageHeading>
          <PageSubheading isFlush>{Messages.MyApps.INTRO}</PageSubheading>
        </PageSection>
        <PageSection isFlush>
          {renderError()}
          <CardGridContainer>{renderApplicationGrids()}</CardGridContainer>
          {isCreateModalOpen && <CreateApplicationModal onRequestClose={handleCreateApplicationModalRequestClose} />}
        </PageSection>
      </PageContent>
    </React.Fragment>
  );
};

export default Applications;
