import * as React from 'react';
import classNames from 'classnames';
import {Text} from '@discordapp/design/components/Text/Text.web';

import styles from './PageSectionHeading.module.css';

enum BottomMarginSizes {
  MEDIUM = 'MEDIUM',
  MEDIUM_LARGE = 'MEDIUM_LARGE',
  LARGE = 'LARGE',
  X_LARGE = 'X_LARGE',
}

interface Props {
  action?: React.ReactNode;
  children: React.ReactNode;
  isFlush?: boolean;
  bottomMargin?: BottomMarginSizes;
  required?: boolean;
}

class PageSectionHeading extends React.Component<Props> {
  static BottomMarginSizes = BottomMarginSizes;

  static defaultProps = {
    isFlush: false,
    bottomMargin: BottomMarginSizes.MEDIUM,
  };

  render() {
    const {action, children, isFlush, bottomMargin, required} = this.props;

    return (
      <div
        className={classNames(styles.headingContent, {
          [styles.flush]: isFlush,
          [styles.bottomMarginMedium]: bottomMargin === BottomMarginSizes.MEDIUM,
          [styles.bottomMarginLarge]: bottomMargin === BottomMarginSizes.LARGE,
          [styles.bottomMarginMediumLarge]: bottomMargin === BottomMarginSizes.MEDIUM_LARGE,
          [styles.bottomMarginExtraLarge]: bottomMargin === BottomMarginSizes.X_LARGE,
        })}>
        <Text
          variant="text-lg/medium"
          className={classNames(styles.headingCopy, {
            [styles.headingCopyWithAction]: action != null,
            [styles.required]: required,
          })}
          color="always-white">
          {children}
        </Text>
        {action}
      </div>
    );
  }
}

export default PageSectionHeading;
