import * as React from 'react';
import classNames from 'classnames';
import {getClass} from '@discordapp/common/utils/StylesheetUtils';

import styles from './TextInput.module.css';

export const TextInputSizes = {
  DEFAULT: 'default',
  MINI: 'mini',
};

export interface Props<TName extends string = string> {
  readOnly?: boolean;
  className?: string;
  inputClassName?: string;
  name?: TName;
  disabled?: boolean;
  type?: string;
  value?: string;
  defaultValue?: string;
  placeholder?: string;
  error?: string | React.ReactNode;
  hasError?: boolean;
  autoFocus?: boolean;
  minLength?: number;
  maxLength?: number;
  editable?: boolean;
  size?: string;
  // Events
  onChange?: (value: string, name: TName | undefined, e: React.SyntheticEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.SyntheticEvent<HTMLInputElement>, name?: TName) => void;
  onBlur?: (e: React.SyntheticEvent<HTMLInputElement>, name?: TName) => void;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  inputRef?: React.Ref<HTMLInputElement> | undefined | null;
  inputPrefix?: string | null;
  spellCheck?: boolean;
  autoComplete?: string;
}

class TextInput<TName extends string> extends React.PureComponent<Props<TName>> {
  static Sizes = TextInputSizes;

  static defaultProps = {
    name: '',
    size: TextInputSizes.DEFAULT,
    disabled: false,
    type: 'text',
    placeholder: '',
    autoFocus: false,
    maxLength: 999,
  };

  render() {
    const {error, hasError, className, inputClassName, inputPrefix, disabled, size, editable, inputRef, ...props} =
      this.props;

    const errorMessage = error != null ? <div className={styles.errorMessage}>{error}</div> : null;

    return (
      <div className={classNames(styles.inputWrapper, className)}>
        {inputPrefix != null && inputPrefix !== '' ? <span className={styles.inputPrefix}>{inputPrefix}</span> : null}
        <input
          className={classNames(getClass(styles, 'input', size), inputClassName, {
            [styles.error]: error != null || hasError,
            [styles.disabled]: disabled,
            [styles.editable]: editable,
          })}
          disabled={disabled}
          {...props}
          onChange={this.onChange}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          ref={inputRef}
        />
        {errorMessage}
      </div>
    );
  }

  /* Handlers */
  onChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    this.props.onChange?.(e.currentTarget.value, this.props.name, e);
  };

  onFocus = (e: React.SyntheticEvent<HTMLInputElement>) => {
    this.props.onFocus?.(e, this.props.name);
  };

  onBlur = (e: React.SyntheticEvent<HTMLInputElement>) => {
    this.props.onBlur?.(e, this.props.name);
  };
}

export default TextInput;
