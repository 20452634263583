import type {IconProps} from './IconTypes';

export default function addDefaultIconProps(props: IconProps) {
  const hasLabel = props['aria-label'] != null;
  props['aria-hidden'] = props['aria-hidden'] ?? !hasLabel;
  // Defaulting to the `img` role ensures browsers don't treat the SVG like
  // a `group` (they generally don't anyway, but this is a safety net).
  props['role'] = props['role'] ?? 'img';

  return props;
}
