import {GetExperimentsPlatform} from '@discordapp/common/shared-constants/GetExperimentsPlatform';
import HTTPUtils from '@discordapp/http-utils';

import Dispatcher from '@developers/Dispatcher';
import ActionTypes from './ActionTypes';

import {Endpoints} from '@developers/Constants';
import type {ExperimentAssignment, GuildExperimentAssignment} from '@developers/flow/Server';

// All of these actioncreators need to be dispatches since they could be
// triggered at odd times

interface ExperimentsResponse {
  body: {
    fingerprint: string | null | undefined;
    assignments: ExperimentAssignment[] | null | undefined;
    guild_experiments?: GuildExperimentAssignment[];
  };
}

export function fetchExperiments(
  options: {
    withGuildExperiments?: boolean;
  } = {}
) {
  const {withGuildExperiments = false} = options;

  return HTTPUtils.get({
    url: Endpoints.EXPERIMENTS,
    query: {
      with_guild_experiments: withGuildExperiments,
      platform: GetExperimentsPlatform.DEVELOPER_PORTAL,
    },
    oldFormErrors: true,
  }).then((res: ExperimentsResponse) => {
    if (res == null || res.body == null) {
      // eslint-disable-next-line no-console
      console.warn('ExperimentActions.fetchExperiments: Invalid response', res);
      return;
    }
    Dispatcher.dispatch({type: ActionTypes.FINGERPRINT_SET, fingerprint: res.body.fingerprint ?? null});
    Dispatcher.dispatch({
      type: ActionTypes.EXPERIMENTS_FETCH_SUCCESS,
      experiments: res.body.assignments ?? [],
      guildExperiments: res.body.guild_experiments ?? [],
    });
  });
}
