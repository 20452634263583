import Flux from '@discordapp/flux';

import Dispatcher from '@developers/Dispatcher';
import ActionTypes from '@developers/actions/ActionTypes';

import type {Action, ActionFor} from '@developers/flow/Action';
import type {AggregatedSummary, Summary} from '@developers/flow/Server';

let aggregatedSummaries: Record<string, AggregatedSummary[]> = {};
let aggregatedSummaryComponents: Record<string, Summary[]> = {};
let isFetchingAggregatedSummaries: Record<string, boolean> = {};
let isFetchingAggregatedSummaryComponents: Record<string, boolean> = {};
let hasFetchedAggregatedSummaries: Record<string, boolean> = {};
let lastUpdatedTimestamps: Record<string, string | null> = {};

const handleAggregatedSummariesFetchStart = ({guildId}: ActionFor<'AGGREGATED_SUMMARIES_FETCH_START'>) => {
  aggregatedSummaries[guildId] = [];
  isFetchingAggregatedSummaries[guildId] = true;
  hasFetchedAggregatedSummaries[guildId] = false;
  lastUpdatedTimestamps[guildId] = null;
};

const handleAggregatedSummariesFetchSuccess = ({
  guildId,
  fetchedAggregatedSummaries,
}: ActionFor<'AGGREGATED_SUMMARIES_FETCH_SUCCESS'>) => {
  const newAggregatedSummaries = fetchedAggregatedSummaries == null ? [] : fetchedAggregatedSummaries.topics;
  const newSnapshot = fetchedAggregatedSummaries == null ? null : fetchedAggregatedSummaries.snapshot_ts;

  aggregatedSummaries[guildId] = [...aggregatedSummaries[guildId], ...newAggregatedSummaries];
  lastUpdatedTimestamps[guildId] = newSnapshot;
  isFetchingAggregatedSummaries[guildId] = false;
  hasFetchedAggregatedSummaries[guildId] = true;
};

const handleAggregatedSummariesFetchFailure = ({guildId}: ActionFor<'AGGREGATED_SUMMARIES_FETCH_FAILURE'>) => {
  isFetchingAggregatedSummaries[guildId] = false;
  lastUpdatedTimestamps[guildId] = null;
  hasFetchedAggregatedSummaries[guildId] = true;
};

const handleAggregatedSummaryComponentsFetchStart = ({
  aggregatedSummaryId,
}: ActionFor<'AGGREGATED_SUMMARY_COMPONENTS_FETCH_START'>) => {
  isFetchingAggregatedSummaryComponents[aggregatedSummaryId] = true;
};

const handleAggregatedSummaryComponentsDataFetched = ({
  aggregatedSummaryId,
  fetchedAggregatedSummaryComponents,
}: ActionFor<'AGGREGATED_SUMMARY_COMPONENTS_DATA_FETCHED'>) => {
  const newSummaryComponents =
    fetchedAggregatedSummaryComponents == null ? [] : fetchedAggregatedSummaryComponents.summaries;
  const newSummaries: Summary[] = newSummaryComponents.map((summaryComponent) => {
    return {
      id: summaryComponent.id,
      topic: summaryComponent.headline,
      summ_short: summaryComponent.description,
      message_ids: summaryComponent.message_ids,
      people: summaryComponent.people,
      channelId: summaryComponent.channel_id,
    };
  });

  const existingSummaries =
    aggregatedSummaryId in aggregatedSummaryComponents ? aggregatedSummaryComponents[aggregatedSummaryId] : [];

  aggregatedSummaryComponents[aggregatedSummaryId] = [...existingSummaries, ...newSummaries];
};

const handleAggregatedSummaryComponentsFetchSuccess = ({
  aggregatedSummaryId,
}: ActionFor<'AGGREGATED_SUMMARY_COMPONENTS_FETCH_SUCCESS'>) => {
  isFetchingAggregatedSummaryComponents[aggregatedSummaryId] = false;
};

const handleAggreagtedSummaryComponentsFetchFailure = ({
  aggregatedSummaryId,
}: ActionFor<'AGGREGATED_SUMMARY_COMPONENTS_FETCH_FAILURE'>) => {
  isFetchingAggregatedSummaryComponents[aggregatedSummaryId] = false;
};

function handleUserLogout() {
  aggregatedSummaries = {};
  aggregatedSummaryComponents = {};
  isFetchingAggregatedSummaries = {};
  isFetchingAggregatedSummaryComponents = {};
  hasFetchedAggregatedSummaries = {};

  lastUpdatedTimestamps = {};
}

class AggregatedSummariesStore extends Flux.Store<Action> {
  static displayName = 'AggregatedSummariesStore';

  getAggregatedSummaries(guildId: string | null): AggregatedSummary[] {
    if (guildId == null || !(guildId in aggregatedSummaries)) {
      return [];
    }
    return aggregatedSummaries[guildId];
  }

  getAggregatedSummaryComponents(aggregatedSummaryId?: string): Summary[] {
    if (aggregatedSummaryId == null || !(aggregatedSummaryId in aggregatedSummaryComponents)) {
      return [];
    }

    return aggregatedSummaryComponents[aggregatedSummaryId];
  }

  getIsFetchingAggregatedSummaries(guildId: string | null): boolean {
    if (guildId == null || !(guildId in isFetchingAggregatedSummaries)) {
      return false;
    }

    return isFetchingAggregatedSummaries[guildId];
  }

  getIsFetchingAggregatedSummaryComponents(aggregatedSummaryId?: string): boolean {
    if (aggregatedSummaryId == null || !(aggregatedSummaryId in isFetchingAggregatedSummaryComponents)) {
      return false;
    }

    return isFetchingAggregatedSummaryComponents[aggregatedSummaryId];
  }

  getHasFetchedAggregatedSummaries(guildId: string | null): boolean {
    if (guildId == null || !(guildId in hasFetchedAggregatedSummaries)) {
      return false;
    }

    return hasFetchedAggregatedSummaries[guildId];
  }

  getLastRanAtTimestamp(guildId: string | null): string | null {
    if (guildId == null || !(guildId in lastUpdatedTimestamps)) {
      return null;
    }

    return lastUpdatedTimestamps[guildId];
  }
}

export default new AggregatedSummariesStore(Dispatcher, {
  [ActionTypes.AGGREGATED_SUMMARIES_FETCH_START]: handleAggregatedSummariesFetchStart,
  [ActionTypes.AGGREGATED_SUMMARIES_FETCH_SUCCESS]: handleAggregatedSummariesFetchSuccess,
  [ActionTypes.AGGREGATED_SUMMARIES_FETCH_FAILURE]: handleAggregatedSummariesFetchFailure,
  [ActionTypes.AGGREGATED_SUMMARY_COMPONENTS_FETCH_START]: handleAggregatedSummaryComponentsFetchStart,
  [ActionTypes.AGGREGATED_SUMMARY_COMPONENTS_DATA_FETCHED]: handleAggregatedSummaryComponentsDataFetched,
  [ActionTypes.AGGREGATED_SUMMARY_COMPONENTS_FETCH_SUCCESS]: handleAggregatedSummaryComponentsFetchSuccess,
  [ActionTypes.AGGREGATED_SUMMARY_COMPONENTS_FETCH_FAILURE]: handleAggreagtedSummaryComponentsFetchFailure,
  [ActionTypes.USER_LOGOUT]: handleUserLogout,
});
