import cookie from 'cookie';
import I18N from '@discordapp/i18n';

import TrackingAnalyticsUtils from '@developers/utils/TrackingAnalyticsUtils';

import {AnalyticsEvents} from '@developers/Constants';

// TODO(beckwith): We need to look at the automatic type generation for strings used in discord_app.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const i18n = new I18N<any>({
  initialLocale: cookie.parse(document.cookie).locale,
  getMessages: (locale) => {
    return require(`./messages/${locale}`);
  },
  getLanguages: () => require('./languages.json'),
});

i18n.addListener('locale', (locale: string, oldLocale: string) => {
  document.cookie = `locale=${locale}`;

  if (locale !== oldLocale && oldLocale !== '') {
    TrackingAnalyticsUtils.track(AnalyticsEvents.CHANGE_MARKETING_LOCALE, {from: oldLocale, to: locale});
  }
});

// in our codebase, we import Messages as import {Messages} from i18n, which
// is invalid, but our old babel setup allowed it
export const Messages = i18n.Messages;
export default i18n;
