/**
 * THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY
 * To add new icons to the system, or to update existing icons, run `clyde gen design icons`.
 */

import * as React from 'react';
import tokens from '@discordapp/tokens/web';

import addDefaultIconProps from '../addDefaultIconProps';

import type {SingleColorIconProps} from '../IconTypes';

/**
 * ![CheckmarkSmallIcon](data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAoklEQVR4nO3SvQrCMBDA8YKCs9BC3yZrsLnLLcelPq2bgjpoB5/A51CuGqdSxEYQyX+/H5ePosjl/j/LXAHJHr1svoKjDx1SuKEP2+Q4kJx7nOTkXFu+P2ztwhgzH8Nfm3+CA8kVfTgODU7CNWaeKR4By1wlw2NNs14CyeHxeHJZMdeT7nwoBRR6/pAuyeajJ6HEeMy5tgSSnf7z5Hgu9xvdAcA5YvxDzxSBAAAAAElFTkSuQmCC)
 */
export const CheckmarkSmallIcon = ({
  width = 24,
  height = 24,
  color = tokens.colors.INTERACTIVE_NORMAL,
  colorClass = '',
  ...props
}: SingleColorIconProps) => {
  return (
    <svg
      {...addDefaultIconProps(props)}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 24 24">
      <path
        fill={typeof color === 'string' ? color : color.css}
        fillRule="evenodd"
        d="M18.7 7.3a1 1 0 0 1 0 1.4l-8 8a1 1 0 0 1-1.4 0l-4-4a1 1 0 1 1 1.4-1.4l3.3 3.29 7.3-7.3a1 1 0 0 1 1.4 0Z"
        clipRule="evenodd"
        className={colorClass}
      />
    </svg>
  );
};
