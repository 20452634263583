import * as React from 'react';
import classnames from 'classnames';
import kebabCase from 'lodash/kebabCase';
import tokens from '@discordapp/tokens/web';

type Kebab<T extends string> = String.Replace<Lowercase<T>, '_', '-'>;
const KEBAB_COLOR_MAPPINGS = Object.fromEntries(Object.keys(tokens.colors).map((key) => [kebabCase(key), key])) as {
  [K in keyof typeof tokens.colors as Kebab<K>]: K;
};
type AllColors = keyof typeof KEBAB_COLOR_MAPPINGS | 'always-white' | undefined;

import type {TextVariant} from '@discordapp/tokens/typography/generated/TextVariants';
import type {String} from 'ts-toolbelt';
import styles from './Text.module.css';
import variantStyles from '@discordapp/tokens/typography/generated/TextVariants.module.css';

// Until Design Systems works on separating colors from effects, this little workaround
// will remove non-colors from the list of things you can pass to the `color` prop.
export type TextColors =
  | Extract<
      AllColors,
      `interactive-${string}` | `${string}-text` | `text-${string}` | `header-${string}` | `status-${string}`
    >
  | 'always-white'
  | 'none';

export {TextVariant};

export interface TextProps
  extends React.PropsWithChildren<
    {
      /**
       * Apply a named visual style to the text. These match the [text styles found in Figma](https://www.figma.com/file/TBUKIi4YR9E2r6AxZxViZEh5/Tokens?type=design&node-id=12491-946).
       *
       * Note that the text style may make text _look like_ a heading, but to render an `h1`-`h6` heading, you must use
       * the `Heading` component.
       */
      variant: TextVariant;

      /**
       * Apply any one of our semantic color tokens, in the format `token-name`. Set to `none` to not apply any color
       * (and allow the color to be inherited or set from CSS).
       */
      color?: TextColors;

      /** Use a particular tag when rendering this text node. */
      tag?: 'div' | 'span' | 'p' | 'strong' | 'label' | 'li';

      /** Whether the text can be selected by the user (useful for making text copyable).  */
      selectable?: boolean;

      /**
       * Truncate text to a given number of lines, truncating with an ellipsis. Keep in mind that, regardless of
       * truncation, the full text contents will still be readable by screen readers.
       */
      lineClamp?: number;

      id?: string;
      className?: string;
      style?: React.CSSProperties;
      'aria-label'?: string;

      /**
       * @deprecated This prop has has been removed in favor of setting `lineClamp={1}`. If you need to truncate text
       * without ellipsis (which is not recommended), manually set `overflow: hidden` in CSS.
       */
      textOverflow?: never;

      /**
       * Whether or not to enable tabular numbers.
       */
      tabularNumbers?: boolean;

      /**
       * Whether or not to scale the font size according to the user's setting in Appearance > Chat Font Scaling .
       */
      scaleFontToUserSetting?: boolean;
    } & Partial<Pick<HTMLElement, 'title'>>
  > {}

/**
 * The Text component is used to apply one of our [text styles found in Figma][figma] to a text node. By default, it
 * renders a div—this means that you must add your own semantic meaning where appropriate.
 *
 * For headings, use the `Heading` component. You can still apply `heading/*` styles to `Text`, but an ESLint rule will
 * attempt to prevent you from doing this without a valid reason.
 *
 * Use `aria-hidden="true"` if the text is decorative and shouldn't be announced by screen readers.
 *
 * [figma]: https://www.figma.com/file/TBUKIi4YR9E2r6AxZxViZEh5/*-Styles?node-id=39%3A25
 */
export const Text = ({
  variant,
  tag = 'div',
  selectable = false,
  className: extraClassName,
  lineClamp,
  color,
  tabularNumbers = false,
  scaleFontToUserSetting = false,
  ...restProps
}: TextProps) => {
  // HACK: This can't be renamed in the function arguments (`tag: Tag = 'div'`) because doing that breaks the docgen.
  const Tag = tag;

  let lineClampClassName = '';
  let lineClampStyle: React.CSSProperties = {};

  if (lineClamp != null) {
    if (lineClamp === 1) {
      lineClampClassName = styles.lineClamp1;
    } else {
      lineClampClassName = styles.lineClamp2Plus;
      lineClampStyle = {
        lineClamp,
        WebkitLineClamp: lineClamp,
      };
    }
  }

  let finalColor: string | undefined;
  if (color !== undefined) {
    switch (color) {
      case 'none':
        finalColor = undefined;
        break;

      case 'always-white':
        finalColor = 'white';
        break;

      default:
        finalColor = tokens.colors[KEBAB_COLOR_MAPPINGS[color]]?.css;
    }
  }

  const style = {
    color: finalColor,
    ...lineClampStyle,
    ...restProps.style,
  };

  return (
    <Tag
      className={classnames(
        {
          [styles.defaultColor]: color === undefined,
          [styles.selectable]: selectable,
          [styles.tabularNumbers]: tabularNumbers,
          [variantStyles.fontScaling]: scaleFontToUserSetting,
        },
        lineClampClassName,
        variantStyles[variant],
        extraClassName
      )}
      {...restProps}
      style={Object.values(style).filter(Boolean).length > 0 ? style : undefined}
      data-text-variant={variant}
    />
  );
};
