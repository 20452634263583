import Flux from '@discordapp/flux';

import Dispatcher from '@developers/Dispatcher';
import ActionTypes from '@developers/actions/ActionTypes';
import {getApplicationIcon} from '@developers/utils/ApplicationImageUtils';

import type {ActionFor, Action} from '@developers/flow/Action';
import type {ApplicationWithDetails} from '@developers/flow/Client';
import type {
  ApplicationId,
  Application,
  AppOwnerId,
  Asset,
  StoreAsset,
  ManifestLabel,
  WhitelistEntry,
  ApplicationProxyMapping,
  ApplicationEmbeddedActivityConfig,
  ApplicationCommand,
} from '@developers/flow/Server';
import type {ApplicationDiscoveryEligibilityFlags} from '@discordapp/common/shared-constants/ApplicationDiscoveryEligibilityFlags';

interface ApplicationStorePersistedState {
  hasAcknowledgedAppOnboarding: Set<ApplicationId>;
}

function createState(): ApplicationStorePersistedState {
  return {
    hasAcknowledgedAppOnboarding: new Set([]),
  };
}

const persistedState: ApplicationStorePersistedState = createState();

let hasFetchedApplications: boolean = false;

let applications: Record<ApplicationId, ApplicationWithDetails> = {};
let applicationList: ApplicationWithDetails[] = [];
let applicationWhitelists: Record<ApplicationId, WhitelistEntry[]> = {};
let applicationAssets: Record<ApplicationId, Asset[]> = {};
let storeAssets: Record<ApplicationId, StoreAsset[]> = {};
let manifestLabels: Record<ApplicationId, ManifestLabel[]> = {};

const applicationProxyConfig: Record<ApplicationId, ApplicationProxyMapping[]> = {};
const applicationEmbeddedActivityConfig: Record<ApplicationId, ApplicationEmbeddedActivityConfig> = {};

function addAssetToApplication(appId: ApplicationId, assetEntry: Asset): void {
  const assets = applicationAssets[appId];
  if (assets == null) throw new Error(`App not found in store: ${appId}`);
  assets.push(assetEntry);
  applicationAssets[appId] = sortApplicationAssets(assets);
}

function addUserToApplicationWhitelist(appId: ApplicationId, whitelistEntry: WhitelistEntry): void {
  const whitelist = applicationWhitelists[appId];
  if (whitelist == null) throw new Error(`App not found in store: ${appId}`);
  whitelist.push(whitelistEntry);
  applicationWhitelists[appId] = sortApplicationWhitelist(whitelist);
}

function getApplicationDetails(application: Application): ApplicationWithDetails {
  return {
    ...application,
    appIcon: getApplicationIcon(application.id, application.icon),
    coverImage: getApplicationIcon(application.id, application.cover_image),
  };
}

function insertApplications(nextApplications: Application[]): void {
  applications = {...applications};
  hasFetchedApplications = true;

  nextApplications.forEach((application) => {
    setApplicationWithDetails(application);
  });
}

function removeAssetFromApplication(appId: ApplicationId, assetId: string): void {
  const assets = applicationAssets[appId];
  if (assets == null) throw new Error(`App not found in store: ${appId}`);
  applicationAssets[appId] = sortApplicationAssets(assets.filter((entry) => entry.id !== assetId));
}

function removeUserFromApplicationWhitelist(appId: ApplicationId, userId: string): void {
  const whitelist = applicationWhitelists[appId];
  if (whitelist == null) throw new Error(`App not found in store: ${appId}`);
  applicationWhitelists[appId] = sortApplicationWhitelist(whitelist.filter((entry) => entry.user.id !== userId));
}

function setApplicationAssets(appId: ApplicationId, assets: Asset[]): void {
  applicationAssets[appId] = sortApplicationAssets(assets);
}

function setApplicationDiscoverability(
  appId: ApplicationId,
  badCommands: ApplicationCommand[],
  discoverabilityState: number,
  discoveryEligibilityFlags: Values<typeof ApplicationDiscoveryEligibilityFlags>
) {
  const application = applications[appId];

  if (application == null) {
    return;
  }

  setApplicationWithDetails({
    ...application,
    bad_commands: badCommands,
    discovery_eligibility_flags: discoveryEligibilityFlags,
    discoverability_state: discoverabilityState,
  });
}

function setApplicationWhitelist(appId: ApplicationId, whitelist: WhitelistEntry[]): void {
  applicationWhitelists[appId] = sortApplicationWhitelist(whitelist);
}

function setApplicationProxyConfig(appId: ApplicationId, config: ApplicationProxyMapping[]) {
  applicationProxyConfig[appId] = config;
}

function setApplicationEmbeddedActivityConfig(appId: ApplicationId, config: ApplicationEmbeddedActivityConfig) {
  applicationEmbeddedActivityConfig[appId] = config;
}

function sortApplicationWhitelist(whitelist: WhitelistEntry[]): WhitelistEntry[] {
  return whitelist.sort((a, b) => a.user.username.localeCompare(b.user.username));
}

function sortApplicationAssets(assets: Asset[]): Asset[] {
  return assets.sort((a, b) => a.name.localeCompare(b.name));
}

function setApplicationWithDetails(application: Application): void {
  const previousApplication = applications[application.id];
  applications = {...applications, [application.id]: getApplicationDetails({...previousApplication, ...application})};
}

function removeApplication(appId: ApplicationId): void {
  delete applications[appId];
}

function updateApplicationList(): void {
  applicationList = Object.keys(applications)
    .sort((idA, idB) => idA.localeCompare(idB, undefined, {numeric: true}))
    .map((appId) => applications[appId]);
}

function addStoreAsset(appId: ApplicationId, asset: StoreAsset): void {
  if (storeAssets[appId] == null) {
    storeAssets[appId] = [];
  }

  storeAssets[appId] = [...storeAssets[appId], asset];
}

function addStoreAssets(appId: ApplicationId, assets: StoreAsset[]): void {
  if (storeAssets[appId] == null) {
    storeAssets[appId] = [];
  }

  storeAssets[appId] = [...storeAssets[appId], ...assets];
}

function setStoreAssets(appId: ApplicationId, assets: StoreAsset[]): void {
  storeAssets[appId] = assets;
}

function removeStoreAsset(appId: ApplicationId, assetId: string): void {
  storeAssets[appId] = storeAssets[appId].filter((asset) => asset.id !== assetId);
}

function setManifestLabels(appId: ApplicationId, appManifestLabels: ManifestLabel[]): void {
  manifestLabels[appId] = appManifestLabels;
}

function handleUserLogout() {
  hasFetchedApplications = false;
  applications = {};
  applicationList = [];
  applicationWhitelists = {};
  applicationAssets = {};
  storeAssets = {};
  manifestLabels = {};
}

class ApplicationStore extends Flux.PersistedStore<ApplicationStorePersistedState, Action> {
  static displayName = 'ApplicationStore';
  static persistKey = 'Applications';

  initialize(cachedState?: ApplicationStorePersistedState | null) {
    if (Array.isArray(cachedState?.hasAcknowledgedAppOnboarding)) {
      persistedState.hasAcknowledgedAppOnboarding = new Set(cachedState?.hasAcknowledgedAppOnboarding);
    }
  }

  static migrations = [
    (cachedState: ApplicationStorePersistedState & {hasAcknowledgedOnboarding?: boolean}) => {
      delete cachedState.hasAcknowledgedOnboarding;
      return {
        ...cachedState,
      };
    },
  ];

  getState(): ApplicationStorePersistedState {
    return persistedState;
  }

  getHasAcknowledgedAppOnboarding(applicationId: ApplicationId): boolean {
    return persistedState.hasAcknowledgedAppOnboarding.has(applicationId);
  }

  get applications(): ApplicationWithDetails[] {
    return applicationList;
  }

  get hasFetchedApplications(): boolean {
    return hasFetchedApplications;
  }

  getApplication(appId: string): ApplicationWithDetails | null | undefined {
    return applications[appId];
  }

  getApplicationAssets(appId: string): Asset[] {
    return applicationAssets[appId] ?? [];
  }

  getApplicationWhitelist(appId: string): WhitelistEntry[] | null | undefined {
    return applicationWhitelists[appId];
  }

  getApplicationProxyConfig(appId: string): ApplicationProxyMapping[] | null {
    return applicationProxyConfig[appId];
  }

  getApplicationEmbeddedActivityConfig(appId: string): ApplicationEmbeddedActivityConfig | null {
    return applicationEmbeddedActivityConfig[appId];
  }

  getStoreAssets(appId: string): StoreAsset[] {
    return storeAssets[appId] ?? [];
  }

  getManifestLabels(appId: string): ManifestLabel[] | null | undefined {
    return manifestLabels[appId];
  }

  getApplicationsByOwner(selectedOwnerId: AppOwnerId): ApplicationWithDetails[] {
    return applicationList.filter((application) => {
      if (application.owner == null) return false;
      return application.owner.id === selectedOwnerId;
    });
  }
}

function handleApplicationsFetchSuccess(
  action: ActionFor<'APPLICATIONS_FETCH_SUCCESS' | 'TEAM_APPLICATIONS_FETCH_SUCCESS'>
) {
  insertApplications(action.applications);
  updateApplicationList();
}

function handleApplicationUpdate(
  action: ActionFor<
    | 'APPLICATION_FETCH_SUCCESS'
    | 'APPLICATION_CREATE_SUCCESS'
    | 'APPLICATION_OWNER_TRANSFER_SUCCESS'
    | 'APPLICATION_LICENSE_ACTIVATE_SUCCESS'
  >
) {
  setApplicationWithDetails(action.application);
  updateApplicationList();
}

function handleApplicationDiscoverabilityStateFetchSuccess({
  appId,
  badCommands,
  discoveryEligibilityFlags,
  discoverabilityState,
}: ActionFor<'APPLICATION_DISCOVERABILITY_STATE_FETCH_SUCCESS'>) {
  setApplicationDiscoverability(appId, badCommands, discoverabilityState, discoveryEligibilityFlags);
}

function handleApplicationDeleteSuccess(action: ActionFor<'APPLICATION_DELETE_SUCCESS'>) {
  removeApplication(action.appId);
  updateApplicationList();
}

function handleApplicationWhitelistFetchSuccess(action: ActionFor<'APPLICATION_WHITELIST_FETCH_SUCCESS'>) {
  setApplicationWhitelist(action.appId, action.whitelist);
}

function handleApplicationWhitelistAddUserSuccess(action: ActionFor<'APPLICATION_WHITELIST_ADD_USER_SUCCESS'>) {
  addUserToApplicationWhitelist(action.appId, action.whitelistEntry);
}

function handleApplicationWhitelistRemoveUserSuccess(action: ActionFor<'APPLICATION_WHITELIST_REMOVE_USER_SUCCESS'>) {
  removeUserFromApplicationWhitelist(action.appId, action.userId);
}

function handleApplicationAssetsFetchSuccess(action: ActionFor<'APPLICATION_ASSETS_FETCH_SUCCESS'>) {
  setApplicationAssets(action.appId, action.assets);
}

function handleApplicationAssetsAddSuccess(action: ActionFor<'APPLICATION_ASSETS_ADD_SUCCESS'>) {
  addAssetToApplication(action.appId, action.asset);
}

function handleApplicationAssetsRemoveSuccess(action: ActionFor<'APPLICATION_ASSETS_REMOVE_SUCCESS'>) {
  removeAssetFromApplication(action.appId, action.assetId);
}

function handleStoreAssetsFetchSuccess(action: ActionFor<'STORE_ASSETS_FETCH_SUCCESS'>) {
  setStoreAssets(action.appId, action.assets);
}

function handleStoreAssetUploadSuccess(action: ActionFor<'STORE_ASSET_UPLOAD_SUCCESS'>) {
  addStoreAsset(action.appId, action.asset);
}

function handleStoreAssetsUploadSuccess(action: ActionFor<'STORE_ASSETS_UPLOAD_SUCCESS'>) {
  addStoreAssets(action.appId, action.assets);
}

function handleStoreAssetRemoveSuccess(action: ActionFor<'STORE_ASSET_REMOVE_SUCCESS'>) {
  removeStoreAsset(action.appId, action.assetId);
}

function handleManifestLabelsFetchSuccess(action: ActionFor<'MANIFEST_LABELS_FETCH_SUCCESS'>) {
  setManifestLabels(action.appId, action.manifestLabels);
}

function handleApplicationProxyUpdate(
  action: ActionFor<'APPLICATION_PROXY_CONFIG_FETCH_SUCCESS' | 'APPLICATION_PROXY_CONFIG_UPDATE_SUCCESS'>
) {
  setApplicationProxyConfig(action.appId, action.config);
}

function handleApplicationEmbeddedActivityConfigUpdate(
  action: ActionFor<
    'APPLICATION_EMBEDDED_ACTIVITY_CONFIG_FETCH_SUCCESS' | 'APPLICATION_EMBEDDED_ACTIVITY_CONFIG_UPDATE_SUCCESS'
  >
) {
  setApplicationEmbeddedActivityConfig(action.appId, action.config);
}

function handleApplicationResetSecretSuccess(action: ActionFor<'APPLICATION_RESET_SECRET_SUCCESS'>) {
  const application = applications[action.appId];
  if (application == null) {
    return false;
  }
  setApplicationWithDetails({...application, secret: action.secret});
  return true;
}

function handleApplicationResetBotTokenSuccess(action: ActionFor<'APPLICATION_RESET_BOT_TOKEN_SUCCESS'>): void {
  const application = applications[action.appId];
  if (application == null || application.bot == null) {
    return;
  }
  setApplicationWithDetails({...application, bot: {...application.bot, token: action.token}});
}

function handleAcknowledgeOnboarding({applicationId}: ActionFor<'ACKNOWLEDGE_ONBOARDING'>) {
  persistedState.hasAcknowledgedAppOnboarding.add(applicationId);
}

export default new ApplicationStore(Dispatcher, {
  [ActionTypes.APPLICATIONS_FETCH_SUCCESS]: handleApplicationsFetchSuccess,
  [ActionTypes.TEAM_APPLICATIONS_FETCH_SUCCESS]: handleApplicationsFetchSuccess,
  [ActionTypes.APPLICATION_FETCH_SUCCESS]: handleApplicationUpdate,
  [ActionTypes.APPLICATION_CREATE_SUCCESS]: handleApplicationUpdate,
  [ActionTypes.APPLICATION_OWNER_TRANSFER_SUCCESS]: handleApplicationUpdate,
  [ActionTypes.APPLICATION_LICENSE_ACTIVATE_SUCCESS]: handleApplicationUpdate,
  [ActionTypes.APPLICATION_DELETE_SUCCESS]: handleApplicationDeleteSuccess,
  [ActionTypes.APPLICATION_WHITELIST_FETCH_SUCCESS]: handleApplicationWhitelistFetchSuccess,
  [ActionTypes.APPLICATION_WHITELIST_ADD_USER_SUCCESS]: handleApplicationWhitelistAddUserSuccess,
  [ActionTypes.APPLICATION_WHITELIST_REMOVE_USER_SUCCESS]: handleApplicationWhitelistRemoveUserSuccess,
  [ActionTypes.APPLICATION_ASSETS_FETCH_SUCCESS]: handleApplicationAssetsFetchSuccess,
  [ActionTypes.APPLICATION_ASSETS_ADD_SUCCESS]: handleApplicationAssetsAddSuccess,
  [ActionTypes.APPLICATION_ASSETS_REMOVE_SUCCESS]: handleApplicationAssetsRemoveSuccess,
  [ActionTypes.STORE_ASSETS_FETCH_SUCCESS]: handleStoreAssetsFetchSuccess,
  [ActionTypes.STORE_ASSET_UPLOAD_SUCCESS]: handleStoreAssetUploadSuccess,
  [ActionTypes.STORE_ASSETS_UPLOAD_SUCCESS]: handleStoreAssetsUploadSuccess,
  [ActionTypes.STORE_ASSET_REMOVE_SUCCESS]: handleStoreAssetRemoveSuccess,
  [ActionTypes.APPLICATION_PROXY_CONFIG_FETCH_SUCCESS]: handleApplicationProxyUpdate,
  [ActionTypes.APPLICATION_PROXY_CONFIG_UPDATE_SUCCESS]: handleApplicationProxyUpdate,
  [ActionTypes.APPLICATION_EMBEDDED_ACTIVITY_CONFIG_FETCH_SUCCESS]: handleApplicationEmbeddedActivityConfigUpdate,
  [ActionTypes.APPLICATION_EMBEDDED_ACTIVITY_CONFIG_UPDATE_SUCCESS]: handleApplicationEmbeddedActivityConfigUpdate,
  [ActionTypes.APPLICATION_DISCOVERABILITY_STATE_FETCH_SUCCESS]: handleApplicationDiscoverabilityStateFetchSuccess,
  [ActionTypes.APPLICATION_RESET_SECRET_SUCCESS]: handleApplicationResetSecretSuccess,
  [ActionTypes.APPLICATION_RESET_BOT_TOKEN_SUCCESS]: handleApplicationResetBotTokenSuccess,
  [ActionTypes.MANIFEST_LABELS_FETCH_SUCCESS]: handleManifestLabelsFetchSuccess,
  [ActionTypes.USER_LOGOUT]: handleUserLogout,
  [ActionTypes.ACKNOWLEDGE_ONBOARDING]: handleAcknowledgeOnboarding,
});
