export function extractId(fingerprint: string): string {
  return fingerprint.split('.')[0];
}

export function maybeExtractId(fingerprint: string | null | undefined): string | null {
  if (fingerprint == null) {
    return null;
  }

  return extractId(fingerprint);
}
