import chroma from 'chroma-js';
const RGBA_REGEX = /rgba?\((\d{1,3}), ?(\d{1,3}), ?(\d{1,3})\)?(?:, ?(\d(?:\.\d*)?)\))?/;

export type RGBArray = [number, number, number];

function pad2(s: string): string {
  return s.length === 1 ? `0${s}` : s;
}

export function hex2int(hex: string): number {
  return chroma(hex).num();
}

export function int2hex(colorInt: number): string {
  if (colorInt <= 0xffffff) {
    const r = (colorInt >> 16) & 0xff;
    const g = (colorInt >> 8) & 0xff;
    const b = colorInt & 0xff;
    return `#${pad2(r.toString(16))}${pad2(g.toString(16))}${pad2(b.toString(16))}`;
  } else {
    const r = (colorInt >> 24) & 0xff;
    const g = (colorInt >> 16) & 0xff;
    const b = (colorInt >> 8) & 0xff;
    return `#${pad2(r.toString(16))}${pad2(g.toString(16))}${pad2(b.toString(16))}`;
  }
}

export function int2hsl(
  colorInt: number,
  supportsCSSVariables = false,
  saturationFactor: number | null = null,
  alpha = 1
): string {
  let r = (colorInt >> 16) & 0xff;
  let g = (colorInt >> 8) & 0xff;
  let b = colorInt & 0xff;
  r /= 255;
  g /= 255;
  b /= 255;
  const cmin = Math.min(r, g, b);
  const cmax = Math.max(r, g, b);
  const delta = cmax - cmin;
  let h = 0;
  let s = 0;
  let l = 0;

  if (delta === 0) {
    h = 0;
  } else if (cmax === r) {
    h = ((g - b) / delta) % 6;
  } else if (cmax === g) {
    h = (b - r) / delta + 2;
  } else {
    h = (r - g) / delta + 4;
  }

  h = Math.round(h * 60);

  if (h < 0) h += 360;

  l = (cmax + cmin) / 2;
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);
  // Only web supports CSS variables
  if (supportsCSSVariables) {
    return `hsla(${h}, calc(var(--saturation-factor, 1) * ${s}%), ${l}%, ${alpha})`;
  } else if (saturationFactor != null) {
    return `hsla(${h}, ${saturationFactor * s}%, ${l}%, ${alpha})`;
  }
  return `hsla(${h}, ${s}%, ${l}%, ${alpha})`;
}

export function hex2rgb(hex: string, overrideAlpha: number | null = null): string | null {
  if (!chroma.valid(hex)) return null;
  const color = chroma(hex);
  return color.alpha(overrideAlpha ?? color.alpha()).css();
}

export function int2rgba(colorInt: number, alpha?: number): string {
  if (alpha == null) {
    alpha = ((colorInt >> 24) & 0xff) / 255;
  }

  const r = (colorInt >> 16) & 0xff;
  const g = (colorInt >> 8) & 0xff;
  const b = colorInt & 0xff;

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export function rgb2int(rgb: string): number {
  const match = rgb.match(RGBA_REGEX);
  const colorValues =
    match != null
      ? {
          red: parseInt(match[1]),
          green: parseInt(match[2]),
          blue: parseInt(match[3]),
        }
      : {red: 0, green: 0, blue: 0};

  return (colorValues.red << 16) + (colorValues.green << 8) + colorValues.blue;
}

export function int2hsv(colorInt: number): {h: number; s: number; v: number} {
  const r = ((colorInt >> 16) & 0xff) / 255;
  const g = ((colorInt >> 8) & 0xff) / 255;
  const b = (colorInt & 0xff) / 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  let h = max;
  let s = max;
  const d = max - min;
  s = max === 0 ? 0 : d / max;

  if (max === min) {
    h = 0; // achromatic
  } else {
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h *= 60;
  }

  return {h, s, v: max};
}

export function getDarkness(color: number) {
  const r = (color >> 16) & 0xff;
  const g = (color >> 8) & 0xff;
  const b = color & 0xff;
  return 1 - (0.299 * r + 0.587 * g + 0.114 * b) / 255;
}

export function isValidHex(hex: string) {
  return chroma.valid(hex);
}

export function int2rgbArray(colorInt: number): RGBArray {
  const r = (colorInt >> 16) & 0xff;
  const g = (colorInt >> 8) & 0xff;
  const b = colorInt & 0xff;

  return [r, g, b];
}

export function getLuminance(r: number, g: number, b: number) {
  var a = [r, g, b].map((v) => {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
  });
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

export function getContrast(color1: number, color2: number) {
  const rgb1 = int2rgbArray(color1);
  const rgb2 = int2rgbArray(color2);
  var lum1 = getLuminance(rgb1[0], rgb1[1], rgb1[2]);
  var lum2 = getLuminance(rgb2[0], rgb2[1], rgb2[2]);
  var brightest = Math.max(lum1, lum2);
  var darkest = Math.min(lum1, lum2);
  return (brightest + 0.05) / (darkest + 0.05);
}

export function hsv2int(h: number, s: number, v: number) {
  let r = 0;
  let g = 0;
  let b = 0;
  h /= 360;

  var i = Math.floor(h * 6);
  var f = h * 6 - i;
  var p = v * (1 - s);
  var q = v * (1 - f * s);
  var t = v * (1 - (1 - f) * s);

  switch (i % 6) {
    case 0:
      r = v;
      g = t;
      b = p;
      break;
    case 1:
      r = q;
      g = v;
      b = p;
      break;
    case 2:
      r = p;
      g = v;
      b = t;
      break;
    case 3:
      r = p;
      g = q;
      b = v;
      break;
    case 4:
      r = t;
      g = p;
      b = v;
      break;
    case 5:
      r = v;
      g = p;
      b = q;
      break;
  }
  const red = Math.round(r * 255);
  const green = Math.round(g * 255);
  const blue = Math.round(b * 255);
  return (red << 16) + (green << 8) + blue;
}
