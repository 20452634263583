/**
 * In API v8, the error response for Skema/Form errors changed. This is the updated APIError that reflects
 * such changes.
 *
 * This class supports both the old and new type of Skema errors. It is compatible with v8+
 */

/**
 * In V8, all Skema form errors should contain this error code.
 */
export const INVALID_FORM_BODY_ERROR_CODE = 50035;

interface SkemaErrorItem {
  code: string;
  message: string;
}

interface SkemaErrorMap {
  [subError: string]: SkemaError;
}

type SkemaError = SkemaErrorMap & {
  _errors?: SkemaErrorItem[];
};

export interface ResponseErrorBody {
  message: string; // Human readable error.
  code?: number; // Discord-defined error code.
  retry_after?: number; // The length of time, in seconds, the user must wait.
  errors?: SkemaError;
}

/**
 * The pre-API v8 form errors.
 */
export interface OldFormErrorBody {
  [field: string]: string[];
}

export interface ResponseError {
  status: number; // HTTP status code
  body?: ResponseErrorBody | OldFormErrorBody;
}

export type MessageOrResponse = string | ResponseError;

type FieldParameter = string | string[];

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export enum CaptchaTypes {
  HCAPTCHA = 'hcaptcha',
  RECAPTCHA = 'recaptcha',
}

interface CaptchaFields {
  captcha_key?: string;
  captcha_service?: CaptchaTypes;
  captcha_sitekey?: string;
}

interface ParsedData {
  message?: string;
  code?: number;
  retryAfter?: number;
  errors?: SkemaError;
  status?: number;
  captchaFields?: CaptchaFields;
}

function convertStringArrayToSkemaErrorItems(errors: string[]): SkemaErrorItem[] {
  return errors.map((error) => ({
    code: 'UNKNOWN',
    message: error,
  }));
}

/**
 * API v6 form errors are of the following:
 *
 * ```
 * {
 *   field1: ["error message 1", "error message 2"],
 *   field2: ["error message 1", "error message 2"],
 * }
 * ```
 */
function convertOldFormError(body: OldFormErrorBody): SkemaError {
  const error: SkemaError = {};

  for (const [field, errors] of Object.entries(body)) {
    if (field === '_misc') {
      error._errors = convertStringArrayToSkemaErrorItems(errors);
      continue;
    }

    // Get around typescript here.
    const subError: SkemaError = {};
    subError._errors = convertStringArrayToSkemaErrorItems(errors);

    error[field] = subError;
  }

  return error;
}

function parseConstructorInput(messageOrResponse: MessageOrResponse, code?: number): ParsedData {
  if (typeof messageOrResponse === 'string') {
    return {
      message: messageOrResponse,
      code,
    };
  }

  if (messageOrResponse.body == null) {
    return {
      status: messageOrResponse.status,
    };
  }

  const body = messageOrResponse.body;

  if (
    messageOrResponse.body.message != null &&
    !Array.isArray(messageOrResponse.body.message) &&
    (messageOrResponse.body.code == null || !Array.isArray(messageOrResponse.body.code))
  ) {
    return {
      message: body.message as string,
      code: body.code as number | undefined,
      retryAfter: body.retry_after as number | undefined,
      errors: body.errors as SkemaError | undefined,
      status: messageOrResponse.status,
    };
  }

  // Captcha errors have a special format that needs to be preserved for older clients
  if (body != null && 'captcha_key' in body) {
    return {
      code: -1,
      captchaFields: body,
      status: messageOrResponse.status,
      message: body['captcha_key'].length > 0 ? body['captcha_key'][0] : undefined,
    };
  }

  return {
    status: messageOrResponse.status,
    code: INVALID_FORM_BODY_ERROR_CODE,
    errors: convertOldFormError(body as OldFormErrorBody),
  };
}

export default class APIError {
  message: string;
  code: number;
  retryAfter?: number;
  errors?: SkemaError;
  status?: number;
  captchaFields: CaptchaFields;

  constructor(
    messageOrResponse: MessageOrResponse,
    code?: number,
    // Ideally all consumers will provide their own i18n string for generic error messages.
    defaultErrorMessage: string = 'An unexpected error occurred.'
  ) {
    const {
      message,
      code: parsedCode,
      retryAfter,
      errors,
      status,
      captchaFields,
    } = parseConstructorInput(messageOrResponse, code);
    this.message = message ?? defaultErrorMessage;
    this.code = parsedCode ?? -1;
    this.retryAfter = retryAfter;
    this.errors = errors;
    this.status = status;
    this.captchaFields = captchaFields ?? {};
  }

  /**
   * Returns true if we have any form field errors.
   */
  hasFieldErrors(): boolean {
    return this.errors != null && Object.keys(this.errors).length > 0;
  }

  /**
   * Get any errors for the specified field.
   * @param fields the field name, or hierarchy of field names if it is nested. Pass empty array for root errors.
   */
  getFieldErrors(fields: FieldParameter): SkemaErrorItem[] | null | undefined {
    if (typeof fields === 'string') {
      fields = [fields];
    }

    let currentErrors = this.errors;
    while (fields.length > 0 && currentErrors != null) {
      currentErrors = currentErrors[fields[0]];
      fields = fields.splice(1);
    }

    return currentErrors?._errors;
  }

  /**
   * Convienence function for fetching the first error message of the specified field.
   * See getFieldErrors for parameter details.
   */
  getFirstFieldErrorMessage(fields: FieldParameter): string | null {
    const errors = this.getFieldErrors(fields);
    if (errors == null || errors.length < 1) {
      return null;
    }

    return errors[0].message;
  }

  /**
   * Convienence function for fetching the first error message, priortizing fields.
   * Use this if you just want a single line to display.
   */
  getAnyErrorMessage(): string | null {
    return this.getAnyErrorMessageAndField()?.error ?? this.message;
  }

  getAnyErrorMessageAndField(): {fieldName: string | null; error: string} | null {
    let currentErrors: SkemaError | undefined = this.errors;
    let fieldName: string | null = null;
    while (currentErrors != null) {
      if (currentErrors._errors != null) {
        return {fieldName, error: currentErrors._errors[0].message};
      }

      fieldName = Object.keys(currentErrors)[0];
      currentErrors = currentErrors[fieldName];
    }

    return null;
  }
}
