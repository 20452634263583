// @ts-check

/**
 * NOTE(faulty): This file has been converted _back_ to regular JS (but still
 * checked by TS!) to avoid some fun resolution conflicts with `@types/node`,
 * because it's existence in our node_modules means that `global` here (which
 * technically doesn't exist in browser environments anyway) would be typed
 * with the NodeJS.Global type, when in reality it's actually going to be
 * `globalThis`, which is `Window` in the browser.
 *
 * For unclear reasons, defining this file in JS and having `index.d.ts` next
 * to it gets TS to understand (somewhat) that this should be the DOM version.
 */

import './performance-polyfill';

// Polyfilling Date.now... because safety
try {
  if (Date.now == null) {
    Date.now = () => new Date().valueOf();
  }
} catch (e) {}

/** @type {number} */
export const navigationStart = (() => {
  try {
    return global.performance.timing.navigationStart ?? global.performance.now();
  } catch (e) {}
  return Date.now();
})();

/** @type {number} */
export const timeOrigin = (() => {
  try {
    return global.performance.timeOrigin ?? navigationStart;
  } catch (e) {}
  return Date.now();
})();

if (global.performance.timing == null) {
  try {
    // @ts-expect-error the base polyfill does not include some of these more
    // specialized variables which we now depend on
    global.performance.timing = {navigationStart};
  } catch (e) {}
}

if (global.performance.timeOrigin == null) {
  try {
    // @ts-expect-error the base polyfill does not include some of these more
    // specialized variables which we now depend on
    global.performance.timeOrigin = timeOrigin;
  } catch (e) {}
}

/** @type {globalThis.Performance} */
export const performance = global.performance;
