export default {
  logFluxAction(action: any, durationMs: number): void {
    this.reportEvent({
      type: 'Flux-Dispatch',
      description: action.type,
      data: action,
      durationMs,
    });
  },

  reportEvent(data: any): void {
    getDevtools()?.reportEvent?.(data);
  },

  notifyStoreCreated(storeName: string): void {
    getDevtools()?.notifyStoreCreated?.({storeName});
  },

  notifyStoreChange(storeName: string): void {
    getDevtools()?.notifyStoreChange?.({storeName});
  },
};

function getDevtools() {
  return typeof window !== 'undefined' ? window.__DISCORD_DEVTOOLS : null;
}
