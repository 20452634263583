import Flux from '@discordapp/flux';

import Dispatcher from '@developers/Dispatcher';
import ActionTypes from '@developers/actions/ActionTypes';

import type {Action, ActionFor} from '@developers/flow/Action';
import type {Summary} from '@developers/flow/Server';

let summaries: Record<string, Summary[]> = {};
let isFetchingSummaries: Record<string, boolean> = {};

const handleSummariesFetchStart = ({guildId}: ActionFor<'SUMMARIES_FETCH_START'>) => {
  summaries[guildId] = [];
  isFetchingSummaries[guildId] = true;
};

const handleSummariesFetchSuccess = ({guildId}: ActionFor<'SUMMARIES_FETCH_SUCCESS'>) => {
  isFetchingSummaries[guildId] = false;
};

const handleSummariesFetchFailure = ({guildId}: ActionFor<'SUMMARIES_FETCH_FAILURE'>) => {
  isFetchingSummaries[guildId] = false;
};

const handleSummariesDataFetched = ({guildId, fetchedSummaries}: ActionFor<'SUMMARIES_DATA_FETCHED'>) => {
  const newSummaries: Summary[] = Object.entries(fetchedSummaries.summaries).flatMap(([channelId, summaries]) => {
    return summaries.map((summary) => {
      return {
        id: summary.id,
        topic: summary.topic,
        summ_short: summary.summ_short,
        message_ids: summary.message_ids,
        people: summary.people,
        channelId: channelId,
      };
    });
  });
  summaries[guildId] = [...summaries[guildId], ...newSummaries];
};

function handleUserLogout() {
  summaries = {};
  isFetchingSummaries = {};
}

class SummariesStore extends Flux.Store<Action> {
  static displayName = 'SummariesStore';

  getSummaries(guildId: string | null): Summary[] {
    if (guildId == null || !(guildId in summaries)) {
      return [];
    }
    return summaries[guildId];
  }

  getIsFetchingSummaries(guildId: string | null): boolean {
    if (guildId == null || !(guildId in isFetchingSummaries)) {
      return false;
    }
    return isFetchingSummaries[guildId];
  }
}

export default new SummariesStore(Dispatcher, {
  [ActionTypes.SUMMARIES_FETCH_START]: handleSummariesFetchStart,
  [ActionTypes.SUMMARIES_DATA_FETCHED]: handleSummariesDataFetched,
  [ActionTypes.SUMMARIES_FETCH_SUCCESS]: handleSummariesFetchSuccess,
  [ActionTypes.SUMMARIES_FETCH_FAILURE]: handleSummariesFetchFailure,
  [ActionTypes.USER_LOGOUT]: handleUserLogout,
});
