import * as React from 'react';
import classNames from 'classnames';
import {Helpers} from 'react-scroll';
import {getClass} from '@discordapp/common/utils/StylesheetUtils';

import Flex from './Flex';
import Spinner from './Spinner';

import styles from './Button.module.css';

export const ButtonColor = {
  TRANSPARENT: 'transparent',
  BLACK: 'black',
  BRAND: 'brand',
  WHITE: 'white',
  GREEN: 'green',
  RED: 'red',
  PRIMARY: 'primary',
  LINK: 'link',
};

export const ButtonLook = {
  FILLED: 'filled',
  OUTLINED: 'outlined',
  LINK: 'link',
  BLANK: 'blank',
};

export const ButtonHeight = {
  NONE: '',
  AUTO: 'auto',
  SHORT: 'short',
  MEDIUM: 'medium',
  TALL: 'tall',
};

export const ButtonStyles = {
  DISABLED: styles.disabled,
};

export interface ButtonProps {
  children?: React.ReactNode;
  className?: string;
  color?: string;
  disabled?: boolean;
  duration?: string;
  grow?: boolean;
  height: string;
  href?: string;
  download?: string;
  look: string;
  paired?: boolean;
  smooth?: string | boolean;
  submitting?: boolean;
  to?: string;
  tag?: string;
  type?: string;
  onClick?: (a: React.SyntheticEvent<HTMLButtonElement>) => void;
  onMouseDown?: (a: React.SyntheticEvent<HTMLButtonElement>) => void;
  rel?: string;
  target?: string;
  wrapContent?: boolean;
}

export default class Button extends React.PureComponent<ButtonProps> {
  static defaultProps = {
    color: ButtonColor.BRAND,
    grow: false,
    height: ButtonHeight.TALL,
    look: ButtonLook.FILLED,
    submitting: false,
    paired: false,
    type: 'button',
    wrapContent: true,
  };

  static Color = ButtonColor;
  static Height = ButtonHeight;
  static Look = ButtonLook;
  static Styles = ButtonStyles;

  getButtonProps() {
    const {
      className,
      color,
      disabled,
      download,
      duration,
      grow,
      height,
      href,
      look,
      paired,
      onClick,
      onMouseDown,
      smooth,
      submitting,
      to,
      type,
      rel,
      target,
    } = this.props;
    const forceDisabled = submitting || disabled;

    const sharedButtonProps = {
      className: classNames(
        styles.button,
        getClass(styles, look, color),
        getClass(styles, 'buttonHeight', height),
        {[styles.grow]: grow, [styles.disabled]: forceDisabled, [styles.unpaired]: !paired},
        className
      ),
      disabled: forceDisabled,
      href: forceDisabled ? null : href,
      download: forceDisabled ? null : download,
      onClick: forceDisabled ? null : onClick,
      onMouseDown: forceDisabled ? null : onMouseDown,
      type,
      rel,
      target,
    };

    if (to != null) {
      return {
        ...sharedButtonProps,
        duration,
        smooth,
        to,
      };
    }

    return sharedButtonProps;
  }

  renderButtonContent() {
    const {children, submitting, wrapContent} = this.props;
    if (!wrapContent) return children;
    const spinner = submitting ? <Spinner className={styles.spinner} type={Spinner.Type.PULSING_ELLIPSIS} /> : null;

    return (
      <Flex align={Flex.Align.CENTER} justify={Flex.Justify.CENTER} className={styles.contentWrapper}>
        {spinner}
        <span className={classNames(styles.buttonContent, {[styles.buttonContentHidden]: submitting})}>{children}</span>
      </Flex>
    );
  }

  render() {
    const {href, to, tag} = this.props;
    const buttonProps = this.getButtonProps();

    let Tag;
    if (tag != null) {
      Tag = tag;
    } else {
      Tag = href != null ? 'a' : 'button';
    }

    if (to != null) {
      Tag = Helpers.Scroll(Tag);
    }

    return <Tag {...buttonProps}>{this.renderButtonContent()}</Tag>;
  }
}
