import {
  defaultRules,
  inlineRegex,
  Capture,
  Parser,
  State,
  UnTypedASTNode,
  ASTNode,
  SingleASTNode,
  ReactOutput,
} from 'simple-markdown';

const {newline, paragraph, url, link, strong, u, br, em, image, text} = defaultRules;

export default {
  newline,
  paragraph,
  url,
  link: {
    ...link,

    parse(capture: Capture, parse: Parser, state: State) {
      const node = link.parse(capture, parse, state);
      node.context = state.context;
      return node;
    },
  },
  strong,
  u,
  br,
  em,
  image,
  hook: {
    order: text.order,
    match: inlineRegex(/^\$\[(.*?)\]\((\w+)\)/),

    parse(capture: Capture, parse: Parser, state: State): UnTypedASTNode {
      const {context} = state;
      return {
        render: context[capture[2]],
        content: parse(capture[1], state),
      };
    },

    react(node: SingleASTNode, output: ReactOutput, state: State) {
      return node.render(output(node.content, state), state.key);
    },
  },
  noparse: {
    order: text.order,
    match: inlineRegex(/^!!(\d+?)!!/),

    parse(capture: Capture, parse: Parser, {unsafeContext}: State): ASTNode {
      let content = unsafeContext[capture[1]];
      if (typeof content !== 'string') {
        content = content != null ? content.toString() : '';
      }
      return {
        type: 'text',
        content,
      };
    },

    react(node: SingleASTNode): ReactOutput {
      return node.content;
    },
  },

  text,
};
