const STRIP_DIACRITIC_RE = /[\u0300-\u036f]/g;
const SURROGATE_PAIR_RE = /[\uD800-\uDBFF][\uDC00-\uDFFF]/;

export function upperCaseFirstChar(str?: string | null): string {
  if (str == null) {
    return '';
  }
  return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
}

export const truncateText = (text: string, maxTextLength: number, ellipsis = '…') => {
  if (text == null || maxTextLength == null) {
    return '';
  }

  if (text.length > maxTextLength) {
    // Fix for handling surrogate pairs in the text
    // without this, the text could be truncated in the middle of a surrogate pair resulting in an invalid string
    // - using `[...text]` to convert the string to an array of characters that handles surrogate pairs correctly
    // - we don't want to use `[...text]` for all strings because it's slower than `text.substring`
    const truncatedText = SURROGATE_PAIR_RE.test(text)
      ? [...text].slice(0, maxTextLength - ellipsis.length).join('')
      : text.substring(0, maxTextLength - ellipsis.length);
    return `${truncatedText.replace(/[\s.]+$/, '')}${ellipsis}`;
  }

  return text;
};

export function getAcronym(name: string | null): string {
  return name != null
    ? name
        .replace(/'s /g, ' ')
        .replace(/\w+/g, (match) => match[0])
        .replace(/\s/g, '')
    : '';
}

export function cssValueToNumber(value: string): number {
  const num = parseInt(value, 10);
  return isNaN(num) ? 0 : num;
}

// hackety-hack for IE not supporting normalize
export const stripDiacritics =
  String.prototype.normalize == null
    ? (input: string): string => input
    : (input: string): string => input.normalize('NFD').replace(STRIP_DIACRITIC_RE, '').normalize('NFC');

// Unicode TR39 section 4, don't mess with the NFD!
function fullNormalize(input: string): string {
  const CONFUSABLES = require('./confusables.json') as Record<string, string>;
  let output = '';
  [...input.normalize('NFD')].forEach((char) => {
    output += CONFUSABLES[char] ?? char;
  });
  return output.normalize('NFD').toLocaleLowerCase();
}

/**
 * Performs TR39's `skeleton` operation. The result of this function is *never* suitable for display or storage.
 */
export const normalize = String.prototype.normalize == null ? (input: string): string => input : fullNormalize;
