import * as React from 'react';

import styles from './404.module.css';

export default function FourOhFour() {
  return (
    <div className={styles.glitchWrapper}>
      <div className={styles.glitch} data-text="404">
        404
      </div>
    </div>
  );
}
