import bigInt from 'big-integer';
import {Buffer} from 'buffer';

function getLowBits(bigint: bigInt.BigInteger) {
  return bigint.mod(0x100000000).toJSNumber() | 0; // lowest 32 bits, signed
}

function getHighBits(bigint: bigInt.BigInteger) {
  return bigint.shiftRight(32).toJSNumber() | 0;
}

export default class IdGenerator {
  _randomPrefix = Math.floor(Math.random() * 0x100000000) | 0;
  _creationTime = bigInt(Date.now());
  _sequenceNumber = 0;

  generate(id: string): string {
    const userIdLong = bigInt(id);
    const seqNum = this._sequenceNumber++ | 0;

    // eslint-disable-next-line node/no-deprecated-api
    const uuid = new Buffer(24);
    // @ts-expect-error
    uuid.writeInt32LE(getLowBits(userIdLong), 0, true);
    // @ts-expect-error
    uuid.writeInt32LE(getHighBits(userIdLong), 4, true);
    // @ts-expect-error
    uuid.writeInt32LE(this._randomPrefix, 8, true);
    // @ts-expect-error
    uuid.writeInt32LE(getLowBits(this._creationTime), 12, true);
    // @ts-expect-error
    uuid.writeInt32LE(getHighBits(this._creationTime), 16, true);
    // @ts-expect-error
    uuid.writeInt32LE(seqNum, 20, true);
    return uuid.toString('base64');
  }
}
