import {getDevicePixelRatio} from './PlatformUtils';

import {MEDIA_PROXY_SIZES} from '@developers/Constants';

const MAX_IMAGE_SIZE = 1024;

// Find closest size bigger or equal to value
export function getBestMediaProxySize(value: number): number {
  return MEDIA_PROXY_SIZES.find((size) => value <= size) ?? MEDIA_PROXY_SIZES[MEDIA_PROXY_SIZES.length - 1];
}

export function calculateSize(size: number) {
  const ratio = getDevicePixelRatio();
  size = ratio > 1 ? size * Math.ceil(ratio) : size;

  // Round size to nearest power of 2.
  size--;
  size |= size >> 1;
  size |= size >> 2;
  size |= size >> 4;
  size |= size >> 8;
  size |= size >> 16;
  size++;

  return Math.min(size, MAX_IMAGE_SIZE);
}
