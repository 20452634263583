import {ErrorTypes} from './ErrorUtils';

import {
  APIErrorCodes,
  APIFormErrorCodes,
  MAXIMUM_ALLOWED_COMPANIES,
  MAXIMUM_ALLOWED_TEAMS,
} from '@developers/Constants';
import {Messages} from '@developers/i18n';
import type {FormattedMessage} from '@discordapp/i18n';

type ErrorMappings = Record<
  keyof typeof ErrorTypes,
  Record<Values<typeof APIErrorCodes>, string | null | FormattedMessage<Record<string, unknown>>>
>;

// ErrorTypes besides COMMON should only be used for handling error codes with multiple potential
// use cases that need section-specific messages.
export const getErrorCodeMappings = (): ErrorMappings => {
  return {
    [ErrorTypes.COMMON]: {
      [APIErrorCodes.INVALID_APPLICATION_NAME]: Messages.SkuManagement.DUPLICATE_APPLICATION_NAME,
      [APIErrorCodes.TOO_MANY_APPLICATION_GAME_SKUS]: Messages.SkuManagement.TOO_MANY_APPLICATION_GAME_SKUS,
      [APIErrorCodes.TOO_MANY_TEAMS]: Messages.Teams.TOO_MANY_TEAMS.format({maxTeams: MAXIMUM_ALLOWED_TEAMS}),
      [APIErrorCodes.TEAM_MEMBER_ALREADY_EXISTS]: Messages.Teams.TEAM_MEMBER_ALREADY_EXISTS,
      [APIErrorCodes.INVALID_MFA_CODE]: Messages.Common.INVALID_MFA_CODE,
      [APIErrorCodes.TEAM_MEMBERS_MUST_BE_VERIFIED]: Messages.Teams.TEAM_MEMBERS_MUST_BE_VERIFIED,
      [APIErrorCodes.TEAM_OWNERSHIP_REQUIRED]: Messages.Teams.TEAM_OWNER_ONLY,
      [APIErrorCodes.TEAM_INVITATION_ALREADY_ACCEPTED]: Messages.Teams.INVITATION_ALREADY_ACCEPTED,
      [APIErrorCodes.UNKNOWN_TEAM]: Messages.Teams.UNKNOWN_TEAM_ERROR,
      [APIErrorCodes.UNKNOWN_TEAM_MEMBER]: Messages.Teams.UNKNOWN_TEAM_MEMBER_ERROR,
      [APIErrorCodes.TOO_MANY_COMPANIES]: Messages.Metadata.MAX_COMPANIES_REACHED.format({
        maxCompanies: MAXIMUM_ALLOWED_COMPANIES,
      }),
      [APIErrorCodes.MFA_REQUIRED]: Messages.Common.TWO_FACTOR_AUTHENTICATION_REQUIRED,
      [APIErrorCodes.TERMS_OF_SERVICE_REQUIRED]: Messages.Common.APPLICATION_VERIFICATION_INVALID_TOS_EMPTY,
      [APIErrorCodes.PRIVACY_POLICY_REQUIRED]: Messages.Common.APPLICATION_VERIFICATION_INVALID_PRIVACY_POLICY_EMPTY,
    },
    [ErrorTypes.TEAMS]: {
      [APIErrorCodes.INVALID_TOKEN]: Messages.Teams.INVALID_INVITE_TOKEN,
      [APIErrorCodes.UNKNOWN_USER]: Messages.Teams.UNKNOWN_USER,
      [APIErrorCodes.INVALID_ACCOUNT_TYPE]: Messages.Teams.INVALID_ACCOUNT_TYPE,
    },
    [ErrorTypes.FORMS]: {
      [APIFormErrorCodes.APPLICATION_VERIFICATION_DISABLED]:
        Messages.Common.APPLICATION_VERIFICATION_DISABLED_HOLIDAY_BREAK,
    },
  };
};
