import * as React from 'react';
import {Text} from '@discordapp/design/components/Text/Text.web';

import styles from './FieldErrorList.module.css';

export const FieldErrorListItem = ({field, message}: {field?: string; message: string | null | undefined}) => (
  <li className={styles.listItem}>
    {field != null ? (
      <span>
        <Text variant="code" tag="span">
          {field}
        </Text>
        {': '}
      </span>
    ) : null}
    {message}
  </li>
);

export const FieldErrorList = ({children, header}: {children: React.ReactNode; header: string}) => (
  <ul>
    <li className={styles.validationErrorListItem}>{header}</li>
    {children}
  </ul>
);
