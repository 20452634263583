import * as React from 'react';
import {CSATSurveyResponses} from '@discordapp/common/shared-constants/CSATSurveyResponses';
import {useStateFromStores} from '@discordapp/flux';
import Storage from '@discordapp/storage';

import {trackDevPortalCSATSurveyResponse} from '@developers/actions/AnalyticsActions';
import UserStore from '@developers/stores/UserStore';
import Tooltip from '@developers/uikit/Tooltip';
import TrackingAnalyticsUtils from '@developers/utils/TrackingAnalyticsUtils';
import SidebarCta, {
  SidebarCtaActionTypes,
  SidebarCtaActions,
  SidebarCtaAction,
  SidebarCtaContent,
  SidebarCtaHeading,
  SidebarCtaCloseButton,
} from './SidebarCta';

import {AnalyticsEvents, SIDEBAR_SURVEY_CONTENT_PROMPT_VISIBILITY_KEY} from '@developers/Constants';
import {Messages} from '@developers/i18n';

const CTA_ANALYTICS_NAME = 'sidebar_survey_content_cta';
const CTA_VISIBILITY_KEY_TTL_DAYS = 90;

interface SidebarSurveyContentCtaStorageValue {
  expiry: number;
}

export function shouldShowSurveyContentCta() {
  const storageObject = Storage.get(SIDEBAR_SURVEY_CONTENT_PROMPT_VISIBILITY_KEY) as
    | SidebarSurveyContentCtaStorageValue
    | undefined;

  if (storageObject === undefined) {
    return true;
  }

  return storageObject.expiry < new Date().getTime();
}

export default function SurveyContentCta() {
  const [shouldDisplayPrompt, setShouldDisplayPrompt] = React.useState(shouldShowSurveyContentCta());

  const user = useStateFromStores([UserStore], () => UserStore.user);
  React.useEffect(() => {
    if (shouldDisplayPrompt && user != null) {
      TrackingAnalyticsUtils.track(AnalyticsEvents.CTA_VIEWED, {
        user_id: user.id,
        cta_name: CTA_ANALYTICS_NAME,
      });
    }
  }, [shouldDisplayPrompt, user]);

  if (!shouldDisplayPrompt || user == null) {
    return null;
  }

  const handleCtaClick = (userResponse: Values<typeof CSATSurveyResponses>) => {
    if (user != null) {
      trackDevPortalCSATSurveyResponse({
        user_id: user.id,
        csat_response: userResponse,
      });
    }

    dismissPrompt();
  };

  const dismissPrompt = () => {
    const timestamp = new Date();
    Storage.set(SIDEBAR_SURVEY_CONTENT_PROMPT_VISIBILITY_KEY, {
      expiry: timestamp.setDate(timestamp.getDate() + CTA_VISIBILITY_KEY_TTL_DAYS),
    });
    setShouldDisplayPrompt(false);
  };

  const messageOptions = Messages.DeveloperExperienceSurvey;

  const options = [
    [
      messageOptions.DEVELOPER_EXPERIENCE_VERY_UNSATISFIED,
      messageOptions.DEVELOPER_EXPERIENCE_VERY_UNSATISFIED_OPTION,
      CSATSurveyResponses.VERY_UNSATISFIED,
    ],
    [
      messageOptions.DEVELOPER_EXPERIENCE_UNSATISFIED,
      messageOptions.DEVELOPER_EXPERIENCE_UNSATISFIED_OPTION,
      CSATSurveyResponses.UNSATISFIED,
    ],
    [
      messageOptions.DEVELOPER_EXPERIENCE_NEUTRAL,
      messageOptions.DEVELOPER_EXPERIENCE_NEUTRAL_OPTION,
      CSATSurveyResponses.NEUTRAL,
    ],
    [
      messageOptions.DEVELOPER_EXPERIENCE_SATISFIED,
      messageOptions.DEVELOPER_EXPERIENCE_SATISFIED_OPTION,
      CSATSurveyResponses.SATISFIED,
    ],
    [
      messageOptions.DEVELOPER_EXPERIENCE_VERY_SATISFIED,
      messageOptions.DEVELOPER_EXPERIENCE_VERY_SATISFIED_OPTION,
      CSATSurveyResponses.VERY_SATISFIED,
    ],
  ];

  return (
    <SidebarCta>
      <SidebarCtaHeading>{Messages.DeveloperExperienceSurvey.DEVELOPER_EXPERIENCE_SURVEY_HEADING}</SidebarCtaHeading>
      <SidebarCtaCloseButton onClick={dismissPrompt} />
      <SidebarCtaContent>{Messages.DeveloperExperienceSurvey.DEVELOPER_EXPERIENCE_CSAT_SURVEY_PITCH}</SidebarCtaContent>
      <SidebarCtaActions>
        {options.map(([tooltipMessage, message, response], index) => (
          <Tooltip key={index} text={tooltipMessage} color={Tooltip.Colors.GREY}>
            <div>
              <SidebarCtaAction
                onClick={() => {
                  handleCtaClick(response);
                }}
                type={SidebarCtaActionTypes.TERTIARY}>
                {message}
              </SidebarCtaAction>
            </div>
          </Tooltip>
        ))}
      </SidebarCtaActions>
    </SidebarCta>
  );
}
