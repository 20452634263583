import TokenManager from '@discordapp/common/lib/TokenManager';
import HTTPUtils, {setRequestPatch} from '@discordapp/http-utils';

import Dispatcher from '@developers/Dispatcher';
import ActionTypes from '@developers/actions/ActionTypes';
import APIStore from '@developers/stores/APIStore';
import TrackingAnalyticsUtils from '@developers/utils/TrackingAnalyticsUtils';

import {Endpoints, APIErrorCodes} from '@developers/Constants';

setRequestPatch({
  prepareRequest(req) {
    if (req.url[0] === '/') {
      const fingerprint = APIStore.fingerprint;
      const token = TokenManager.getToken();
      const path = req.url;
      req.url = HTTPUtils.getAPIBaseURL() + req.url;
      const superProperties = TrackingAnalyticsUtils.getSuperPropertiesBase64();
      if (superProperties != null) {
        // SMS send endpoint has special Smite rules
        //  and requires X-Super-Properties.
        // We are leaving X-Track for other endpoints for compatibility reasons.
        const headerName = path === Endpoints.LOGIN_SMS_SEND ? 'X-Super-Properties' : 'X-Track';
        req.set(headerName, superProperties);
      }

      // @ts-expect-error: `req.header` exists but is not in type def
      if (token != null && token !== '' && req.header['Authorization'] == null) {
        req.set('Authorization', token);
      }

      if (fingerprint != null && fingerprint !== '') {
        req.set('X-Fingerprint', fingerprint);
      }

      // Attach the path onto the response for intercepting later.
      req.on('response', (res) => {
        // @ts-expect-error: `req.path` exists but is not in type def
        res.path = path;
      });
    }
  },
  interceptResponse(res, retry, cancel) {
    if (res.statusCode === 401 && res.body?.code === APIErrorCodes.MFA_REQUIRED && res.body?.mfa) {
      Dispatcher.dispatch({
        type: ActionTypes.SHOW_MFA_MODAL,
        retry,
        cancel,
        request: res.body.mfa,
      });
      return true;
    }

    // TODO: handle captcha
    return false;
  },
});
