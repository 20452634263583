// not converting to typescript: we're moving off this

import * as Animated from 'animated/lib';
import Easing from 'animated/lib/Easing';
import random from 'lodash/random';
import CSSPropertyOperations from 'only-react-css-property-operations';

function mapTransform(t) {
  const k = Object.keys(t)[0];
  return `${k}(${t[k]})`;
}

const RGBA_RE = /rgba\(([\d.]+), ([\d.]+), ([\d.]+), ([\d.]+)\)/;

/**
 * Animated currently does a poor job of mapping RGBA.
 * This is a hacky fix that I would like to improve later.
 */
function cleanColor(color) {
  const match = color.match(RGBA_RE);
  if (match != null) {
    color = `rgba(${match[1] | 0}, ${match[2] | 0}, ${match[3] | 0}, ${match[4]})`;
  }
  return color;
}

function mapStyle(style) {
  if (style) {
    if (style.transform) {
      style.transform = style.WebkitTransform = style.MozTransform = style.transform.map(mapTransform).join(' ');
    }
    if (style.color) {
      style.color = cleanColor(style.color);
    }
    if (style.backgroundColor) {
      style.backgroundColor = cleanColor(style.backgroundColor);
    }
  }
  return style;
}

function ApplyAnimatedValues(instance, props, component) {
  if (instance.setNativeProps) {
    instance.setNativeProps(props);
  } else if (instance.nodeType && instance.setAttribute !== undefined) {
    CSSPropertyOperations.setValueForStyles(instance, mapStyle(props.style), component._reactInternalInstance);
  } else {
    return false;
  }
}

Animated.inject.ApplyAnimatedValues(ApplyAnimatedValues, (style) => style);

function accelerate(style) {
  style.transform = style.transform || [];
  style.transform.push({translateZ: 0});
  return style;
}

function getRandom(original, min, max) {
  return min !== undefined && max != undefined ? random(min, max) : original;
}

/**
 * Animate a single property easily.
 * Pass it the values you would to a normal animation, plus some extra ones.
 * @param {Animated.Value} animValue: The Animated.Value you want to animate.
 * @param {Object} options: Options to define how the animation will behave.
 *   `loop` will automagically loop the animation until `shouldLoop` evaluates to false.
 *   `shouldLoop` is a function that returns if the animation should continue looping or not.
 *   `reverse` will start the original animation, and when it is finished, animate to the original value.
 *   `invert` will start the original animation, and when it is finished, animate to the negative of toValue.
 *   `type` will define what kind of animation to use. 'spring' (default), or 'timing'.
 *   `callback` will be called when the animation is finished or in between each loop.
 *     If you specifiy this with a loop, you must call the function passed in to continue the loop.
 */
function animate(animValue, options) {
  const {
    toValueMin,
    toValueMax,
    tension = 0,
    friction = 0,
    loop,
    reverse,
    invert,
    callback,
    type = 'spring',
    shouldLoop,
    durationMin,
    durationMax,
    ...restOptions
  } = options;

  const originalValue = animValue._value;

  const duration = getRandom(options.duration, durationMin, durationMax);
  const toValue = getRandom(options.toValue, toValueMin, toValueMax);

  const first = Animated[type](animValue, {
    ...restOptions,
    toValue,
    tension,
    friction,
    duration,
  });

  let animation = first;
  let second;
  if (reverse || invert) {
    const duration2 = getRandom(options.duration, durationMin, durationMax);

    second = Animated[type](animValue, {
      ...restOptions,
      toValue: reverse ? originalValue : -toValue,
      tension,
      friction,
      duration: duration2,
    });

    animation = Animated.sequence([first, second]);
  }

  if (loop) {
    animation.start(() => {
      if (!shouldLoop || (shouldLoop && shouldLoop())) {
        if (callback) {
          callback(animate.bind(null, animValue, options));
        } else {
          animate(animValue, options);
        }
      }
    });
  } else {
    animation.start(callback);
  }
}

function interpolate(anim, ...outputRange) {
  return anim.interpolate({
    inputRange: [0, 1],
    outputRange: outputRange,
  });
}

const Extrapolate = {
  CLAMP: 'clamp',
};

export default {
  ...Animated,
  Easing,
  accelerate,
  animate,
  interpolate,
  Extrapolate,
  div: Animated.createAnimatedComponent('div'),
  span: Animated.createAnimatedComponent('span'),
  img: Animated.createAnimatedComponent('img'),
  a: Animated.createAnimatedComponent('a'),
  form: Animated.createAnimatedComponent('form'),
  ul: Animated.createAnimatedComponent('ul'),
  li: Animated.createAnimatedComponent('li'),
  g: Animated.createAnimatedComponent('g'),
  use: Animated.createAnimatedComponent('use'),
  path: Animated.createAnimatedComponent('path'),
  section: Animated.createAnimatedComponent('section'),
  video: Animated.createAnimatedComponent('video'),
};
