import * as React from 'react';
import classNames from 'classnames';
import {upperCaseFirstChar} from '@discordapp/common/utils/StringUtils';
import {getClass} from '@discordapp/common/utils/StylesheetUtils';

import {StatusTypes} from '@developers/Constants';
import styles from './Avatar.module.css';

export const AvatarSizes = {
  PROFILE: 'profile',
  XXLARGE: 'xxlarge',
  POPOUT: 'popout',
  XLARGE: 'xlarge',
  LARGE: 'large',
  SMALL: 'small',
  XSMALL: 'xsmall',
};

export const AvatarStatuses = StatusTypes;

interface Props {
  size?: string;
  src?: string | null;
  className?: string | null;
  innerClassName?: string | null;
  statusClass?: string | null;
  status?: string | null;
  style?: React.CSSProperties;
}

const Status = ({status, size, className}: Props) => (
  <div
    className={classNames(
      className,
      styles[`status${upperCaseFirstChar(status)}`],
      size === AvatarSizes.POPOUT || size === AvatarSizes.PROFILE ? getClass(styles, 'status', status, 'profile') : null
    )}
  />
);

const Avatar = ({
  size = AvatarSizes.LARGE,
  src,
  className,
  innerClassName,
  status,
  statusClass,
  style,
  ...props
}: Props) => {
  let maskClass;
  if (status != null) {
    maskClass = size === AvatarSizes.POPOUT || size === AvatarSizes.PROFILE ? styles.maskProfile : styles.mask;
  }
  return (
    <div className={classNames(styles.avatar, styles[size], className)} {...props}>
      <div
        className={classNames(innerClassName, styles.image, maskClass)}
        style={{
          ...style,
          backgroundImage: src != null ? `url('${src}')` : 'none',
        }}
      />
      {status != null ? <Status status={status} size={size} className={statusClass} /> : null}
    </div>
  );
};

Avatar.Sizes = AvatarSizes;
Avatar.Statuses = AvatarStatuses;

export default Avatar;
