import invariant from 'invariant';
import AppStartPerformance from '@discordapp/common/AppStartPerformance';
import DevtoolsExtension from '@discordapp/common/DevtoolsExtension';

import ChangeListeners from './ChangeListeners';
import Emitter from './Emitter';

import type {Dispatcher, DispatchToken, ActionHandler, ActionHandlers} from './Dispatcher';
import type {ActionBase} from './FluxTypes';

type AnyStore = Store<any>;
const stores: AnyStore[] = [];
let initialized: boolean = false;
let initializeResolve: (() => void) | undefined | null;
const initializePromise: Promise<void> = new Promise((resolve) => {
  initializeResolve = () => {
    resolve();
    initializeResolve = null;
  };
});

/**
 * Creates a function that debounces to the end of the execution stack.
 */
function debounce(timeout: number, func: () => void): () => void {
  let id: any /* number | null */ = null;
  if (timeout === 0) {
    return function () {
      clearImmediate(id);
      id = setImmediate(func);
    };
  } else {
    return function () {
      if (id != null) return;
      id = setTimeout(() => {
        try {
          func();
        } finally {
          id = null;
        }
      }, timeout);
    };
  }
}

export default class Store<Action extends Readonly<ActionBase>> {
  _changeCallbacks = new ChangeListeners();
  _reactChangeCallbacks = new ChangeListeners();
  _dispatchToken: DispatchToken;
  _dispatcher: Dispatcher<Action>;
  _mustEmitChanges: ActionHandler<Action> | undefined;
  _isInitialized: boolean = false;
  __getLocalVars?: () => object;
  static displayName: string;

  /**
   * Initialize all stores.
   */
  static initialize() {
    initialized = true;
    stores.forEach((store) => store.initializeIfNeeded());

    if (initializeResolve != null) {
      initializeResolve();
    }
  }

  static initialized = initializePromise;
  static destroy() {
    stores.length = 0;
    Emitter.destroy();
  }

  static getAll() {
    return stores;
  }

  constructor(
    dispatcher: any extends Action ? never : Dispatcher<Action>,
    actionHandler?: ActionHandlers<Action>,
    band?: number
  ) {
    this._dispatcher = dispatcher;
    this._dispatchToken = this._dispatcher.createToken();

    this.registerActionHandlers(actionHandler ?? {}, band);

    stores.push(this);

    if (process.env.NODE_ENV === 'development') {
      if (typeof window !== 'undefined') {
        // Allows console inspecting store instances, e.g. __DEBUG_STORES.UserStore.getCurrentUser()
        // Use ts-ignore to make this work for both webpack 4 + webpack 5
        /* eslint-disable */
        // @ts-ignore
        window.__DEBUG_STORES = window.__DEBUG_STORES || {};
        // @ts-ignore
        window.__DEBUG_STORES[this.getName()] = this;
        /* eslint-enable */
        DevtoolsExtension.notifyStoreCreated(this.getName());
      }
    }

    if (initialized) {
      this.initializeIfNeeded();
    }
  }

  private registerActionHandlers(actionHandler: ActionHandlers<Action>, band?: number) {
    this._dispatcher.register(
      this.getName(),
      actionHandler,
      (action) => {
        // This function is called when the action handler notes that the store did change,
        // and we must emit change callbacks.
        if (this._changeCallbacks.hasAny() || this._reactChangeCallbacks.hasAny()) {
          Emitter.markChanged(this);
          if (Emitter.getIsPaused() && this._mustEmitChanges != null && this._mustEmitChanges(action)) {
            Emitter.resume(false /* scheduleEmitChanges */);
          }
        } else {
          if (process.env.NODE_ENV === 'development') {
            DevtoolsExtension.notifyStoreChange(this.getName());
          }
        }
      },
      band,
      this._dispatchToken
    );
  }

  getName(): string {
    // @ts-expect-error
    return this.constructor.displayName ?? this.constructor.name;
  }

  /**
   * Calls intialize if it has not yet been run
   *
   */
  initializeIfNeeded() {
    if (!this._isInitialized) {
      const start = Date.now();

      this.initialize();
      this._isInitialized = true;

      const duration = Date.now() - start;
      if (duration > 5) {
        AppStartPerformance.mark('🦥', this.getName() + '.initialize()', duration);
      }
    }
  }

  /**
   * Binds a function to an action handler.
   *
   * @abstract
   */
  initialize() {}

  /**
   * Sync with stores and invoke a handler when all Stores processed.
   *
   * This is kinda not great because it executes on the next tick, but the debounce helps.
   */
  syncWith(stores: AnyStore[], callback: () => boolean | undefined | void, timeout?: number) {
    this.waitFor(...stores);
    let lastSentinel = 0;
    let wrapper = () => {
      if (lastSentinel === Emitter.getChangeSentinel()) return;
      lastSentinel = Emitter.getChangeSentinel();
      if (callback() !== false) {
        this.emitChange();
      }
    };
    wrapper = debounce(timeout ?? 0, wrapper);
    stores.forEach((store) => store.addChangeListener(wrapper));
  }

  /**
   * Adds a Store's to a list for wait for during a dispatch.
   */
  waitFor(...stores: AnyStore[]) {
    const dependentTokens = stores.map((store, i) => {
      invariant(store != null, `Store.waitFor(...) called with null Store at index ${i} for store ${this.getName()}`);

      // in Jest tests, mock stores won't have this property define, which is insane, but this if statement exists
      // to handle that
      if (store._dispatcher != null) {
        invariant(store._dispatcher === this._dispatcher, 'Stores belong to two separate dispatchers.');
        return store.getDispatchToken();
      } else {
        return null;
      }
    });

    this._dispatcher.addDependencies(
      this.getDispatchToken(),
      dependentTokens.filter((token) => token != null) as string[]
    );
  }

  /**
   * Emit a change event.
   */
  emitChange() {
    Emitter.markChanged(this);
  }

  addChangeListener = this._changeCallbacks.add;
  addConditionalChangeListener = this._changeCallbacks.addConditional;
  removeChangeListener = this._changeCallbacks.remove;
  addReactChangeListener = this._reactChangeCallbacks.add;
  removeReactChangeListener = this._reactChangeCallbacks.remove;

  getDispatchToken(): string {
    return this._dispatchToken;
  }

  /**
   * Call this if the store must always emit changes - and if changed, must resume the store emit stream.
   */
  mustEmitChanges(actionHandler: ActionHandler<Action> = () => true) {
    this._mustEmitChanges = actionHandler;
  }
}
