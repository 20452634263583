/*
  THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY
  If you need to add or edit existing variables, check out
  discord_common/js/packages/tokens/definitions, then run
  `clyde gen design tokens`
*/
const ThemeTypes = Object.freeze({
  DARK: 'dark',
  LIGHT: 'light',
  MIDNIGHT: 'midnight',
  DARKER: 'darker',
});

export type ThemeValues = (typeof ThemeTypes)[keyof typeof ThemeTypes];

export const ThemeExtends = new Map<ThemeValues, ThemeValues>([
  [ThemeTypes.MIDNIGHT, ThemeTypes.DARKER],
  [ThemeTypes.DARKER, ThemeTypes.DARK],
]);

export default ThemeTypes;
