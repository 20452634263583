import * as React from 'react';
import * as Sentry from '@sentry/react';

import GenericError from './errors/GenericError';

import styles from './ErrorBoundary.module.css';

interface Props {
  children: React.ReactNode;
}

const ErrorBoundary = ({children}: Props) => {
  const fallbackRender = ({error}: Parameters<Sentry.FallbackRender>[0]) => (
    <div className={styles.wrapper}>
      <GenericError errorMessage={error.message} />
    </div>
  );

  return (
    <Sentry.ErrorBoundary fallback={fallbackRender}>
      <div className={styles.wrapper}>{children}</div>
    </Sentry.ErrorBoundary>
  );
};

export default ErrorBoundary;
