/**
 * THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY
 * To add new icons to the system, or to update existing icons, run `clyde gen design icons`.
 */

import * as React from 'react';
import tokens from '@discordapp/tokens/web';

import addDefaultIconProps from '../addDefaultIconProps';

import type {SingleColorIconProps} from '../IconTypes';

/**
 * ![MenuIcon](data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAPElEQVR4nGNgGAWDAvgGRBz1C4j4T1XsH34YbgGIQ20LfAMiDg1oqI0COgPf0WQ6CgY/8B1NpqOAgQIAAFF+/3Yx1D4DAAAAAElFTkSuQmCC)
 */
export const MenuIcon = ({
  width = 24,
  height = 24,
  color = tokens.colors.INTERACTIVE_NORMAL,
  colorClass = '',
  ...props
}: SingleColorIconProps) => {
  return (
    <svg
      {...addDefaultIconProps(props)}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 24 24">
      <path
        fill={typeof color === 'string' ? color : color.css}
        d="M1 5a1 1 0 0 1 1-1h20a1 1 0 1 1 0 2H2a1 1 0 0 1-1-1ZM1 12a1 1 0 0 1 1-1h20a1 1 0 1 1 0 2H2a1 1 0 0 1-1-1ZM2 18a1 1 0 1 0 0 2h20a1 1 0 1 0 0-2H2Z"
        className={colorClass}
      />
    </svg>
  );
};
