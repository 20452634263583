import Emitter from './Emitter';
import PersistedStore, {DeviceSettingsStore, OfflineCacheStore} from './PersistedStore';
import Store from './Store';
import connectStores from './connectStores';

export default {
  Emitter,
  Store,
  PersistedStore,
  DeviceSettingsStore,
  OfflineCacheStore,

  connectStores,

  initialize() {
    Store.initialize();
  },

  get initialized(): Promise<void> {
    return Store.initialized;
  },

  destroy() {
    // will call Store.destroy() too
    PersistedStore.destroy();
  },
};
