import * as React from 'react';
import classNames from 'classnames';
import {FocusRingScope} from 'react-focus-rings';

import {
  usePaddingFixes,
  getScrollbarSpecs,
  getMergedOrientationStyles,
  getAnimatedScrollHelpers,
  useUncachedScrollerState,
  useScrollSpring,
} from './scroller-utilities';

import type {AnimatedScrollHelperState, ScrollerComponentBaseProps, ScrollerState} from './scroller-utilities';

// Your basic Scroller component.  It's flexible in that it can have state
// queried and scroll positions set as needed.  It also includes the basic
// padding fixes as needed.

export interface AdvancedScrollerRef extends AnimatedScrollHelperState {
  getScrollerNode: () => HTMLDivElement | null;
  getScrollerState: () => ScrollerState;
}

export interface AdvancedScrollerProps extends ScrollerComponentBaseProps {
  orientation?: 'vertical' | 'horizontal';
  fade?: boolean;
  customTheme?: boolean;
  children?: React.ReactNode;
}

export function createAdvancedScroller(
  scrollbarClassName: string,
  fadeClassName: string,
  customThemeClassName: string
) {
  const specs = getScrollbarSpecs(scrollbarClassName);
  return React.forwardRef(function AdvancedScroller(
    {
      children,
      className,
      dir = 'ltr',
      orientation = 'vertical',
      fade = false,
      customTheme = false,
      paddingFix = true,
      style,
      ...props
    }: AdvancedScrollerProps,
    ref: React.Ref<AdvancedScrollerRef>
  ) {
    const {scrollerRef, getScrollerState} = useUncachedScrollerState();
    const spring = useScrollSpring(scrollerRef, orientation);
    React.useImperativeHandle<AdvancedScrollerRef, AdvancedScrollerRef>(
      ref,
      () => ({
        getScrollerNode() {
          return scrollerRef.current;
        },
        getScrollerState,
        ...getAnimatedScrollHelpers(scrollerRef, getScrollerState, spring, orientation),
      }),
      [scrollerRef, getScrollerState, orientation, spring]
    );
    const paddingNode = usePaddingFixes({paddingFix, orientation, dir, className, scrollerRef, specs});
    return (
      <div
        ref={scrollerRef}
        className={classNames(className, {
          [scrollbarClassName]: true,
          [fadeClassName]: fade,
          [customThemeClassName]: customTheme,
        })}
        style={getMergedOrientationStyles(style, orientation)}
        dir={dir}
        {...props}>
        <FocusRingScope containerRef={scrollerRef}>
          {children}
          {paddingNode}
        </FocusRingScope>
      </div>
    );
  });
}
