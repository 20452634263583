import * as React from 'react';
import {useStateFromStores} from '@discordapp/flux';

import * as TeamActions from '@developers/actions/TeamActions';
import {useShouldShowActiveDeveloperCta} from '@developers/components/active_developer/useShouldShowActiveDeveloperCta';
import TeamStore from '@developers/stores/TeamStore';
import ActiveDeveloperProgramCta from './ActiveDeveloperProgramCta';
import SurveyContentCta, {shouldShowSurveyContentCta} from './SurveyContentCta';

export default function ShowCta() {
  const hasFetchedTeams = useStateFromStores([TeamStore], () => TeamStore.hasFetchedTeams());
  const shouldShowActiveDeveloperCta = useShouldShowActiveDeveloperCta();

  React.useEffect(() => {
    if (!hasFetchedTeams) {
      TeamActions.fetchTeams();
    }
  }, [hasFetchedTeams]);

  if (shouldShowSurveyContentCta()) {
    return <SurveyContentCta />;
  } else if (shouldShowActiveDeveloperCta) {
    // Non-dismissable, should be last
    return <ActiveDeveloperProgramCta />;
  } else {
    return null;
  }
}
