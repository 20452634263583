/** Check if a value is an Element.
 *
 * Supports cross-window checks. If `type` is provided, returns whether the
 * element is that specific type. */
export function isElement<T extends Node = Element>(value: any, type?: {new (): T}): value is T {
  if (value == null) {
    return false;
  }

  const renderWindow = value?.ownerDocument?.defaultView as Window;
  if (renderWindow == null) {
    // eslint-disable-next-line no-console
    console.warn('Unable to determine render window for element', value);
    return false;
  }

  // Hack: TS doesn't understand subclasses of Node are guaranteed to exist on the window object
  const typeName = (type?.name ?? 'Element') as keyof Window;
  const typeConstructor = renderWindow[typeName];
  if (typeConstructor == null) {
    // eslint-disable-next-line no-console
    console.warn(`Unable to find element constructor "${typeName}" in`, renderWindow);
    return false;
  }

  return value instanceof typeConstructor;
}
