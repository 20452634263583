import * as React from 'react';
import {useNavigate} from 'react-router-dom';
import {useStateFromStores} from '@discordapp/flux';

import * as ApplicationActions from '@developers/actions/ApplicationActions';
import AvatarSelectOption, {SelectOptionWithAvatar} from '@developers/components/common/AvatarSelectOption';
import Form from '@developers/components/common/Form';
import FormLabel from '@developers/components/common/FormLabel';
import Modal, {
  ModalHeader,
  ModalContent,
  ModalFooter,
  ModalCancel,
  ModalAlert,
  ModalAlertTypes,
} from '@developers/components/common/Modal';
import TeamStore from '@developers/stores/TeamStore';
import UserStore from '@developers/stores/UserStore';
import Button from '@developers/uikit/Button';
import Checkbox from '@developers/uikit/Checkbox';
import Grid, {GridSection, GridChild} from '@developers/uikit/Grid';
import Select from '@developers/uikit/Select';
import TextInput from '@developers/uikit/TextInput';
import {getUserAvatarURL} from '@developers/utils/AvatarUtils';
import RouterUtils from '@developers/utils/RouterUtils';
import {getTeamSelectOptions} from '@developers/utils/TeamUtils';

import {Routes, Links} from '@developers/Constants';
import {Messages} from '@developers/i18n';
import type {ErrorWithStatusCode} from '@developers/flow/Client';
import type {Application, AppOwnerId} from '@developers/flow/Server';
import styles from './CreateApplicationModal.module.css';

interface Props {
  onRequestClose: () => void;
}

interface FormData {
  name?: string;
}

export default function CreateApplicationModal({onRequestClose}: Props) {
  const navigate = useNavigate();
  const user = useStateFromStores([UserStore], () => UserStore.user);
  const teams = useStateFromStores([TeamStore], () => TeamStore.teamsMap);

  const [hasAcceptedTermsOfService, setHasAcceptedTermsOfService] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [selectedOwnerId, setSelectedOwnerId] = React.useState<AppOwnerId | null | undefined>(null);
  const [error, setError] = React.useState<React.ReactNode | null | undefined>(null);

  const handleCreateNewApp = (formData: FormData): void => {
    if (user == null) return;

    if (formData == null || formData.name == null || formData.name === '') {
      setError(Messages.MyApps.ERROR_MUST_PROVIDE_NAME);
      return;
    }

    if (!hasAcceptedTermsOfService) {
      setError(
        Messages.MyApps.ERROR_MUST_ACCEPT_TERMS_OF_SERVICE.format({
          link: RouterUtils.prependBasename(Routes.DOCS_POLICIES_AND_AGREEMENTS_DEVELOPER_TERMS_OF_SERVICE),
        })
      );
      return;
    }

    const appData: {
      name: string;
      team_id?: string | null;
    } = {name: formData.name};

    if (selectedOwnerId !== user.id) {
      appData.team_id = selectedOwnerId;
    }

    setIsSubmitting(true);
    ApplicationActions.createApplication(appData)
      .then((application: Application): void => {
        setIsSubmitting(false);
        setError(null);
        navigate(Routes.APPLICATION_INFORMATION(application.id));
      })
      .catch((error: ErrorWithStatusCode): void => {
        setIsSubmitting(false);
        setError(error.message);
      });
  };

  const handleTeamSelect = <T extends string>(option: SelectOptionWithAvatar<T>): void => {
    setSelectedOwnerId(option.value);
  };

  const renderFormStatus = (): React.ReactNode => {
    if (error == null) return null;
    return <ModalAlert type={ModalAlertTypes.ERROR}>{error}</ModalAlert>;
  };

  const renderTeamOption = <T,>(option: SelectOptionWithAvatar<T>): React.ReactNode => {
    return <AvatarSelectOption option={option} />;
  };

  const renderTeamValue = <T,>(option: SelectOptionWithAvatar<T>): React.ReactNode => {
    return <AvatarSelectOption option={option} isValue />;
  };

  const getTeamSelectOptionsHandler = (): Array<SelectOptionWithAvatar<string>> => {
    if (user == null) {
      return [];
    }
    return getTeamSelectOptions(teams, [
      {label: Messages.MyApps.PERSONAL, value: user.id, avatar: getUserAvatarURL(user)},
    ]);
  };

  const handleHasAcceptedTermsOfServiceChange = (hasAccepted: boolean) => {
    setHasAcceptedTermsOfService(hasAccepted);
  };

  const handleRequestClose = (): void => {
    if (!isSubmitting) {
      onRequestClose();
      setHasAcceptedTermsOfService(false);
      setIsSubmitting(false);
      setSelectedOwnerId(null);
      setError(null);
    }
  };

  const renderTeamSelect = (): React.ReactNode => {
    if (teams.size === 0 || user == null) return null;
    return (
      <GridChild columnSpread={12}>
        <FormLabel>{Messages.MyApps.TEAM}</FormLabel>
        <Select
          clearable={false}
          searchable={false}
          value={selectedOwnerId == null ? user.id : selectedOwnerId}
          valueRenderer={renderTeamValue}
          optionRenderer={renderTeamOption}
          options={getTeamSelectOptionsHandler()}
          onChange={handleTeamSelect}
        />
      </GridChild>
    );
  };

  return (
    <Modal onRequestClose={handleRequestClose} className={styles.overflowOverride}>
      <Form onSubmit={handleCreateNewApp}>
        <ModalContent>
          <ModalHeader>{Messages.MyApps.CREATE_APPLICATION_MODAL_HEADING}</ModalHeader>
          {renderFormStatus()}
          <Grid>
            <GridSection>
              <GridChild columnSpread={12}>
                <FormLabel isRequired>{Messages.Forms.NAME}</FormLabel>
                <TextInput name="name" defaultValue="" />
              </GridChild>
              {renderTeamSelect()}
              <GridChild columnSpread={12} className={styles.legalLinksSection}>
                <Checkbox
                  value={hasAcceptedTermsOfService}
                  onChange={handleHasAcceptedTermsOfServiceChange}
                  type={Checkbox.Types.INVERTED}>
                  {Messages.Information.SUBHEADING_LINK.format({
                    developerPolicyLink: Links.DEVELOPER_POLICY,
                    termsOfServiceLink: Links.TERMS_OF_SERVICE,
                  })}
                </Checkbox>
              </GridChild>
            </GridSection>
          </Grid>
        </ModalContent>
        <ModalFooter>
          <ModalCancel onClick={handleRequestClose}>{Messages.Actions.CANCEL}</ModalCancel>
          <Button className={styles.createButton} type="submit" submitting={isSubmitting}>
            {Messages.Actions.CREATE}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}
