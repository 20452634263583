import type {StandardAnalyticsSchemaNameMap, ImpressionNames, NetworkActionNames} from './AnalyticsSchema';

// This will strongly map metadata fields against a particular registered event
export type TypedEventProperties<EventName extends ImpressionNames | NetworkActionNames> =
  StandardAnalyticsSchemaNameMap[EventName];

export interface StandardAnalyticsLocation {
  page?: ImpressionNames | null;
  section?: ImpressionNames | null;
  object?: null;
  objectType?: null;
  promotionId?: null;
}

export enum ImpressionGroups {
  CHANNEL_ADD_FLOW = 'channel_add_flow',
  CONTACT_SYNC_FLOW = 'contact_sync_flow',
  DIRECTORY_GUILD_ADD_FLOW = 'directory_guild_add_flow',
  GUILD_ADD_FLOW = 'guild_add_flow',
  GUILD_ADD_NUF = 'guild_add_nuf',
  USER_VERIFICATION_MODAL_FLOW = 'user_verification_modal_flow',
  GUILD_TRANSFER_OWNERSHIP_FLOW = 'guild_transfer_ownership_flow',
  ROLE_ADD_FLOW = 'role_add_flow',
  USER_ACCOUNT_EMAIL_CHANGE_FLOW = 'user_account_email_change_flow',
  USER_LOGIN_FLOW = 'user_login_flow',
  USER_REGISTRATION_FLOW = 'user_registration_flow',
  ACTIVITY_SHELF_FLOW = 'activity_shelf_flow',
  POMELO_FLOW = 'pomelo_flow',
  APPEAL_INGESTION = 'appeal_ingestion',
}

export enum ImpressionTypes {
  PAGE = 'page',
  MODAL = 'modal',
  POPOUT = 'popout',
  MENU = 'menu',
  PANE = 'pane', // e.g., settings
  VIEW = 'view', // e.g., in-page transition
  HALFSHEET = 'halfsheet',
}

export interface Impression<T extends ImpressionNames = ImpressionNames> {
  type?: ImpressionTypes;
  name?: T;
  properties?: TypedEventProperties<T>;
  sequenceId?: string;
  _stackContext?: any;
}
