import * as React from 'react';
import classNames from 'classnames';

import styles from './SidebarSection.module.css';

const SidebarSection = ({
  children,
  className,
  isFlush = false,
  hasBorder = false,
  isSubNavigation = false,
}: {
  className?: string;
  children: React.ReactNode;
  isFlush?: boolean;
  hasBorder?: boolean;
  isSubNavigation?: boolean;
}) => (
  <div
    className={classNames(styles.section, className, {
      [styles.flush]: isFlush,
      [styles.hasBorder]: hasBorder,
      [styles.subNavigation]: isSubNavigation,
    })}>
    {children}
  </div>
);

export default SidebarSection;
