import * as React from 'react';

export const BlockInteractionsContext = React.createContext<boolean>(false);

interface BlockInteractionsProps {
  children: React.ReactNode;
}

export default function BlockInteractions({children}: BlockInteractionsProps) {
  return (
    <BlockInteractionsContext.Provider
      // eslint-disable-next-line react/jsx-boolean-value
      value={true}>
      {children}
    </BlockInteractionsContext.Provider>
  );
}
