import * as React from 'react';
import classNames from 'classnames';
import {NavLink, NavLinkProps} from 'react-router-dom';

export interface TrackedLinkProps extends NavLinkProps {
  from?: string;
  pathname?: string;
  className?: string;
  activeClassName?: string;
}

export default function TrackedLink({to, children, className, activeClassName, ...props}: TrackedLinkProps) {
  if (to == null) {
    throw new Error(`Missing 'to' route prop in TrackLink component`);
  }

  const pathname = typeof to === 'string' ? to : to.pathname;
  const hash = typeof to === 'string' ? '' : to.hash;
  const search = typeof to === 'string' ? '' : to.search;

  return (
    <NavLink
      {...props}
      to={{pathname, hash, search}}
      className={({isActive}) => classNames(className, isActive ? activeClassName : undefined)}>
      {children}
    </NavLink>
  );
}
