/* eslint-disable no-console */

import {checkLogForPII} from './LoggerPIIRestrictedObjects';

export type LogLevel = 'info' | 'warn' | 'error' | 'trace' | 'log' | 'debug' | 'file-only';

let log: (name: string, level: LogLevel, message: string, ...args: any[]) => void = () => {};
export function setLogFn(fn: typeof log) {
  log = fn;
}

export default class Logger {
  name: string;

  constructor(name = 'default') {
    this.name = name;
  }

  logDangerously = (message: string, ...args: any[]) => {
    log(this.name, 'log', message, ...args);
  };

  log = (message: string, ...args: any[]) => {
    checkLogForPII(message, ...args);
    log(this.name, 'log', message, ...args);
  };

  verboseDangerously = (message: string, ...args: any[]) => {
    log(this.name, 'debug', message, ...args);
  };

  verbose = (message: string, ...args: any[]) => {
    checkLogForPII(message, ...args);
    log(this.name, 'debug', message, ...args);
  };

  info = (message: string, ...args: any[]) => {
    checkLogForPII(message, ...args);
    log(this.name, 'info', message, ...args);
  };

  warn = (message: string, ...args: any[]) => {
    checkLogForPII(message, ...args);
    log(this.name, 'warn', message, ...args);
  };

  error = (message: string, ...args: any[]) => {
    checkLogForPII(message, ...args);
    log(this.name, 'error', message, ...args);
  };

  trace = (message: string, ...args: any[]) => {
    log(this.name, 'trace', message, ...args);
  };

  time = <T,>(label: string, fn: () => T): T => {
    const start = Date.now();
    const result = fn();
    this.log(label, Date.now() - start);
    return result;
  };

  /** Logs the message to our internal files/tools, just doesn't print to the console. */
  fileOnly = (message: string, ...args: any[]) => {
    log(this.name, 'file-only', message, ...args);
  };
}
