import * as React from 'react';
import {
  Rules,
  ParserRule,
  ReactOutputRule,
  sanitizeUrl,
  defaultRules,
  SingleASTNode,
  ReactOutput,
  State,
} from 'simple-markdown';

export default function updateRules(rules: {[type: string]: ParserRule & ReactOutputRule}): Rules<any> {
  rules.heading = defaultRules.heading;
  rules.lheading = defaultRules.lheading;
  rules.list = defaultRules.list;
  rules.paragraph = {
    ...rules.paragraph,
    react: function Paragraph(node: SingleASTNode, output: ReactOutput, state: State) {
      return <p key={state.key}>{output(node.content, state)}</p>;
    },
  };
  rules.link = {
    ...rules.link,
    react: function Link(node: SingleASTNode, output: ReactOutput, state: State) {
      const props: {
        onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
        onContextMenu?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
        href?: string;
        target?: string;
      } = {};

      if (node.context != null) {
        const handlers = node.context[node.target];

        if (handlers && handlers.onClick) {
          props.onClick = handlers.onClick;
          props.onContextMenu = handlers.onContextMenu;
        } else {
          props.onClick = handlers;
        }
      }

      if (props.onClick == null) {
        props.href = sanitizeUrl(node.target);
        props.target = '_blank';
      }

      return (
        // eslint-disable-next-line @discordapp/discord/use-a11y-component
        <a key={state.key} title={node.title} {...props} rel="noreferrer">
          {output(node.content, state)}
        </a>
      );
    },
  };
  return rules;
}
