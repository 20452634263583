import * as React from 'react';
import classNames from 'classnames';

import styles from './PageHeading.module.css';

interface Props {
  action?: React.ReactNode;
  children: React.ReactNode;
  isFlush?: boolean;
}

const PageHeading = ({action, children, isFlush = false}: Props) => {
  return (
    <h2 className={classNames(styles.heading, {[styles.flush]: isFlush})}>
      <div className={styles.contentPrimary}>{children}</div>
      {action == null ? null : <div className={styles.contentSecondary}>{action}</div>}
    </h2>
  );
};

export default PageHeading;
