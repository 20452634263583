import {TeamMemberRoles} from '@discordapp/common/shared-constants/TeamMemberRoles';

import TeamMemberStore from '@developers/stores/TeamMemberStore';
import TeamStore from '@developers/stores/TeamStore';

export function teamMemberCan(teamId: string, userId: string, role: TeamMemberRoles, requireOwner = false) {
  const ranking = [TeamMemberRoles.ADMIN, TeamMemberRoles.DEVELOPER, TeamMemberRoles.READ_ONLY];

  if (teamId === userId) return true;

  const team = TeamStore.getTeam(teamId);
  if (team == null) return false;

  if (userId === team.owner_user_id) {
    return true;
  }

  if (requireOwner) return false;

  const member = TeamMemberStore.getTeamMembersByTeam(teamId)?.get(userId);
  if (member == null) return false;

  return ranking.indexOf(member.role) <= ranking.indexOf(role);
}
