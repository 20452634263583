import * as React from 'react';
import BigFlagUtils from '@discordapp/common/utils/BigFlagUtils';
import {useStateFromStoresArray} from '@discordapp/flux';

import UserStore from '@developers/stores/UserStore';
import useCanViewAllCommunityGuilds from './useCanViewAllCommunityGuilds';

import {GuildFeatures, Permissions} from '@developers/Constants';
import type {Guild} from '@developers/flow/Server';

const computeAnalyticsAccess = (
  guild?: Guild | null,
  overridePerms: boolean = false
): {canViewGuildInsights: boolean; canViewMonetizationInsights: boolean} => {
  if (guild == null) {
    return {canViewGuildInsights: false, canViewMonetizationInsights: false};
  }

  const guildPermissions = BigFlagUtils.deserialize(guild.permissions);
  return {
    canViewGuildInsights: BigFlagUtils.has(guildPermissions, Permissions.VIEW_GUILD_ANALYTICS) || overridePerms,
    canViewMonetizationInsights:
      guild.features.includes(GuildFeatures.ROLE_SUBSCRIPTIONS_ENABLED) &&
      (BigFlagUtils.has(guildPermissions, Permissions.VIEW_CREATOR_MONETIZATION_ANALYTICS) || overridePerms),
  };
};

export const useGuildAnalyticsAccess = (guild?: Guild | null) => {
  const overridePerms = useCanViewAllCommunityGuilds();
  return React.useMemo(() => computeAnalyticsAccess(guild, overridePerms), [guild, overridePerms]);
};

const useGuildAnalyticsGuilds = () => {
  const guilds = useStateFromStoresArray([UserStore], () => UserStore.guilds ?? []);
  const overridePerms = useCanViewAllCommunityGuilds();

  return React.useMemo(
    (): Guild[] =>
      guilds.filter((guild) => {
        const {canViewGuildInsights, canViewMonetizationInsights} = computeAnalyticsAccess(guild, overridePerms);
        return (
          guild.features.includes(GuildFeatures.COMMUNITY) && (canViewGuildInsights || canViewMonetizationInsights)
        );
      }),
    [guilds, overridePerms]
  );
};

export default useGuildAnalyticsGuilds;
