import * as React from 'react';
import classNames from 'classnames';
import {FocusRing} from 'react-focus-rings';
import {MenuIcon} from '@discordapp/design/components/Icon/generated/MenuIcon.web';

import styles from './HamburgerButton.module.css';

interface Props {
  className?: string;
  onClick?: () => void;
}

const HamburgerButton = ({className, ...props}: Props) => {
  return (
    <FocusRing>
      <button {...props} type="button" className={classNames(className, styles.button)}>
        <MenuIcon className={styles.icon} color="currentColor" />
      </button>
    </FocusRing>
  );
};

export default HamburgerButton;
