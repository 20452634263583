import Emitter from './Emitter';

import type Store from './Store';

// A convenience wrapper around listening to a bunch of stores, calling a change callback,
// and then removing the change listener.
export default class BatchedStoreListener {
  stores: Array<Store<any>>;
  changeCallback: () => void;
  storeVersionHandled: number | undefined;

  constructor(stores: Array<Store<any>>, changeCallback: () => void) {
    this.stores = stores;
    this.changeCallback = changeCallback;
  }

  // Adds the changeCallback to the provided stores change listeners. This should generally be called
  // during the `componentDidMount` lifecycle of a react component.
  attach(displayName: string) {
    const {stores} = this;
    stores.forEach((store, i) => {
      if (store == null) {
        throw new Error(
          `${displayName} tried to load a non-existent store. Either it isn't defined or there is a circular dependency. Loaded ${i} stores before error.`
        );
      }
      store.addReactChangeListener(this.handleStoreChange);
    });
  }

  handleStoreChange = () => {
    const storeChangeSentinel = Emitter.getChangeSentinel();
    if (this.storeVersionHandled === storeChangeSentinel) {
      // We've already fired changed for this batch store emit, return early.
      return;
    }
    this.changeCallback();
    this.storeVersionHandled = storeChangeSentinel;
  };

  // Does the exact inverse of `attach`. This must be called during `componentDidUnmount` lifecycle
  // of a react component, or the change listeners will leak!!
  detach() {
    const {stores} = this;
    stores.forEach((store) => store.removeReactChangeListener(this.handleStoreChange));
  }
}
