interface SessionStorageType {
  get<T>(key: string, defaultValue?: T): T | undefined | null;
  set(key: string, value: any): void;
  remove(key: string): void;
  clear(): void;
}

let Storage: SessionStorageType;
if (__MOBILE__) {
  // just hand asyncStorage, no sense of sessionStorage in IOS nor is it meaningful
  Storage = require('./native/Storage').impl;
} else {
  Storage = require('./web/SessionStorage').impl;
}

export default Storage;
