import * as React from 'react';

import {getChildMapping, mergeChildMappings} from './TransitionChildMapping';

import type {TransitionWillLifecycleKey, TransitionDidLifecycleKey} from './TransitionChild';
import type {ChildMapping} from './TransitionChildMapping';

interface IntrinsicTransitionGroupProps<T extends keyof JSX.IntrinsicElements = 'span'> {
  component?: T | null;
}

interface ComponentTransitionGroupProps<T extends React.ElementType> {
  component: T;
}

interface TransitionGroupDefaultProps {
  childFactory?: ((child: React.ElementType<any>) => React.ElementType<any>) | null | undefined;
  transitionAppear?: boolean;
  transitionLeave?: boolean;
  transitionEnter?: boolean;
}

type TransitionGroupProps<T extends keyof JSX.IntrinsicElements = 'span', V extends React.ElementType = any> = (
  | (IntrinsicTransitionGroupProps<T> & JSX.IntrinsicElements[T])
  | (ComponentTransitionGroupProps<V> & {
      children?: React.ReactNode;
      [prop: string]: any;
    })
) &
  TransitionGroupDefaultProps;

interface TransitionGroupState {
  children: ChildMapping;
  firstRender: boolean;
}

export default class TransitionGroup<
  C extends
    | React.ForwardRefExoticComponent<any>
    | {new (props: any): React.Component<any>}
    | ((props: any, context?: any) => React.ReactElement | null)
    | keyof JSX.IntrinsicElements = any
> extends React.Component<TransitionGroupProps, TransitionGroupState> {
  static defaultProps = {
    component: 'span',
    transitionAppear: true,
    transitionLeave: true,
    transitionEnter: true,
    childFactory: null,
  };

  static getDerivedStateFromProps(
    nextProps: TransitionGroupProps,
    {children: prevChildMapping, firstRender}: TransitionGroupState
  ) {
    const nextChildMapping = getChildMapping(nextProps.children);
    return {
      children: firstRender ? nextChildMapping : mergeChildMappings(prevChildMapping, nextChildMapping),
      firstRender: false,
    };
  }

  _currentlyTransitioningKeys: Set<string>;
  _keysToEnter: string[];
  _keysToLeave: string[];
  _isMounted: boolean;
  _keyChildMapping: {[key: string]: React.ElementRef<C>} = {};

  constructor(props: TransitionGroupProps) {
    super(props);

    this.state = {
      children: getChildMapping(props.children),
      firstRender: true,
    };

    this._currentlyTransitioningKeys = new Set();
    this._keysToEnter = [];
    this._keysToLeave = [];
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    const {children} = this.state;
    if (this.props.transitionAppear) {
      for (const key in children) {
        if (children[key]) {
          this.performAppear(key);
        }
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this._keyChildMapping = {};

    // @ts-expect-error
    // This is needed to prevent memory leaks.
    this.state.children = {};
  }

  componentDidUpdate(prevProps: TransitionGroupProps, prevState: TransitionGroupState) {
    if (prevProps !== this.props) {
      const nextChildMapping = getChildMapping(this.props.children);
      const prevChildMapping = prevState.children;

      if (this.props.transitionEnter) {
        this._enqueueTransitions(nextChildMapping, prevChildMapping, this._keysToEnter);
      } else if (this._keysToEnter.length) {
        this._keysToEnter = [];
      }

      if (this.props.transitionLeave) {
        this._enqueueTransitions(prevChildMapping, nextChildMapping, this._keysToLeave);
      } else {
        const keysToDelete: string[] = [];
        this._enqueueTransitions(prevChildMapping, nextChildMapping, keysToDelete);
        const children = mergeChildMappings(prevChildMapping, nextChildMapping);
        for (let i = 0, l = keysToDelete.length; i < l; i++) {
          delete children[keysToDelete[i]];
        }
        if (this._isMounted) {
          // to avoid calling setState on an unmounted component
          this.setState({
            children,
          });
        }
        if (this._keysToLeave.length) {
          this._keysToLeave = [];
        }
      }
    }

    if (this._keysToEnter.length) {
      const keysToEnter = this._keysToEnter;
      this._keysToEnter = [];
      keysToEnter.forEach(this.performEnter, this);
    }

    if (this._keysToLeave.length) {
      const keysToLeave = this._keysToLeave;
      this._keysToLeave = [];
      keysToLeave.forEach(this.performLeave, this);
    }
  }

  _enqueueTransitions(a: ChildMapping, b: ChildMapping, keys: string[]) {
    for (const key in a) {
      const bHas = b && b.hasOwnProperty(key);
      if (a[key] && !bHas && !this._currentlyTransitioningKeys.has(key)) {
        keys.push(key);
      }
    }
  }

  _perform(key: string, will: TransitionWillLifecycleKey, did: TransitionDidLifecycleKey, remove: boolean = false) {
    this._currentlyTransitioningKeys.add(key);

    const callback = () => this._handleDonePerform(key, did, remove);

    const component = this._keyChildMapping[key];
    // @ts-expect-error component type is 'unknown'
    if (component != null && component[will] != null) {
      // @ts-expect-error component type is 'unknown'
      component[will](callback);
    } else {
      callback();
    }
  }

  _handleDonePerform(key: string, did: TransitionDidLifecycleKey, remove: boolean = false) {
    const component = this._keyChildMapping[key];
    // @ts-expect-error component type is 'unknown'
    if (component != null && component[did] != null) {
      // @ts-expect-error component type is 'unknown'
      component[did]();
    }

    this._currentlyTransitioningKeys.delete(key);

    const currentChildMapping = getChildMapping(this.props.children);

    if (remove) {
      // Child was re-added during transition.
      if (currentChildMapping != null && currentChildMapping.hasOwnProperty(key)) {
        this.performEnter(key);
      } else {
        this.setState(({children}) => {
          // eslint-disable-next-line no-unused-vars
          const {[key]: _, ...newChildren} = children;
          return {children: newChildren};
        });
      }
    } else {
      // Child was removed during transition.
      if (currentChildMapping == null || !currentChildMapping.hasOwnProperty(key)) {
        this.performLeave(key);
      }
    }
  }

  performAppear(key: string) {
    this._perform(key, 'componentWillAppear', 'componentDidAppear');
  }

  performEnter(key: string) {
    this._perform(key, 'componentWillEnter', 'componentDidEnter');
  }

  performLeave(key: string) {
    this._perform(key, 'componentWillLeave', 'componentDidLeave', true);
  }

  addChildRef = (key: string, ref: React.ElementRef<C>) => {
    this._keyChildMapping[key] = ref;
  };

  render(): React.ReactNode {
    const {childFactory, component} = this.props;
    const {children} = this.state;
    const childrenToRender = [];
    for (const key in children) {
      const child = children[key];
      if (child != null && React.isValidElement(child)) {
        childrenToRender.push(
          // @ts-expect-error we're guarding against errors with the isValidElement check above
          React.cloneElement(childFactory == null ? child : childFactory(child), {
            ref: (ref: React.ElementRef<C>) => this.addChildRef(key, ref),
            key,
          })
        );
      }
    }

    const props = {...this.props};
    Object.keys(TransitionGroup.defaultProps).forEach((key) => delete props[key as keyof typeof props]);
    return React.createElement(component, props, childrenToRender);
  }
}
