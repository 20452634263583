import {analyticsTrackingStoreMaker, AnalyticsActionHandlers} from '@discordapp/analytics-utils';

import Dispatcher from '@developers/Dispatcher';
import ActionTypes from '@developers/actions/ActionTypes';
import APIStore from './APIStore';

import {Endpoints} from '@developers/Constants';
import type {ActionFor} from '@developers/flow/Action';

const AnalyticsTrackingStore = analyticsTrackingStoreMaker({
  dispatcher: Dispatcher,
  actionHandler: {
    [ActionTypes.USER_FETCH_SUCCESS]: (action: ActionFor<'USER_FETCH_SUCCESS'>) =>
      AnalyticsActionHandlers.handleConnectionOpen(action),
    [ActionTypes.USER_LOGOUT]: () => AnalyticsActionHandlers.handleConnectionClosed(),
    [ActionTypes.FINGERPRINT_SET]: () => AnalyticsActionHandlers.handleFingerprint(),
    [ActionTypes.TRACK]: (action: ActionFor<'TRACK'>) => AnalyticsActionHandlers.handleTrack(action),
  },
  TRACKING_URL: Endpoints.TRACK,
  waitFor: [APIStore],
  getFingerprint: () => APIStore.fingerprint,
  drainTimeoutOverride: 500,
});

export default AnalyticsTrackingStore;
