import {useLayoutEffect} from 'react';
import {flushSync} from 'react-dom';

export type OnResizeUpdateCallback = (entry: ResizeObserverEntry, key: string) => unknown;
export type ResizeObserverCallback = (entry: ResizeObserverEntry) => unknown;

export declare class ResizeObserverInterface {
  observe(target: Element, options?: {box?: 'content-box' | 'border-box'}): void;
  unobserve(target: Element): void;
}

export interface ResizeObserverSubscriptionProps {
  ref: React.RefObject<HTMLElement>;
  onUpdate: OnResizeUpdateCallback;
  resizeObserver: ResizeObserverInterface;
  listenerMap: Map<Element, ResizeObserverCallback>;
  key: string;
}

const OBSERVE_OPTIONS = Object.freeze({
  box: 'border-box',
} as const);

export default function useResizeObserverSubscription({
  ref,
  onUpdate,
  resizeObserver,
  listenerMap,
  key,
}: ResizeObserverSubscriptionProps) {
  useLayoutEffect(() => {
    const handleUpdate: ResizeObserverCallback = (entry) => {
      flushSync(() => {
        onUpdate(entry, key);
      });
    };
    const {current} = ref;
    if (current != null) {
      listenerMap.set(current, handleUpdate);
      resizeObserver.observe(current, OBSERVE_OPTIONS);
    }
    return () => {
      if (current != null) {
        resizeObserver.unobserve(current);
        listenerMap.delete(current);
      }
    };
  }, [onUpdate, resizeObserver, ref, listenerMap, key]);
}
