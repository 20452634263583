import * as React from 'react';
import classNames from 'classnames';
import {Text} from '@discordapp/design/components/Text/Text.web';

import styles from './PageSubheading.module.css';

interface Props {
  children: React.ReactNode;
  isFlush?: boolean;
  className?: string | null;
}

const PageSubheading = ({children, className, isFlush = false}: Props) => (
  <Text
    variant="text-lg/normal"
    className={classNames(styles.subheading, className, {[styles.flush]: isFlush})}
    color="text-secondary"
    tag="p">
    {children}
  </Text>
);

export default PageSubheading;
