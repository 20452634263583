import * as React from 'react';

import Flex from '@developers/uikit/Flex';
import Spinner from '@developers/uikit/Spinner';

import styles from './PageLoadingIndicator.module.css';

const PageLoadingIndicator = () => (
  <Flex className={styles.wrapper} align={Flex.Align.CENTER} justify={Flex.Justify.CENTER}>
    <Flex.Child grow={0}>
      <Spinner />
    </Flex.Child>
  </Flex>
);

export default PageLoadingIndicator;
