import * as React from 'react';
import classNames from 'classnames';
import {Location, useLocation} from 'react-router-dom';

import {closeMobileMenu} from '@developers/actions/UIActions';
import {fetchGuilds} from '@developers/actions/UserActionCreators';
import Tooltips from '@developers/uikit/Tooltips';
import {ComponentDispatch} from '@developers/utils/ComponentDispatchUtils';
import TrackingAnalyticsUtils, {getPageName} from '@developers/utils/TrackingAnalyticsUtils';
import DeveloperSurveyNagbar from './DeveloperSurveyNagbar';
import MFACodeModal from './MFACodeModal';

import {ComponentActions, HistoryActions, NOOP} from '@developers/Constants';
import styles from './PageWrapper.module.css';

interface LocationData {
  previousLinkLocation?: string;
}

const handleRouteChange = (location: Location, action?: HistoryActions): void => {
  const applicationAnalyticsDetails = TrackingAnalyticsUtils.getApplicationProperties(location);
  const guildAnalyticsDetails = TrackingAnalyticsUtils.getGuildProperties(location);
  const previousLinkLocation = location.state != null ? location.state.previousLinkLocation : null;
  trackPageViews(location, previousLinkLocation, {...applicationAnalyticsDetails, ...guildAnalyticsDetails});
  if (action !== HistoryActions.REPLACE) {
    closeMobileMenu();
  }
};

const trackPageViews = (
  location: Location<LocationData>,
  previousLink: string | null | undefined,
  additionalDetails: Record<string, unknown>
): void => {
  const pageName = getPageName(location);
  TrackingAnalyticsUtils.trackPageLoad(pageName, previousLink, additionalDetails);
};

interface Props {
  children: React.ReactNode;
}

export default function PageWrapper({children}: Props) {
  const location = useLocation();
  const [shaking, setShaking] = React.useState(false);
  React.useEffect(() => {
    handleRouteChange(location);
  }, [location]);

  React.useEffect(() => {
    // TODO(beckwith) This appears to not be behind a route that requires authentication
    // so it's showing up as a failed request when logged out.
    // Queuing this up for a later investigation.
    fetchGuilds().catch(NOOP);

    const shakeFn = () => {
      setShaking(true);
      setTimeout(() => setShaking(false), 200);
    };
    ComponentDispatch.subscribe(ComponentActions.SHAKE_APP, shakeFn);
    return () => {
      ComponentDispatch.unsubscribe(ComponentActions.SHAKE_APP, shakeFn);
    };
  }, []);

  return (
    <div className={classNames(styles.shakeShake, shaking ? styles.shaking : null)}>
      <DeveloperSurveyNagbar />
      <div className={styles.content}>{children}</div>
      <Tooltips />
      <MFACodeModal />
    </div>
  );
}
