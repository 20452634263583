class Dispatch extends EventTarget {
  private savedDispatches = {} as Record<string, object[] | undefined>;
  private listeners = {} as Record<string, Array<EventListenerOrEventListenerObject | null>>;

  addEventListener(
    type: string,
    callback: EventListenerOrEventListenerObject | null,
    options?: boolean | AddEventListenerOptions | undefined
  ): void {
    if (this.listeners[type] == null) {
      this.listeners[type] = [];
    }
    this.listeners[type].push(callback);
    super.addEventListener(type, callback, options);
  }

  dispatch(type: string, args?: object) {
    this.dispatchEvent(new CustomEvent(type, args));
    return this;
  }

  subscribe(type: string, callback: EventListener) {
    const listeners = this.listeners[type];
    if (listeners != null && listeners.indexOf(callback) >= 0) {
      // eslint-disable-next-line no-console
      console.warn('ComponentDispatch.subscribe: Attempting to add a duplicate listener', type);
      return this;
    }
    this.addEventListener(type, callback);
    if (this.savedDispatches[type] != null) {
      this.savedDispatches[type]!.forEach((args) => this.dispatch(type, args));
    }
    this.savedDispatches[type] = undefined;
    return this;
  }

  unsubscribe(type: string, callback: EventListener) {
    this.removeEventListener(type, callback);
    return this;
  }
}

export const ComponentDispatch = new Dispatch();
