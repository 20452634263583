import type {Asset, StoreAsset} from '@developers/flow/Server';

export const isAssetVideo = (asset?: StoreAsset | null) => {
  return asset != null && asset.mime_type != null && asset.mime_type.includes('video');
};

export const isFileVideo = (file?: File | null) => {
  return file != null && file.type.includes('video');
};

export const getAssetLabel = (asset: Asset | StoreAsset | null | undefined): string | null => {
  if (asset == null) return null;
  else if (typeof (asset as Asset).name === 'string') return (asset as Asset).name;
  else if (typeof asset.filename === 'string') return asset.filename;
  else return null;
};

export const getFileExtension = (file?: File | null): string | null | undefined => {
  if (file == null) return null;
  const matches = file.name.match(/\.([a-zA-Z0-9]*)$/);
  if (matches != null) return matches[0];
  return null;
};

export const normalizeFilename = (filename: string): string =>
  filename
    .replace(/\.[a-zA-Z0-9]{3,4}$/, '')
    .replace(/[^A-Za-z0-9_-]+/g, '_')
    .toLowerCase();
