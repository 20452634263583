/**
 * THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
 *
 * Constants in this file are generated from the Python discord_common.shared_constants module.
 * Run `clyde gen ts-constants` to regenerate.
 */
export enum SKUTypes {
  DURABLE_PRIMARY = 1,
  DURABLE = 2,
  CONSUMABLE = 3,
  BUNDLE = 4,
  SUBSCRIPTION = 5,
  SUBSCRIPTION_GROUP = 6,
}

export const SKUTypesSets = {
  GIFTABLE: new Set([1, 2, 5]),
  BUNDLES: new Set([4, 6]),
};
