import {EventEmitter} from 'events';
import Logger from '@discordapp/common/Logger';
import {performance} from '@discordapp/performance-utils';

import type {ActionBase} from './FluxTypes';

export type TraceFunction<T> = (traceName: string, callback: () => T) => T;
export interface ActionHandlerTrace {
  name: string;
  time: number;
}
export interface ActionLogJSON {
  actionType: string;
  created_at: Date;
  totalTime: number;
  traces: ActionHandlerTrace[];
}

interface ActionLogOptions {
  /** If true, persist logged actions; by default they are discarded after the action is handled. */
  persist?: boolean;
}

const logger = new Logger('Flux');

export class ActionLogger<Action extends Readonly<ActionBase>> extends EventEmitter {
  logs: Array<ActionLog<Action>> = [];
  persist: boolean; // Devtools can toggle this on/off at will

  constructor({persist = false}: ActionLogOptions = {}) {
    super();
    this.persist = persist;
  }

  log<T>(action: Action, callback: (trace: TraceFunction<T>) => T) {
    const actionLog = new ActionLog(action);
    const trace = (traceName: string, traceCallback: () => T) => {
      let result: T;
      const actionTrace: ActionHandlerTrace = {name: traceName, time: -1};
      const start = performance.now();
      try {
        result = traceCallback();
      } finally {
        actionTrace.time = performance.now() - start;
        if (this.persist) {
          actionLog.traces.push(actionTrace);
        }

        this.emit('trace', action.type, traceName, actionTrace.time);
      }

      return result;
    };

    actionLog.startTime = performance.now();
    try {
      callback(trace);
    } catch (error) {
      actionLog.error = error;
      throw error;
    } finally {
      actionLog.totalTime = performance.now() - actionLog.startTime;

      if (this.persist && actionLog.totalTime > 0) {
        this.logs.push(actionLog);
      }
      // Ensure action logs don't get pathologically long
      if (this.logs.length > 1000) {
        this.logs.shift();
      }

      this.emit('log', action);
    }

    return actionLog;
  }

  getSlowestActions(filterAction?: string, limit: number = 20) {
    const items: Array<[string, string, number]> = [];
    for (const actionLog of this.logs) {
      if (filterAction != null && actionLog.name !== filterAction) {
        continue;
      }

      for (const trace of actionLog.traces) {
        items.push([trace.name, actionLog.name, trace.time]);
      }
    }

    items.sort((a, b) => b[2] - a[2]);
    if (items.length > limit) {
      items.length = limit;
    }
    let maxLength = 0;
    let totalTime = 0;
    const str = items
      .map<[string, number]>(([store, action, time]) => {
        let key = `${store}`;
        if (filterAction == null) {
          key += `<${action}>`;
        }
        maxLength = Math.max(key.length, maxLength);
        return [key, time];
      })
      .map<string>(([key, time]) => {
        totalTime += time;
        return `${key.padEnd(maxLength + 1, ' ')} - ${time}ms`;
      })
      .join('\n');
    if (items.length === 0 || items[0][2] < 10 || totalTime < 20) {
      return items;
    }
    // @ts-expect-error
    logger.log('Using Hermes:', !(typeof global?.HermesInternal === 'undefined'));
    logger.log(`${filterAction != null ? `\n\n=== ${filterAction} ===` : ''}\n${str}\n`);
    logger.log(`Total Time: ${totalTime}ms`);
    return items;
  }

  getLastActionMetrics(action: string, limit: number = 20) {
    const lastTraces: {[storeName: string]: [string, string, number]} = {};
    for (const actionLog of this.logs) {
      for (const trace of actionLog.traces) {
        lastTraces[trace.name] = [trace.name, actionLog.name, trace.time];
      }
    }

    const items = Object.values(lastTraces);
    items.sort((a, b) => b[2] - a[2]);
    if (items.length > limit) {
      items.length = limit;
    }
    let maxLength = 0;
    let totalTime = 0;
    const str = items
      .map<[string, number]>(([store, _action, time]) => {
        maxLength = Math.max(store.length, maxLength);
        return [store, time];
      })
      .map<string>(([key, time]) => {
        totalTime += time;
        return `${key.padEnd(maxLength + 1, ' ')} - ${time}ms`;
      })
      .join('\n');
    if (items.length === 0 || totalTime < 8) {
      return items;
    }
    logger.log(
      // @ts-expect-error
      `\nUsing Hermes: ${!(typeof global?.HermesInternal === 'undefined')}`,
      `\n\n=== ${action} ===\n${str}`,
      `\nTotal Time: ${totalTime}ms\n\n`
    );
    return items;
  }
}

let _id = 0;
export class ActionLog<Action extends Readonly<ActionBase>> {
  id: number;
  action: Action;
  createdAt: Date | undefined;
  startTime: number = 0;
  totalTime: number = 0;
  traces: ActionHandlerTrace[] = [];
  error?: any;

  constructor(action: Action) {
    this.id = _id++;
    this.action = action;
    this.createdAt = new Date();
  }

  get name() {
    return this.action.type;
  }

  toJSON(): ActionLogJSON {
    if (this.createdAt == null) {
      throw new Error('ActionLog.toJSON: You must complete your logging before calling toJSON');
    }

    return {
      actionType: this.action.type,
      created_at: this.createdAt,
      totalTime: this.totalTime,
      traces: this.traces,
    };
  }
}
