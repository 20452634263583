/**
 * THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
 *
 * Constants in this file are generated from the Python discord_common.shared_constants module.
 * Run `clyde gen ts-constants` to regenerate.
 */
export enum OAuth2Scopes {
  IDENTIFY = 'identify',
  EMAIL = 'email',
  CONNECTIONS = 'connections',
  GUILDS = 'guilds',
  GUILDS_JOIN = 'guilds.join',
  GUILDS_MEMBERS_READ = 'guilds.members.read',
  GDM_JOIN = 'gdm.join',
  BOT = 'bot',
  RPC = 'rpc',
  RPC_NOTIFICATIONS_READ = 'rpc.notifications.read',
  RPC_VOICE_READ = 'rpc.voice.read',
  RPC_VOICE_WRITE = 'rpc.voice.write',
  RPC_VIDEO_READ = 'rpc.video.read',
  RPC_VIDEO_WRITE = 'rpc.video.write',
  RPC_SCREENSHARE_READ = 'rpc.screenshare.read',
  RPC_SCREENSHARE_WRITE = 'rpc.screenshare.write',
  RPC_ACTIVITIES_WRITE = 'rpc.activities.write',
  WEBHOOK_INCOMING = 'webhook.incoming',
  MESSAGES_READ = 'messages.read',
  APPLICATIONS_BUILDS_UPLOAD = 'applications.builds.upload',
  APPLICATIONS_BUILDS_READ = 'applications.builds.read',
  APPLICATIONS_COMMANDS = 'applications.commands',
  APPLICATIONS_COMMANDS_PERMISSIONS_UPDATE = 'applications.commands.permissions.update',
  APPLICATIONS_COMMANDS_UPDATE = 'applications.commands.update',
  APPLICATIONS_STORE_UPDATE = 'applications.store.update',
  APPLICATIONS_ENTITLEMENTS = 'applications.entitlements',
  ACTIVITIES_READ = 'activities.read',
  ACTIVITIES_WRITE = 'activities.write',
  RELATIONSHIPS_READ = 'relationships.read',
  VOICE = 'voice',
  DM_CHANNELS_READ = 'dm_channels.read',
  ROLE_CONNECTIONS_WRITE = 'role_connections.write',
}

export const OAuth2ScopesSets = {
  PUBLIC: new Set(['applications.builds.read', 'applications.builds.upload', 'applications.commands', 'applications.commands.permissions.update', 'applications.entitlements', 'applications.store.update', 'bot', 'connections', 'email', 'gdm.join', 'guilds', 'guilds.join', 'guilds.members.read', 'identify', 'messages.read', 'role_connections.write', 'rpc', 'rpc.activities.write', 'rpc.notifications.read', 'rpc.screenshare.read', 'rpc.screenshare.write', 'rpc.video.read', 'rpc.video.write', 'rpc.voice.read', 'rpc.voice.write', 'webhook.incoming']),
  PRIVATE: new Set(['activities.read', 'activities.write', 'dm_channels.read', 'relationships.read', 'voice']),
  TEAM_USER: new Set(['applications.builds.read', 'applications.builds.upload', 'applications.commands.update', 'applications.entitlements', 'applications.store.update', 'identify']),
  EMBEDDED_APPS: new Set(['rpc.activities.write', 'rpc.voice.read', 'rpc.voice.write']),
  CLIENT_CREDENTIALS: new Set(['applications.commands.update']),
  CONFIDENTIAL_REQUEST_SCOPES: new Set(['role_connections.write']),
  BYPASS: new Set(['applications.commands', 'bot']),
  GUILD_INTEGRATION: new Set(['applications.commands', 'bot', 'webhook.incoming']),
  USER_INSTALLABLE_SCOPES: new Set(['applications.commands']),
  PRIVATE_CHANNEL_INTEGRATION: new Set(['applications.commands']),
  ALL: new Set(['activities.read', 'activities.write', 'applications.builds.read', 'applications.builds.upload', 'applications.commands', 'applications.commands.permissions.update', 'applications.entitlements', 'applications.store.update', 'bot', 'connections', 'dm_channels.read', 'email', 'gdm.join', 'guilds', 'guilds.join', 'guilds.members.read', 'identify', 'messages.read', 'relationships.read', 'role_connections.write', 'rpc', 'rpc.activities.write', 'rpc.notifications.read', 'rpc.screenshare.read', 'rpc.screenshare.write', 'rpc.video.read', 'rpc.video.write', 'rpc.voice.read', 'rpc.voice.write', 'voice', 'webhook.incoming']),
};
