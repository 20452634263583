import * as React from 'react';
import ReactDOM from 'react-dom';
import Animated from '@discordapp/animated';
import TransitionGroup from '@discordapp/transition-group';

import styles from './ContextMenu.module.css';

const ANIMATION_DURATION = 150;
const VERTICAL_OFFSET = 7;

interface ContainerProps {
  menuContent: React.ReactNode;
  children: (onTriggerClick: (event: React.SyntheticEvent<HTMLElement>) => void) => React.ReactNode;
}

interface ContainerState {
  isOpen: boolean;
  triggerTop: number | null | undefined;
  triggerRight: number | null | undefined;
  triggerHeight: number | null | undefined;
  viewportWidth: number | null | undefined;
}

interface MenuProps {
  menuContent: React.ReactNode;
  positionRight: number;
  positionTop: number;
}

interface MenuState {
  menuAnimation: Animated.Value;
}

class ContextMenu extends React.Component<MenuProps, MenuState> {
  state: MenuState = {
    menuAnimation: new Animated.Value(0),
  };

  componentWillEnter(callback: () => void): void {
    const {menuAnimation} = this.state;
    Animated.timing(menuAnimation, {toValue: 1, duration: ANIMATION_DURATION}).start(callback);
  }

  componentWillLeave(callback: () => void): void {
    const {menuAnimation} = this.state;
    Animated.timing(menuAnimation, {toValue: 0, duration: ANIMATION_DURATION}).start(callback);
  }

  render() {
    const {menuContent, positionRight, positionTop} = this.props;
    const {menuAnimation} = this.state;
    const translateY = menuAnimation.interpolate({
      inputRange: [0, 1],
      outputRange: ['-10px', '0px'],
    });

    return (
      <Animated.div
        className={styles.menu}
        style={{opacity: menuAnimation, right: positionRight, transform: [{translateY}], top: positionTop}}>
        {menuContent}
      </Animated.div>
    );
  }
}

export default class ContextMenuContainer extends React.Component<ContainerProps, ContainerState> {
  rafId: number | null | undefined = null;
  state: ContainerState = {
    isOpen: false,
    triggerTop: null,
    triggerRight: null,
    triggerHeight: null,
    viewportWidth: null,
  };

  componentDidUpdate(prevProps: ContainerProps, prevState: ContainerState) {
    const {isOpen} = this.state;
    const {isOpen: prevIsOpen} = prevState;

    if (!prevIsOpen && isOpen) {
      this.addEventListeners();
    } else if (prevIsOpen && !isOpen) {
      this.removeEventListeners();
    }
  }

  componentWillUnmount() {
    this.removeEventListeners();
    if (this.rafId != null) window.cancelAnimationFrame(this.rafId);
  }

  addEventListeners(): void {
    window.addEventListener('click', this.handleExternalClick);
    window.addEventListener('keydown', this.handleExternalKeyDown);
  }

  removeEventListeners(): void {
    window.removeEventListener('click', this.handleExternalClick);
    window.removeEventListener('keydown', this.handleExternalKeyDown);
  }

  closeContextMenu = (): void => {
    this.setState({
      isOpen: false,
      triggerTop: null,
      triggerRight: null,
      triggerHeight: null,
      viewportWidth: null,
    });
  };

  handleExternalClick = (): void => {
    this.closeContextMenu();
  };

  handleExternalKeyDown = (event: KeyboardEvent): void => {
    if (event.key === 'Escape') this.closeContextMenu();
  };

  handleTriggerClick = (event: React.SyntheticEvent<HTMLElement>): void => {
    event.stopPropagation();
    const {currentTarget} = event;

    this.rafId = window.requestAnimationFrame(() => {
      const clientRect = currentTarget.getBoundingClientRect();
      const viewportWidth = window.innerWidth;

      this.setState((state) => {
        return {
          isOpen: !state.isOpen,
          triggerTop: clientRect.top,
          triggerRight: clientRect.right,
          triggerHeight: clientRect.height,
          viewportWidth,
        };
      });
    });
  };

  renderContextMenu(): React.ReactNode {
    const {isOpen, triggerHeight, triggerRight, triggerTop, viewportWidth} = this.state;
    if (!isOpen || viewportWidth == null || triggerRight == null) return null;
    return (
      <ContextMenu
        menuContent={this.props.menuContent}
        positionRight={viewportWidth - triggerRight}
        positionTop={(triggerTop ?? 0) + (triggerHeight ?? 0) + VERTICAL_OFFSET}
      />
    );
  }

  render() {
    const {children} = this.props;

    return (
      <React.Fragment>
        {children(this.handleTriggerClick)}
        {
          ReactDOM.createPortal(
            <TransitionGroup component="div" transitionAppear={false}>
              {this.renderContextMenu()}
            </TransitionGroup>,
            window.document.body
          ) as React.ReactNode
        }
      </React.Fragment>
    );
  }
}
