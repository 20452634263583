import Flux from '@discordapp/flux';

import Dispatcher from '@developers/Dispatcher';
import ActionTypes from '@developers/actions/ActionTypes';

import type {ActionFor, Action} from '@developers/flow/Action';
import type {Payout, Team, TeamId, UserIdentityVerification} from '@developers/flow/Server';

let teamsMap: Map<TeamId, Team> = new Map();
let payoutHistoriesMap: Map<TeamId, Payout[]> = new Map();
let teamIdentityVerifications: Map<TeamId, UserIdentityVerification> = new Map();
let hasFetchedTeams = false;

function mergeTeamObject(nextTeamData: Team): void {
  teamsMap.set(nextTeamData.id, {...teamsMap.get(nextTeamData.id), ...nextTeamData});
}

function handleTeamsFetchSuccess({teams}: ActionFor<'TEAMS_FETCH_SUCCESS'>): void {
  teams
    .sort((teamA, teamB) => teamA.id.localeCompare(teamB.id, undefined, {numeric: true}))
    .forEach((team) => {
      mergeTeamObject(team);
    });
  hasFetchedTeams = true;
}

function handleTeamUpdate(action: ActionFor<'TEAM_INVITE_ACCEPTED' | 'TEAM_FETCH_SUCCESS' | 'TEAM_UPDATE_SUCCESS'>) {
  mergeTeamObject(action.team);
}

function handleTeamCreateSuccess({team}: ActionFor<'TEAM_CREATE_SUCCESS'>): void {
  teamsMap.set(team.id, team);
}

function deleteTeam(teamId: TeamId): void {
  teamsMap.delete(teamId);
  payoutHistoriesMap.delete(teamId);
}

function handleTeamDeleteSuccess({teamId}: ActionFor<'TEAM_DELETE_SUCCESS'>) {
  deleteTeam(teamId);
}

function handleTeamMemberDeleteSuccess({hasRemovedSelf, teamId}: ActionFor<'TEAM_MEMBER_DELETE_SUCCESS'>): void {
  if (!hasRemovedSelf) return;
  deleteTeam(teamId);
}

function handleTeamPayoutHistoryFetchSuccess({teamId, payouts}: ActionFor<'TEAM_PAYOUT_HISTORY_FETCH_SUCCESS'>): void {
  const existingPayoutHistory = payoutHistoriesMap.get(teamId);

  if (existingPayoutHistory != null) {
    payoutHistoriesMap.set(teamId, existingPayoutHistory.concat(payouts));
  } else {
    payoutHistoriesMap.set(teamId, payouts);
  }
}

function handleIdentityVerificationUpdate({
  teamId,
  identityVerification,
}: ActionFor<'TEAM_IDENTITY_VERIFICATION_FETCH_SUCCESS'>) {
  teamIdentityVerifications.set(teamId, identityVerification);
}

function handleUserLogout() {
  teamsMap = new Map();
  payoutHistoriesMap = new Map();
  teamIdentityVerifications = new Map();
  hasFetchedTeams = false;
}

class TeamStore extends Flux.Store<Action> {
  static displayName = 'TeamStore';
  get teamsMap(): Map<TeamId, Team> {
    return teamsMap;
  }

  getTeam(teamId: TeamId): Team | null | undefined {
    return teamsMap.get(teamId);
  }

  getPayoutHistory(teamId: TeamId): Payout[] | null | undefined {
    return payoutHistoriesMap.get(teamId);
  }

  getIdentityVerification(teamId: TeamId): UserIdentityVerification | null | undefined {
    return teamIdentityVerifications.get(teamId);
  }

  hasFetchedTeams(): boolean {
    return hasFetchedTeams;
  }
}

export default new TeamStore(Dispatcher, {
  [ActionTypes.TEAMS_FETCH_SUCCESS]: handleTeamsFetchSuccess,
  [ActionTypes.TEAM_INVITE_ACCEPTED]: handleTeamUpdate,
  [ActionTypes.TEAM_FETCH_SUCCESS]: handleTeamUpdate,
  [ActionTypes.TEAM_UPDATE_SUCCESS]: handleTeamUpdate,
  [ActionTypes.TEAM_CREATE_SUCCESS]: handleTeamCreateSuccess,
  [ActionTypes.TEAM_DELETE_SUCCESS]: handleTeamDeleteSuccess,
  [ActionTypes.TEAM_MEMBER_DELETE_SUCCESS]: handleTeamMemberDeleteSuccess,
  [ActionTypes.TEAM_PAYOUT_HISTORY_FETCH_SUCCESS]: handleTeamPayoutHistoryFetchSuccess,
  [ActionTypes.TEAM_IDENTITY_VERIFICATION_FETCH_SUCCESS]: handleIdentityVerificationUpdate,
  [ActionTypes.USER_LOGOUT]: handleUserLogout,
});
