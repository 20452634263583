import * as React from 'react';

type ImportPromise<T> = () => Promise<{default: T}>;

export const loaderMaker =
  (bgColor: string = 'transparent') =>
  () =>
    <div style={{position: 'absolute', width: '100%', height: '100%', backgroundColor: bgColor}} />;

const MAX_RETRIES = 50;
const STARTING_BACKOFF = 500;
const MAX_BACKOFF = 5000;

// Hook to allow callers to wait until the user is online before retrying
// This code lives in discord_app and has different implementations for web/ios, so it's not easy to pull into
// discord_common.  Instead, a wrapper around CodeSplittingUtils will set this function when the file loads.
type AwaitOnline = () => Promise<void>;
let awaitOnline: AwaitOnline = () => Promise.resolve();
export function setAwaitOnline(fn: AwaitOnline) {
  awaitOnline = fn;
}

const pausedPromise = (duration: number): Promise<any> => new Promise((resolve) => setTimeout(resolve, duration));

interface ImportWithRetryOptions<T> {
  createPromise: () => Promise<T>;
  webpackId: any;
}

export async function importWithRetry<T>({createPromise, webpackId}: ImportWithRetryOptions<T>): Promise<T> {
  let delay = STARTING_BACKOFF;
  let retries = 0;
  while (true) {
    try {
      return await createPromise();
    } catch (err) {
      console.log(err);

      // Webpack is smart and will clear its _chunk_ cache when a _chunk_ fails to load from the network
      // so it is safe to retry those kinds of failures.
      // However, it will not do that when _executing_ a _module_.  So if we throw an exception when loading a file for
      // example, there is no point to retrying, it is a terminal state, so let's just re-throw the error.
      if (webpackId in require.cache) {
        console.log('Module was found in webpack cache so it has loaded from the network and webpack will not retry');
        throw err;
      }

      if (retries >= MAX_RETRIES) {
        throw err;
      }

      await pausedPromise(delay);
      await awaitOnline();
      delay = Math.min(MAX_BACKOFF, delay * 2);
      retries++;
    }
  }
}

/*
 * Loads require or delays import depending on if server or client
 * Default loader is transparent, as it could be a component which is not full-page
 * This can be overridden.
 */
interface MakeLazyOptions<Config> {
  createPromise: ImportPromise<React.ComponentType<Config>>;
  webpackId: any;
  name?: string;
  renderLoader?: () => NonNullable<React.ReactNode> | null;
  memo?: boolean;
}

export function makeLazy<Config extends Record<string, any>>({
  createPromise,
  webpackId,
  renderLoader,
  name,
  memo = false,
}: MakeLazyOptions<Config>): React.JSXElementConstructor<Config> {
  const Lazy = React.lazy(() => importWithRetry({createPromise, webpackId}));
  let Wrapper: React.FunctionComponent<Config> = (props: Config) => (
    <React.Suspense fallback={renderLoader != null ? renderLoader() : loaderMaker()()}>
      {/* @ts-expect-error expects PropsWithRef */}
      <Lazy {...props} />
    </React.Suspense>
  );
  if (memo) {
    Wrapper = React.memo(Wrapper);
  }
  Wrapper.displayName = `Suspense(${name || 'Unknown'})`;
  return Wrapper;
}

/**
 * This is a helper component for lazy loading a non-react library but that is needed by react.
 * For example for syntax highlighting, we lazy load highlight.js, and use that to transform the text
 * rendered by react.  This component gives a way of lazy loading that library and then invoking the given
 * render function when it is ready or displaying the given fallback in the mean time.
 *
 * NOTE: The `createPromise` function will only ever be invoked once, which means it cannot use a ternary or
 * other dynamic logic, it has to always return the same library.
 */
interface LazyLibraryProps<T> {
  createPromise: ImportPromise<T>;
  webpackId: any;
  render: (library: T) => NonNullable<React.ReactNode> | null;
  renderFallback: () => NonNullable<React.ReactNode> | null;
}
export function LazyLibrary<T>({createPromise, webpackId, render, renderFallback}: LazyLibraryProps<T>) {
  const [library, setLibrary] = React.useState<T | null>(null);
  React.useEffect(() => {
    importWithRetry({createPromise, webpackId}).then(({default: library}) => setLibrary(library));
    // createPromise is intentionally not passed in as a dependency since the assumption
    // is that the imported library will always be the same
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <>{library == null ? renderFallback() : render(library)}</>;
}
