import Dispatcher from '@developers/Dispatcher';
import ActionTypes from './ActionTypes';

import type {TooltipProps} from '@developers/flow/Client';

export default {
  show(id: string | number, tooltip: TooltipProps) {
    // This is a Flux anti-pattern. Since tooltip trigger can be updated due to change
    // in text or position it will trigger and update and that update might be
    // happening during a dispatch that caused a re-render.
    Dispatcher.dispatch({type: ActionTypes.TOOLTIP_SHOW, id, tooltip});
  },

  hide(id: string | number) {
    // This is a Flux anti-pattern. Since tooltip unmount can be triggered by
    // other Dispatcher actions we must force the hide to occur on the next tick
    // outside of the current dispatch.
    Dispatcher.dispatch({type: ActionTypes.TOOLTIP_HIDE, id});
  },
};
