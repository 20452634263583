import * as React from 'react';
import {Text} from '@discordapp/design/components/Text/Text.web';
import HTTPUtils, {HTTPResponse} from '@discordapp/http-utils/HTTPUtils';

const buildOverridesEndpoint = new URL('/__development/build_overrides', location.origin);
const buildOverrideCookieName = 'buildOverride';
const buildOverrideProjectName = 'discord_developers';

function maybeHasBuildOverride(): boolean {
  return window.document.cookie.includes(`${buildOverrideCookieName}=`);
}

interface BuildOverride {
  id: string;
  type: 'id' | 'branch';
}

interface BuildOverrides {
  [project: string]: BuildOverride;
}

export function BuildOverrideMenuItem() {
  const [buildOverrides, setBuildOverrides] = React.useState<BuildOverrides>();
  const projectBuildOverride = buildOverrides?.[buildOverrideProjectName];

  React.useEffect(() => {
    if (__DEV__) return;
    if (!maybeHasBuildOverride()) return;
    HTTPUtils.get(buildOverridesEndpoint.href)
      .then((res: HTTPResponse<BuildOverrides>) => {
        setBuildOverrides(res.body);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error('Error fetching build overrides:', err);
      });
  }, []);

  if (__DEV__ || projectBuildOverride == null) return null;
  return (
    <>
      <br />
      <Text variant="text-xs/normal">Build Override: {projectBuildOverride.id}</Text>
    </>
  );
}
