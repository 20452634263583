export class Timeout {
  _ref?: number | null;

  start(delay: number, handler: () => void, reset: boolean = true) {
    if (this.isStarted() && !reset) {
      return;
    }

    this.stop();
    this._ref = window.setTimeout(() => {
      this._ref = null;
      handler();
    }, delay);
  }

  stop() {
    if (this._ref != null) {
      clearTimeout(this._ref);
      this._ref = null;
    }
  }

  isStarted(): boolean {
    return this._ref != null;
  }
}

export class DelayedCall {
  _timeout: Timeout;
  _delay: number;
  _handler: () => void;

  constructor(delay: number, handler: () => void) {
    this._delay = delay;
    this._handler = handler;
    this._timeout = new Timeout();
  }

  set(delay: number): DelayedCall {
    this._delay = delay;
    return this;
  }

  delay(reset: boolean = true) {
    this._timeout.start(this._delay, this._handler, reset);
  }

  cancel() {
    this._timeout.stop();
  }

  isDelayed(): boolean {
    return this._timeout.isStarted();
  }
}

export class Interval {
  _ref?: number | null;

  start(delay: number, handler: () => void) {
    this.stop();
    this._ref = window.setInterval(handler, delay);
  }

  stop() {
    if (this._ref != null) {
      clearInterval(this._ref);
      this._ref = null;
    }
  }

  isStarted(): boolean {
    return this._ref != null;
  }
}

export function timeoutPromise(duration: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(() => resolve(), duration);
  });
}
