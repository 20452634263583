import * as React from 'react';
import shallowEqual from '@discordapp/shallow-equal';

import BatchedStoreListener from './BatchedStoreListener';

import type Store from './Store';

/**
 * A property P will be present if:
 * - it is present in DecorationTargetProps
 *
 * Its value will be dependent on the following conditions
 * - if property P is present in InjectedProps and its definition extends the definition
 *   in DecorationTargetProps, then its definition will be that of DecorationTargetProps[P]
 * - if property P is not present in InjectedProps then its definition will be that of
 *   DecorationTargetProps[P]
 * - if property P is present in InjectedProps but does not extend the
 *   DecorationTargetProps[P] definition, its definition will be that of InjectedProps[P]
 */
type Matching<InjectedProps, DecorationTargetProps> = {
  [P in keyof DecorationTargetProps]: P extends keyof InjectedProps
    ? InjectedProps[P] extends DecorationTargetProps[P]
      ? DecorationTargetProps[P]
      : InjectedProps[P]
    : DecorationTargetProps[P];
};

/**
 * a property P will be present if :
 * - it is present in both DecorationTargetProps and InjectedProps
 * - InjectedProps[P] can satisfy DecorationTargetProps[P]
 * ie: decorated component can accept more types than decorator is injecting
 *
 * For decoration, inject props or ownProps are all optionally
 * required by the decorated (right hand side) component.
 * But any property required by the decorated component must be satisfied by the injected property.
 */
type Shared<InjectedProps, DecorationTargetProps> = {
  [P in Extract<keyof InjectedProps, keyof DecorationTargetProps>]?: InjectedProps[P] extends DecorationTargetProps[P]
    ? DecorationTargetProps[P]
    : never;
};

// Infers prop type from component C
type GetProps<C> = C extends React.ComponentType<infer P> ? P : never;

// Applies LibraryManagedAttributes (proper handling of defaultProps
// and propTypes), as well as defines WrappedComponent.
type ConnectedComponent<C extends React.ComponentType<any>, P> = React.NamedExoticComponent<
  JSX.LibraryManagedAttributes<C, P>
>;

// Injects props and removes them from the prop requirements.
// Will not pass through the injected props if they are passed in during
// render. Also adds new prop requirements from TNeedsProps.
type InferableComponentEnhancerWithProps<TInjectedProps, TNeedsProps> = <
  C extends React.ComponentType<Matching<TInjectedProps, GetProps<C>>>
>(
  component: C
) => ConnectedComponent<C, Omit<GetProps<C>, keyof Shared<TInjectedProps, GetProps<C>>> & TNeedsProps>;

// declare interface InferableComponentEnhancerWithProps<TInjectedProps, TNeedsProps> {
//   <P extends TInjectedProps>(component: React.ComponentType<P>): React.ComponentClass<
//     Omit<P, keyof TInjectedProps> & TNeedsProps
//   >;
// }

interface ConnectStoresOptions {
  forwardRef?: boolean;
}

interface ConnectStores {
  <TInjectedProps>(
    stores: Array<Store<any>>,
    getStateFromStores: () => TInjectedProps,
    options?: ConnectStoresOptions
    // eslint-disable-next-line @typescript-eslint/ban-types
  ): InferableComponentEnhancerWithProps<TInjectedProps, {}>;

  <TOwnProps, TInjectedProps>(
    stores: Array<Store<any>>,
    getStateFromStores: (needsProps: TOwnProps) => TInjectedProps,
    options?: ConnectStoresOptions
  ): InferableComponentEnhancerWithProps<TInjectedProps, TOwnProps>;
}

function connectStores(stores: Array<Store<any>>, getStateFromStores: () => any, options?: ConnectStoresOptions): any {
  if (options != null && options.forwardRef) {
    return connectStoresWithRef(stores, getStateFromStores);
  } else {
    return connectStoresWithoutRef(stores, getStateFromStores);
  }
}

// Ok, so, here me out!  connectStores is deprecated and real hard to type right.
// So, I copied the definitions from the old .d.ts file we had and cast connectStores to that so there's
// no change in type checking behavior.  And then made a minimal effort in the rest of the file to make it
//  not fail, but it's definitely not perfect type checking any more.  Part of the assumption is we know the
// code "works" based on a few years of testing, and we won't make any new features here so maybe it's safe?
export default connectStores as ConnectStores;

// Attempts to get the display name of a component, such that we can use it to
// create the display name of the connect stores container.
function getDisplayName(Component: React.ComponentType<any>): string {
  // If we are in dev mode, warn if we can't figure out the display name.
  if (process.env.NODE_ENV === 'development') {
    const name = Component.displayName == null || Component.displayName === '' ? Component.name : Component.displayName;
    if (name == null || name === '' || name === '_temp') {
      // eslint-disable-next-line no-console
      console.warn(
        'Component',
        Component,
        'has no name! This generally means you have passed a function directly to Flux.connectStores. Instead, consider binding that function to a variable.'
      );
    }
  }

  return Component.displayName ?? Component.name ?? '<Unknown>';
}

// Internal implementation of connect stores, where we need to forward ref. This has an optimization,
// which avoids a destructure copy `{forwardedConnectStoresRef, ...rest} = this.props`, by instead
// passing the child component's props down a single key within props (`childProps`).
function connectStoresWithRef<
  Config,
  State extends Record<string, any>,
  Component extends React.ComponentClass<Config>
>(
  stores: Array<Store<any>>,
  /**
   * Ideally this isn't any but the correct connectStores annotation would require explicit parameters.
   *
   * connectStores<OwnProps: Object, State: Object, Component: React.AbstractComponent<{|...OwnProps, ...State|}>>
   */
  getStateFromStores: (props: any) => State
) {
  return (Component: Component) => {
    const displayName = `FluxContainer(${getDisplayName(Component)})`;
    class FluxContainer extends React.Component<{
      // @ts-expect-error idk how to type $Diff here
      childProps: $Diff<Config, State>;
      forwardedConnectStoresRef: any;
    }> {
      static displayName = displayName;

      memoizedGetStateFromStores = memoizeGetStateFromStores(getStateFromStores);
      listener = new BatchedStoreListener(stores, () => {
        const cachedState = this.memoizedGetStateFromStores.getCachedResult(this.props.childProps);
        if (cachedState != null) {
          this.memoizedGetStateFromStores.clear();
          if (shallowEqual(this.memoizedGetStateFromStores(this.props.childProps), cachedState)) {
            return;
          }
        }
        this.forceUpdate();
      });

      componentDidMount() {
        this.listener.attach(displayName);
      }

      componentWillUnmount() {
        this.listener.detach();
        this.memoizedGetStateFromStores.clear();
      }

      render() {
        const {forwardedConnectStoresRef, childProps} = this.props;
        const state = this.memoizedGetStateFromStores(childProps);
        return <Component ref={forwardedConnectStoresRef} {...childProps} {...state} />;
      }
    }

    const ForwardRef = React.forwardRef((props, ref) => (
      <FluxContainer childProps={props} forwardedConnectStoresRef={ref} />
    ));
    ForwardRef.displayName = `ForwardRef(${displayName})`;
    return ForwardRef;
  };
}

// Internal implementation of connect stores, where we don't forward ref. During development, we will
// however forward refs, such that we can detect if a ref was forwarded, and fail very loudly, to let
// the developer know that they need to set the `forwardRef` option to true. In production mode,
// this safety rail is not present, and we will use the most optimal version (no forward refs).
function connectStoresWithoutRef<
  Config,
  State extends Record<string, any>,
  Component extends React.ComponentClass<Config>
>(
  stores: Array<Store<any>>,
  // @ts-expect-error idk how to type $Diff here
  getStateFromStores: (props: $Diff<Config, State>) => State
) {
  return (Component: Component) => {
    const displayName = `FluxContainer(${getDisplayName(Component)})`;
    // @ts-expect-error idk how to type $Diff here
    class FluxContainer extends React.Component<$Diff<Config, State>> {
      static displayName = displayName;

      memoizedGetStateFromStores = memoizeGetStateFromStores(getStateFromStores);
      listener = new BatchedStoreListener(stores, () => {
        const cachedState = this.memoizedGetStateFromStores.getCachedResult(this.props);
        if (cachedState != null) {
          this.memoizedGetStateFromStores.clear();
          if (shallowEqual(this.memoizedGetStateFromStores(this.props), cachedState)) {
            return;
          }
        }
        this.forceUpdate();
      });

      componentDidMount() {
        this.listener.attach(displayName);
        if (process.env.NODE_ENV === 'development') {
          if (this.props.forwardedConnectStoresRef != null) {
            throw new Error(
              `${displayName} has a forwarded ref, you must pass {forwardRef: true} to the options in Flux.connectStores.`
            );
          }
        }
      }

      componentWillUnmount() {
        this.listener.detach();
        this.memoizedGetStateFromStores.clear();
      }

      render() {
        const state = this.memoizedGetStateFromStores(this.props);
        // In development we need to pull out the `forwardedConnectStoresRef`
        // variable from props, otherwise react will warn!
        if (process.env.NODE_ENV === 'development') {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const {forwardedConnectStoresRef, ...rest} = this.props;
          return <Component {...rest} {...state} />;
        }
        return <Component {...this.props} {...state} />;
      }
    }

    // On development, we want to forward the ref, to ensure that it *is* null.
    // We check the ref in `componentDidMount`, and throw an error if it's not null.
    if (process.env.NODE_ENV === 'development') {
      const ForwardRef = React.forwardRef((props, ref) => <FluxContainer {...props} forwardedConnectStoresRef={ref} />);
      ForwardRef.displayName = `ForwardRefDevelopment(${displayName})`;
      return ForwardRef;
    }

    return FluxContainer;
  };
}

// Memoizes the results from `getStateFromStores`, only re-calling it when props change.
function memoizeGetStateFromStores<Props extends Record<string, any>, State>(
  getStateFromStores: (props: Props) => State
): ((props: Props) => State) & {
  getCachedResult(props: Props): State | null;
  clear(): void;
} {
  let lastProps: Props | null = null;
  let lastResult: State | null = null;

  const getCachedResult = (nextProps: Props) => {
    if (lastProps != null && lastResult != null && shallowEqual(lastProps, nextProps)) {
      return lastResult;
    }

    // Object changed referential equality, but remained the same.
    // No recomputation is required, but update lastProps in order to
    // drop the stale ref.
    if (lastProps != null && lastResult != null && shallowEqual(lastProps, nextProps)) {
      lastProps = nextProps;
      return lastResult;
    }

    return null;
  };

  const memoizedFunction = (nextProps: Props): State => {
    const cachedResult = getCachedResult(nextProps);
    if (cachedResult != null) {
      return cachedResult;
    }

    // Things changed, so an update is required.
    lastProps = nextProps;
    lastResult = getStateFromStores(lastProps);
    return lastResult;
  };

  // This will return null if we are unable to use the existing cached result.
  memoizedFunction.getCachedResult = getCachedResult;

  // Clear any stored state
  // The memoized function will recompute on the next call.
  memoizedFunction.clear = () => {
    lastProps = null;
    lastResult = null;
  };

  return memoizedFunction;
}
