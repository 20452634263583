import * as React from 'react';

import {Messages} from '@developers/i18n';
import styles from './GenericError.module.css';

interface Props {
  errorMessage?: React.ReactNode;
  heading?: React.ReactNode;
}

export default function GenericError({
  errorMessage = Messages.DeveloperPages.ERROR_UNKNOWN,
  heading = Messages.DeveloperPages.ERROR_HEADING,
}: Props) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <img src={require('@developers/images/img_app_crash_dark.svg')} alt="" />
        <h1 className={styles.errorHeading}>{heading}</h1>
        <p className={styles.errorBody}>{errorMessage}</p>
      </div>
    </div>
  );
}
